import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 322,
    width: '100vw',
    minHeight: 300,
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'inline-block',
      height: 440,
      width: 440,
      backgroundColor: '#fef4eb',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -60%)',
      borderBottomLeftRadius: '100%',
      borderBottomRightRadius: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',

      '&:before': {
        transform: 'translate(-50%, -70%)',
      },
    },
  },

  stepGraphic: {
    width: '100%',
    height: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: -16,

    [theme.breakpoints.down('xs')]: {
      height: 90,

      '& svg': {
        height: 90,
      },
    },
  },

  closeButton: {
    position: 'absolute',
    top: -50,
    right: -20,
  },

  titleFirstPart: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: 1.25,
    },
  },

  titleLastPart: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 42,
      lineHeight: 1.25,
    },
  },

  details: {
    minHeight: 135,
    marginTop: 16,

    [theme.breakpoints.down('xs')]: {
      minHeight: 120,

      '& *': {
        fontSize: 16,
      },
    },
  },

  note: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 54,
    marginTop: 32,

    [theme.breakpoints.down('xs')]: {
      marginTop: 24,

      '& *': {
        fontSize: 16,
      },
    },
  },

  bulletNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 32,

    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
    },
  },

  bullet: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: colors.grey40,
    display: 'inline-block',
    margin: 4,
    position: 'relative',
    transition: 'all .25s easy',

    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: colors.primary.main,
      transform: 'scale(0)',
      transition: 'all 0.35s linear',
    },

    '&.active': {
      '&:before': {
        transform: 'scale(1)',
      },
    },
  },

  bulletButton: {
    width: 60,

    [theme.breakpoints.down('xs')]: {
      width: 50,
    },
  },

  bulletText: {
    color: colors.grey120,
  },
}));
