import { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';

import { Button } from 'components/button/Button';
import { Loader } from 'components/loader/Loader';
import { Scrollbars } from 'components/scrollbars/Scrollbars';

import { useStyles } from './TimePicker.styles';
import { TimePickerProps } from './TimePicker.types';

export const TimePicker = memo(
  ({
    period,
    schedule,
    isLoading,
    onTimeSelected,
    onChangePeriod,
    className,
    alwaysShowScrollbar,
  }: TimePickerProps) => {
    const styles = useStyles({ isOnChangePeriod: !!onChangePeriod });

    return (
      <Grid container direction="column" className={clsx(styles.container, className)}>
        <Grid item container justify="center" xs="auto">
          <div className={styles.navigationButtons}>
            {onChangePeriod && (
              <div className={styles.navigationButton}>
                <IconButton size="small" onClick={() => onChangePeriod('decrease')}>
                  <KeyboardArrowLeft color="primary" />
                </IconButton>
              </div>
            )}
            <div className={styles.titleBox}>
              <Typography variant="overline" component="p" color="secondary" className={styles.title}>
                {period}
              </Typography>
            </div>
            {onChangePeriod && (
              <div className={styles.navigationButton}>
                <IconButton size="small" onClick={() => onChangePeriod('add')}>
                  <KeyboardArrowRight color="primary" />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs className={styles.timeContainer}>
          {isLoading && <Loader />}
          {!isLoading && schedule.length !== 0 && (
            <Scrollbars
              autoHide={!alwaysShowScrollbar}
              autoHideTimeout={800}
              autoHideDuration={200}
              className={clsx(alwaysShowScrollbar && styles.alwaysShowScrollbar)}
            >
              {schedule.map((time, index) => (
                <div className={styles.timeButton} key={`${index}-${time.period}`}>
                  <Button
                    className={clsx(time.selected && styles.selected)}
                    fullWidth
                    buttonType="twoTone"
                    onClick={() => onTimeSelected(time)}
                  >
                    {time.friendly}
                  </Button>
                </div>
              ))}
            </Scrollbars>
          )}
          {!isLoading && schedule.length === 0 && (
            <Typography variant="body1" className={styles.noAvailability}>
              No Availability
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  },
);
