import { memo, useCallback } from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { CalendarIcon, PencilIcon, TrashIcon, colors } from 'assets';
import { Paper, UserIdentifier } from 'components';
import { useLocationTimezone } from 'hooks/useLocationTimezone/useLocationTimezone';
import { USADateTimeFormatAmPm } from 'utils';

import { useStyles } from './styles';
import { CommentCardProps } from './types';

export const CommentCard = memo(({ comment, timeZone, onEdit, onDelete }: CommentCardProps) => {
  const styles = useStyles();

  const { utcToZone } = useLocationTimezone(timeZone);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      onDelete();
    },
    [onDelete],
  );

  const handleEdit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      onEdit();
    },
    [onEdit],
  );

  return (
    <Paper roundingValue={4} elevation={16} className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerTitleSection}>
          <UserIdentifier name={comment.created.name} imageUrl={comment.created.imageUrl} size="large" />
        </div>

        <div className={styles.headerDateSection}>
          <CalendarIcon color={colors.grey80} className={styles.calendarIcon} />

          <Typography variant="body1" className={styles.headerDate}>
            {comment.updated.datetime !== '' ? utcToZone(comment.updated.datetime, USADateTimeFormatAmPm) : 'Recently'}
          </Typography>
        </div>

        <div className={styles.headerActionsSection}>
          {comment.canBeEdited && (
            <IconButton className="iconButtonMedium" onClick={handleEdit}>
              <PencilIcon fontSize={18} />
            </IconButton>
          )}

          {comment.canBeDeleted && (
            <IconButton className="iconButtonMedium" onClick={handleDelete}>
              <TrashIcon fontSize={20} />
            </IconButton>
          )}
        </div>
      </div>

      <Divider className={styles.divider} />

      <Typography variant="body1" className={styles.comment}>
        <span
          dangerouslySetInnerHTML={{
            __html: comment.comment,
          }}
        />
      </Typography>
    </Paper>
  );
});
