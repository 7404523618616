import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useHookStyles = makeStyles((theme: Theme) => ({
  filterRow: { display: 'flex', flexDirection: 'column' },
  filterHeader: { backgroundColor: colors.grey10, borderBottom: `1px solid ${colors.grey30}`, padding: 16 },
  tableBox: {
    flex: 1,
  },
  checkboxHolder: { paddingLeft: 16, display: 'inline-block' },
  paginationRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    padding: '0 8px',
  },
  deleteAll: {
    marginRight: 16,
  },
  pagination: {
    flex: 1,
  },
}));
