import { memo, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { Input } from '../input/Input';
import { MinusIcon, colors, PlusIcon } from 'assets';

import { useStyles } from './IncrementInput.styles';
import { IncrementInputProps, IncrementInputMask } from './IncrementInput.types';

export const IncrementInput = memo(
  ({
    onChange,
    value,
    step = 1,
    mask,
    min,
    max,
    onValidation,
    className,
    inputRef,
    classes,
    name,
    error,
    size,
    disabled,
    id,
    ...props
  }: IncrementInputProps) => {
    const styles = useStyles({ size });

    const handleValueChange = (inputValue: string) => {
      const currentValue = inputValue !== '' ? Number.parseFloat(inputValue) : null;
      validate(currentValue);
      if (max !== undefined && currentValue) {
        if (currentValue > max) {
          onChange(max);

          return;
        }
      }
      if (min !== undefined && currentValue) {
        if (currentValue < min) {
          onChange(min);

          return;
        }
      }
      onChange(currentValue);
    };

    const handleStepChange = (step: number) => {
      const currentValue = value ? value + step : step;

      if (min !== undefined && min > currentValue) {
        onChange(min);

        return;
      }

      if (max !== undefined && max < currentValue) {
        onChange(max);

        return;
      }

      onChange(currentValue);
    };

    const validate = (value: number | null) => {
      if (!value) {
        return;
      }
      const minError = min !== undefined && value < min;
      const maxError = max !== undefined && value > max;
      const stepError = value % step !== 0;
      const isValid = !stepError && !minError && !maxError;
      const validationMessage = isValid ? '' : `Please enter valid number (step:${step}, min:${step}, max:${max})`;
      onValidation && onValidation(validationMessage);

      return validationMessage;
    };

    const calculatedMask = useMemo<IncrementInputMask>(() => {
      if (mask) {
        return mask;
      }
      if (min !== undefined && min >= 0) {
        return 'positive-integer';
      }

      return 'integer';
    }, [mask, min]);

    const decrementDisabled = useMemo(
      () => disabled || (typeof min !== 'undefined' && Number(value) === Number(min)),
      [disabled, min, value],
    );
    const incrementDisabled = useMemo(
      () => disabled || (typeof max !== 'undefined' && Number(value) === Number(max)),
      [disabled, max, value],
    );

    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={2}
        className={className}
        data-testid={props['data-testid']}
      >
        <Grid item className={clsx(classes?.buttonWrapper)}>
          <IconButton
            className={clsx(styles.button, decrementDisabled && styles.buttonDisabled, classes?.button)}
            onClick={() => handleStepChange(-step)}
            id={`${name}-decrement`}
            size={size}
            disabled={decrementDisabled}
          >
            <MinusIcon color={decrementDisabled ? colors.grey120 : colors.secondary.main} />
          </IconButton>
        </Grid>
        <Grid item>
          <div className={clsx(classes?.inputWrapper, styles.inputWrapper, error && styles.error)}>
            <Input
              id={id || name}
              name={name}
              className={styles.rootInput}
              mask={calculatedMask}
              color="primary"
              variant="outlined"
              inputRef={inputRef}
              value={value ?? ''}
              onChange={e => handleValueChange(e.target.value)}
              size={size}
              disabled={disabled}
            />
          </div>
        </Grid>
        <Grid item className={clsx(classes?.buttonWrapper)}>
          <IconButton
            className={clsx(styles.button, incrementDisabled && styles.buttonDisabled, classes?.button)}
            onClick={() => handleStepChange(step)}
            id={`${name}-increment`}
            size={size}
            disabled={incrementDisabled}
          >
            <PlusIcon color={incrementDisabled ? colors.grey120 : colors.secondary.main} />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
);
