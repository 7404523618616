import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../assets';

export const useSliderStyles = makeStyles<Theme>({
  root: {
    color: colors.primary.main,
  },

  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    marginTop: -5,
    marginLeft: -10,

    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },

  active: {},

  valueLabel: {
    left: 'calc(-50% + 4px)',
  },

  track: {
    height: 10,
    borderRadius: 5,
  },

  rail: {
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.grey20,
    boxShadow: 'inset 3px 0 6px rgba(0, 0, 0, 0.5)',
  },
});
