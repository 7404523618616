import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  title: {
    color: colors.grey140,
  },
}));
