import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from './GeneralTopbar.styles';
import { GeneralTopbarDesktopSkeleton, GeneralTopbarMobileSkeleton } from './views';

export const GeneralTopbarSkeleton = () => {
  const styles = useStyles({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={styles.root}>{!isMobile ? <GeneralTopbarDesktopSkeleton /> : <GeneralTopbarMobileSkeleton />}</div>
  );
};
