import SvgIcon from '@material-ui/core/SvgIcon';

import { SvgIconProps } from './SvgIcon.types';

export const FileImageIcon = ({ className, fontSize }: Omit<SvgIconProps, 'color'>) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="40" height="48" viewBox="0 0 40 48" fill="none">
      <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M40 16V46.4C40 47.3 39.3 48 38.4 48H1.6C0.7 48 0 47.3 0 46.4V1.6C0 0.7 0.7 0 1.6 0H24L40 16Z"
            fill="#6798F8"
          />
          <g opacity="0.56" filter="url(#filter0_d)">
            <path d="M40 16H25.6C24.7 16 24 15.3 24 14.4V0L40 16Z" fill="#ECF2FE" />
          </g>
          <path
            d="M27.1 20H12.7C11.8 20 11 20.7 11 21.7V33.5C11 34.4 11.7 35.2 12.7 35.2H27C27.9 35.2 28.7 34.5 28.7 33.5V21.7C28.7 20.8 28 20 27.1 20ZM12.7 21.3H27C27.2 21.3 27.4 21.5 27.4 21.7V32L23.5 27.7C23.3 27.4 22.9 27.4 22.6 27.7L21.7 28.4C21.5 28.7 21.1 28.7 20.9 28.4L18.7 25.9C18.3 25.5 17.7 25.5 17.4 25.9L12.4 31.9V21.7C12.3 21.4 12.5 21.3 12.7 21.3Z"
            fill="white"
          />
          <path
            d="M24.3001 26.1C25.239 26.1 26.0001 25.3389 26.0001 24.4C26.0001 23.4611 25.239 22.7 24.3001 22.7C23.3612 22.7 22.6001 23.4611 22.6001 24.4C22.6001 25.3389 23.3612 26.1 24.3001 26.1Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="20"
            y="0"
            width="24"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.291667 0 0 0 0 0 0 0 0 0 1.04308e-07 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect width="40" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
