import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    padding: 16,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 8,
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  titleSection: {
    flex: 1,

    [theme.breakpoints.up('sm')]: {
      flex: 'initial',
    },
  },
  statusSection: {
    marginLeft: 24,

    [theme.breakpoints.up('sm')]: {
      marginLeft: 48,
    },
  },
  descriptionSection: {
    width: '100%',
  },
  updateButton: {
    color: 'white',
    backgroundColor: colors.primary.main,
    marginTop: 16,
    padding: 8,
    textAlign: 'center',
    borderRadius: 4,

    [theme.breakpoints.up('sm')]: {
      color: colors.primary.main,
      backgroundColor: 'initial',
      marginTop: 0,
      padding: 0,
    },
  },
}));
