import SvgIcon from '@material-ui/core/SvgIcon';

import { SvgIconProps } from './SvgIcon.types';

export const FileIcon = ({ className, fontSize }: Omit<SvgIconProps, 'color'>) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="40" height="48" viewBox="0 0 40 48" fill="none">
      <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M40 16V46.4C40 47.3 39.3 48 38.4 48H1.6C0.7 48 0 47.3 0 46.4V1.6C0 0.7 0.7 0 1.6 0H24L40 16Z"
            fill="#6798F8"
          />
          <g opacity="0.56" filter="url(#filter0_d)">
            <path d="M40 16H25.6C24.7 16 24 15.3 24 14.4V0L40 16Z" fill="#ECF2FE" />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="20"
            y="0"
            width="24"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.291667 0 0 0 0 0 0 0 0 0 1.04308e-07 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect width="40" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
