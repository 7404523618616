import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = () => {
  const style = makeStyles({
    container: {
      alignItems: 'center',
    },
    avatar: {
      '&.large': {
        height: '24px',
        width: '24px',
      },
      '&.medium': {
        height: '22px',
        width: '22px',
      },
      '&.small': {
        height: '20px',
        width: '20px',
      },
    },
    name: {
      height: '100%',
      '&.large': {
        fontSize: '18px',
      },
      '&.medium': {
        fontSize: '16px',
      },
      '&.small': {
        fontSize: '14px',
      },
    },
    underlineName: {
      textDecoration: 'underline',
    },
  });

  return style();
};
