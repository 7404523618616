import { memo, MouseEvent as ReactMouseEvent, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';
import { WatchLater } from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment';

import { TaskCardProps } from '../types';
import { FlagIcon } from 'assets';
import { Paper, StatusBadge } from 'components';
import { useEllipsis } from 'hooks';

import { useStyles } from './styles';

export const TaskCardDesktop = memo(({ task, onClick }: TaskCardProps) => {
  const styles = useStyles();

  const { renderEllipsisText: renderSummary } = useEllipsis({
    text: (
      <Typography variant="body1" color="inherit">
        <b>{task.summary}</b>
      </Typography>
    ),
  });

  const handleClick = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      if (onClick) {
        onClick(task);
      }
    },
    [task, onClick],
  );

  return (
    <Paper
      variant="elevation"
      elevation={24}
      className={clsx(styles.root, onClick && styles.clickable)}
      onClick={handleClick}
    >
      <Typography variant="body1" color="primary" className={styles.taskIdSection}>
        <u>#{task.id}</u>
      </Typography>

      <div className={styles.summarySection}>{renderSummary()}</div>

      <div className={styles.dueDateSection}>
        <StatusBadge
          size="small"
          text={task.isOverdue ? 'Overdue' : moment(task.due.datetime).utc(true).format('MMM D')}
          icon={WatchLater}
          type={task.isOverdue ? 'error' : 'info'}
        />
      </div>

      <div className={styles.dueTimeSection}>
        <Typography variant="body1" color="inherit">
          {moment(task.due.datetime).utc(true).format('h:mmA')}
        </Typography>
      </div>

      <div className={styles.boardSwimlaneSection}>
        <div className={styles.boardItem}>
          <StatusBadge text={task.board.name} size="small" customColor={task.board.color} />
        </div>

        <div className={styles.swimlaneItem}>
          <Typography variant="body1" className={styles.swimlane}>
            {task.swimlane.name}
          </Typography>
        </div>
      </div>

      <div className={styles.flagItem}>{task.flag.active ? <FlagIcon /> : <></>}</div>
    </Paper>
  );
});
