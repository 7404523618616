import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets';

type Props = {
  isMobile?: boolean;
};

export const useStyles = ({ isMobile }: Props) => {
  const style = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 48,
        paddingRight: 24,

        [isMobile ? theme.breakpoints.up('xs') : theme.breakpoints.down('sm')]: {
          display: 'block',
          padding: 16,
        },
      },

      content: {
        flex: 1,
      },

      titleRow: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        marginBottom: 8,
      },

      stepCount: {
        color: colors.grey120,
        fontSize: 14,
      },

      title: {
        color: colors.grey180,
      },

      nextStep: {
        color: colors.grey140,
        fontSize: 14,
      },

      actions: {
        width: 332,
        marginLeft: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [isMobile ? theme.breakpoints.up('xs') : theme.breakpoints.down('sm')]: {
          margin: 0,
          marginTop: 16,
          width: '100%',
        },
      },

      actionItem: {
        flex: 1,

        '&:first-child:not(:last-child)': {
          marginRight: 16,

          [isMobile ? theme.breakpoints.up('xs') : theme.breakpoints.down('xs')]: {
            marginRight: 8,
          },
        },
      },

      settings: {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translate(-16px, -50%)',

        [isMobile ? theme.breakpoints.up('xs') : theme.breakpoints.down('sm')]: {
          top: 0,
          transform: 'translate(-2px, 2px)',
        },
      },
    }),
  );

  return style({ isMobile });
};

export const LinearProgressStyled = withStyles(() =>
  createStyles({
    root: {
      height: 5,
    },
    colorSecondary: {
      backgroundColor: colors.secondary.light,
    },
  }),
)(LinearProgress);
