import { memo } from 'react';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './ConfirmationDialog.styles';
import { UseConfirmDialogParams } from './ConfirmationDialog.types';

export const ConfirmationTitle = memo(({ title, titleType }: Pick<UseConfirmDialogParams, 'title' | 'titleType'>) => {
  const styles = useStyles();

  return (
    <Typography
      variant="h4"
      className={clsx(
        titleType === 'error' && styles.titleError,
        titleType === 'info' && styles.titleInfo,
        titleType === 'success' && styles.titleSuccess,
        titleType === 'warning' && styles.titleWarning,
      )}
      component="div"
    >
      {title ?? 'Action Confirmation'}
    </Typography>
  );
});
