import { FC, memo, useMemo } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { useController, useWatch } from 'react-hook-form';

import { Checkbox } from 'components/checkbox/Checkbox';
import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { UserCheckbox } from 'components/userCheckbox/UserCheckbox';

import { useFormCheckboxGroupStyles } from './FormItems.styles';
import { FormCheckboxGroupProps } from './FormItems.types';

export const FormCheckboxGroup: FC<FormCheckboxGroupProps> = memo(
  ({
    row = false,
    groups,
    control,
    name,
    size = 'medium',
    isUserPill = false,
    variant = 'outline',
    required,
    ...rest
  }) => {
    const styles = useFormCheckboxGroupStyles();

    const {
      field: { ref, value, onChange, ...inputProps },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: [],
    });

    const checkboxIds = useWatch({ control, name: name }) || [];

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    const handleChange = (handleValue: number) => {
      const newArray = [...checkboxIds];
      const item = handleValue;
      //Ensure array isn"t empty
      if (newArray.length > 0) {
        //Attempt to find an item in array with matching id
        const index = newArray.findIndex(x => x === item);

        // If theres no match add item to the array
        if (index === -1) {
          newArray.push(item);
        } else {
          //If there is a match and the handleValue is empty, remove the item from the array
          newArray.splice(index, 1);
        }
      } else {
        //If the array is empty, add the item to the array
        newArray.push(item);
      }

      //Overwrite existing array with newArray}
      onChange(newArray);
    };

    return (
      <FormControl error={!!error} fullWidth>
        <FormGroup row={row} className={styles.formGroup}>
          {groups?.map(checkbox => (
            <FormControlLabel
              key={checkbox.id}
              name={name}
              label={null}
              className={styles.formControlLabel}
              control={
                isUserPill ? (
                  <UserCheckbox
                    {...rest}
                    {...inputProps}
                    userName={checkbox.name}
                    imageUrl={checkbox.imageUrl}
                    checked={value?.some((checked: number) => checked === checkbox.id)}
                    inputRef={ref}
                    onChange={() => handleChange(checkbox.id)}
                    size={size}
                    required={required}
                  />
                ) : (
                  <Checkbox
                    variant={variant}
                    checked={value?.some((checked: number) => checked === checkbox.id)}
                    inputRef={ref}
                    onChange={() => handleChange(checkbox.id)}
                    size={size}
                    required={required}
                    label={checkbox.name}
                    {...rest}
                    {...inputProps}
                  />
                )
              }
            />
          ))}
        </FormGroup>

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
