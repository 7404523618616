import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

type Props = {
  maxWidth: number;
};

export const useStyles = makeStyles<Theme, Props>(theme => ({
  container: {
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  wrapper: {
    width: ({ maxWidth }) => maxWidth,
  },
  confirmButton: {
    minWidth: 136,

    '& .MuiButtonBase-root': {
      width: '100%',
    },
  },
  buttonsContainer: {
    marginTop: 24,
  },
}));
