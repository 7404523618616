import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';

import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import CheckIcon from '@material-ui/icons/Check';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import clsx from 'clsx';
import moment from 'moment';

import { CalendarIconMain } from '../../assets';
import { CalendarPicker } from '../pickers/calendarPicker/CalendarPicker';
import { CalendarRangePicker } from '../pickers/calendarRangePicker/CalendarRangePicker';
import { Popover } from '../popover/Popover';

import { InputStyled, useStyles } from './Input.styles';
import { InputProps, InputCalendarAdornmentProps } from './Input.types';
import { MaskComponent } from './MaskComponent';

const InputCheckAdornment = memo(() => {
  const styles = useStyles();

  return (
    <InputAdornment position="end" className={styles.checkAdornment}>
      <CheckIcon />
    </InputAdornment>
  );
});

const InputCalendarAdornment = memo(
  ({
    value,
    type = 'date',
    dateFormat,
    minDate,
    maxDate,
    disabled,
    popoverProps,
    className,
    onChange,
  }: InputCalendarAdornmentProps) => {
    const [openPopover, setOpenPopover] = useState(false);
    const [currentDate, setCurrentDate] = useState<Date>(moment().toDate());
    const [startDate, setStartDate] = useState<Date>(moment().toDate());
    const [endDate, setEndDate] = useState<Date>(moment().toDate());

    const handleOpenPopover = useCallback(() => {
      setOpenPopover(true);
    }, []);

    const handleClosePopover = useCallback(() => {
      setOpenPopover(false);
    }, []);

    const handleDateChange = useCallback(
      (date?: Date) => {
        if (date && dateFormat) {
          handleClosePopover();

          if (onChange) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange({ target: { value: moment(date).format(dateFormat) } });
          }

          setCurrentDate(date);
        }
      },
      [dateFormat, onChange, setCurrentDate, handleClosePopover],
    );

    const handleRangeDateChange = useCallback(
      (dates?: Date[]) => {
        if (dates && dateFormat) {
          const [firstDate, secondDate] = dates;

          if (!!firstDate && !!secondDate) {
            handleClosePopover();
          }

          if (onChange) {
            onChange({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target: {
                value: `${moment(firstDate || null).format(dateFormat)} - ${
                  secondDate ? moment(secondDate).format(dateFormat) : ''
                }`,
              },
            });
          }

          setStartDate(firstDate);
          setEndDate(secondDate);
        }
      },
      [dateFormat, onChange, setStartDate, setEndDate, handleClosePopover],
    );

    useEffect(() => {
      if (type === 'date' && value && dateFormat) {
        setCurrentDate(moment(value as number, dateFormat).toDate());
      }
    }, [dateFormat, type, value, disabled]);

    return (
      <InputAdornment position="end" className={className}>
        <Popover
          open={openPopover}
          variant="click"
          disabled={disabled}
          popoverProps={popoverProps}
          toggle={
            <IconButton size="small" disabled={disabled}>
              <CalendarIconMain />
            </IconButton>
          }
          onOpen={handleOpenPopover}
          onClose={handleClosePopover}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {type === 'date' &&
              (() => {
                const currentDateMoment = moment(currentDate, dateFormat);
                const dateValue = currentDateMoment.isValid() ? currentDateMoment : moment();
                const minDateValue = minDate ? moment(minDate) : undefined;
                const maxDateValue = maxDate ? moment(maxDate) : undefined;

                return (
                  <CalendarPicker
                    date={dateValue}
                    minDate={minDateValue}
                    maxDate={maxDateValue}
                    onChange={date => handleDateChange(date?.toDate())}
                  />
                );
              })()}

            {type === 'date-range' &&
              (() => {
                const minDateValue = minDate ? new Date(minDate) : undefined;
                const maxDateValue = maxDate ? new Date(maxDate) : undefined;

                return (
                  <CalendarRangePicker
                    isRange
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDateValue}
                    maxDate={maxDateValue}
                    onChange={dates => handleRangeDateChange(dates)}
                  />
                );
              })()}
          </MuiPickersUtilsProvider>
        </Popover>
      </InputAdornment>
    );
  },
);

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        size = 'large',
        label,
        labelProps,
        placeholder,
        variant = 'standard',
        className,
        mask,
        checkAdornment,
        calendarAdornment,
        calendarAdornmentPopoverProps,
        dateFormat,
        minDate,
        maxDate,
        ...props
      }: InputProps,
      ref,
    ) => {
      const styles = useStyles();

      const labelShrink = useMemo(
        () => !!(labelProps?.shrink || (props.value && props.value !== '')) || undefined,
        [labelProps?.shrink, props.value],
      );

      const InputComponent = useMemo(() => {
        if (!mask) {
          return;
        }

        return MaskComponent(mask);
      }, [mask]);

      return (
        <div
          data-testid={props['data-testid']}
          className={clsx(
            className,
            styles.root,
            variant === 'outlined' && styles.rootOutlined,
            size === 'small' && styles.rootSmall,
            size === 'medium' && styles.rootMedium,
            size === 'large' && styles.rootLarge,
            props.error && styles.rootError,
            props.disabled && styles.rootDisabled,
          )}
        >
          {label && (
            <InputLabel htmlFor={props.name} {...labelProps} shrink={labelShrink}>
              {label}
            </InputLabel>
          )}

          <InputStyled
            ref={ref}
            placeholder={!label ? placeholder : ''}
            {...props}
            endAdornment={
              checkAdornment ? (
                <InputCheckAdornment />
              ) : calendarAdornment ? (
                <InputCalendarAdornment
                  value={props.value}
                  type={mask}
                  dateFormat={dateFormat}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={props.disabled}
                  popoverProps={calendarAdornmentPopoverProps}
                  className={label ? styles.calendarAdornment : ''}
                  onChange={props.onChange}
                />
              ) : (
                props.endAdornment
              )
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent={InputComponent as any}
          />
        </div>
      );
    },
  ),
);
