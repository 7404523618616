import { useMemo, useCallback, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { StarFilledIcon, StarIcon, colors } from 'assets';
import {
  Button,
  Checkbox,
  FormCheckbox,
  FormClockTimepicker,
  FormColorfulSelect,
  FormInput,
  FormNewSelect,
  FormSelect,
  FormTextEditor,
} from 'components';
import { TaskSwimlaneTypesEnum, resolutionTypes } from 'entities/tasks';
import { USATimeFormatAmPm } from 'utils';

import { TaskViewGeneralProps } from './model';
import { ViewSection } from './ui';

export const TaskViewGeneral = ({
  control,
  isClaim,
  isEstimate,
  loading,
  priorities,
  setValue,
  swimlanes,
  watch,
  user,
  users,
  onDelete,
  initialAssignees,
  isDone: isDoneTask,
  permissions,
}: TaskViewGeneralProps) => {
  const showAssignee = useMemo(() => !permissions?.assignee?.denied, [permissions?.assignee?.denied]);

  const showWatchTask = useMemo(() => !permissions?.watchTask?.denied, [permissions?.watchTask?.denied]);

  const showPrioriy = useMemo(() => !permissions?.priority?.denied, [permissions?.priority?.denied]);

  const priorityOptions = useMemo(
    () =>
      priorities
        .filter(({ showInDropdown }) => showInDropdown)
        .reduce(
          (res, priority) => ({
            ...res,
            [priority.id]: priority.name,
          }),
          {},
        ),
    [priorities],
  );

  // Like & Unlike
  const likes = watch('likes');
  const liked = useMemo(() => likes?.some(item => item.id === user.id), [likes, user.id]);

  const likeTask = useCallback(() => {
    if (user) {
      const items = !liked
        ? [
            ...likes,
            {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
            },
          ]
        : likes.filter(watcher => watcher.id !== user.id);

      setValue('likes', items, {
        shouldDirty: true,
      });
    }
  }, [user, liked, likes]);

  // Wtach & Unwatch
  const watchers = watch('watchers');
  const watched = useMemo(() => watchers?.length && watchers.some(item => item.id === user.id), [watchers, user.id]);
  const watchTask = useCallback(
    (checked: boolean) => {
      if (user) {
        setValue(
          'watchers',
          checked
            ? [
                ...watchers,
                {
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                },
              ]
            : watchers.filter(watcher => watcher.id !== user.id),
          {
            shouldDirty: true,
          },
        );
      }
    },
    [user, watchers],
  );

  const flag = watch('flag');
  useEffect(() => {
    if (!flag.active) {
      setValue('flag.reason', '', {
        shouldDirty: true,
      });
    }
  }, [flag.active]);

  // resolution fields visibility
  const swimlane = watch('swimlane');
  const isDone = useMemo(() => {
    const selectedSwimlane = swimlanes.find(({ id }) => id === swimlane);

    return selectedSwimlane?.type.id === TaskSwimlaneTypesEnum.Done;
  }, [swimlane, swimlanes]);

  const resolution = watch('resolution');

  // Assignees
  const assigneesOptions = useMemo(
    () =>
      users
        .filter(usr => (!usr.approved ? initialAssignees?.find(assignee => assignee.value === usr.id) : true))
        .map(({ id, firstName, lastName, imageApproved, approved }) => ({
          value: id,
          label: `${firstName} ${lastName}`,
          userImgUrl: imageApproved?.url,
          isDisabled: !approved,
        })),
    [initialAssignees, users],
  );

  useEffect(() => {
    if (resolution === resolutionTypes.Denied) {
      setValue('totalCost', undefined);
    }
  }, [resolution]);

  useEffect(() => {
    if (!isClaim) {
      return;
    }

    const selectedSwimlane = swimlanes.find(({ id }) => id === swimlane);

    if (selectedSwimlane?.type.id !== TaskSwimlaneTypesEnum.Done) {
      setValue('resolution', undefined);
      setValue('totalCost', undefined);
      setValue('update', undefined);
    }
  }, [isClaim, swimlane, swimlanes]);

  return (
    <ViewSection title="General">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item xs={12} style={{ width: '0%' }}>
              <div style={{ maxWidth: 'fit-content' }}>
                <FormColorfulSelect
                  palette="basic"
                  name="swimlane"
                  fullWidth
                  control={control}
                  options={swimlanes}
                  disabled={loading || (isDoneTask && isClaim)}
                />
              </div>
            </Grid>

            {onDelete && (
              <Grid item style={{ minWidth: 'fit-content' }}>
                <Button size="small" color="primary" disabled={isDoneTask} onClick={onDelete}>
                  Delete task
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {isClaim && isDone && (
          <>
            <Grid item xs={12}>
              <FormSelect
                label="Type"
                name="resolution"
                control={control}
                required
                options={resolutionTypes}
                fullWidth
                disabled={loading || isDoneTask}
              />
            </Grid>

            {resolution && resolution !== resolutionTypes.Denied && (
              <Grid item xs={12}>
                <FormInput
                  name="totalCost"
                  control={control}
                  required
                  label="Total cost"
                  size="medium"
                  disabled={loading || isDoneTask}
                  mask="positive-two-decimal"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormTextEditor label="Note" name="update" control={control} disabled={loading || isDoneTask} required />
            </Grid>
          </>
        )}

        <Grid item xs={7} sm={7}>
          <FormInput
            name="dueDate"
            control={control}
            label="Due date"
            calendarAdornment
            size="medium"
            mask="date"
            dateFormat="MM/DD/YYYY"
            disabled={loading || isClaim || isDoneTask}
          />
        </Grid>

        <Grid item xs={5} sm={5}>
          <FormClockTimepicker
            name="dueTime"
            control={control}
            inputStyledButton
            disabled={loading || isClaim || isDoneTask}
            format={USATimeFormatAmPm}
          />
        </Grid>

        {showAssignee && (
          <Grid item xs={12}>
            <FormNewSelect
              control={control}
              name="assignees"
              label="Assignee"
              isMulti={!isEstimate}
              isUserName
              isShowDropdownIndicator={false}
              options={assigneesOptions}
              isDisabled={loading || isDoneTask}
              cacheOptions
              isClearable
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            {showWatchTask && (
              <Grid item>
                <Checkbox
                  label="Watch this task"
                  onChange={watchTask}
                  disabled={loading || isDoneTask}
                  size="medium"
                  variant="outline"
                  className="checkbox"
                  checked={!!watched}
                />
              </Grid>
            )}

            <Grid item>
              <FormCheckbox
                control={control}
                disabled={loading || isDoneTask}
                label="Flag this task"
                name="flag.active"
              />
            </Grid>
          </Grid>

          {flag.active && (
            <div style={{ marginTop: 16 }}>
              <FormInput
                name="flag.reason"
                control={control}
                label="Add a reason for flagging"
                size="medium"
                disabled={loading || isDoneTask}
              />
            </div>
          )}
        </Grid>

        {showPrioriy && (
          <Grid item xs={12}>
            <FormSelect
              name="priority"
              label="Priority"
              control={control}
              options={priorityOptions}
              fullWidth
              required
              disabled={loading || isDoneTask}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ marginRight: 16 }}>
              <Button
                color="primary"
                onClick={likeTask}
                size="small"
                startIcon={liked ? <StarFilledIcon /> : <StarIcon />}
                disabled={isDoneTask}
              >
                {liked ? 'Liked' : 'Like'}
              </Button>
            </div>

            <div style={{ color: colors.grey120 }}>
              {likes?.length ? (
                <Typography color="inherit" component="span" style={{ fontSize: 14, lineHeight: 1 }} variant="h6">
                  Liked by {likes.map((item, index) => `${index ? ', ' : ''} ${item.name}`)}
                </Typography>
              ) : (
                ''
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </ViewSection>
  );
};
