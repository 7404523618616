import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  updateListWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.grey10,
    border: `1px solid ${colors.grey20}`,
    borderRadius: 4,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  updateItem: {
    padding: '8px 0',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey40}`,
    },
  },
  updateLabel: {
    height: 51,

    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
  },
  updateDataRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  updateDataCol: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,

    '&:not(:first-child)': {
      justifyContent: 'center',
    },

    '&:nth-child(3)': {
      marginLeft: 12,
      marginRight: 12,
      width: 30,
      minWidth: 30,
    },

    [theme.breakpoints.down('lg')]: {
      flex: 'initial',
      minWidth: 80,

      '&:first-child': {
        minWidth: 128,
      },
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'initial',
      marginTop: 4,
      marginBottom: 4,
      maxWidth: '100%',

      '&:first-child': {
        marginBottom: 0,
        width: '100%',
        justifyContent: 'initial',
      },
    },
  },
  value: {
    borderRadius: 4,
    padding: '4px 8px',
    display: 'inline-block',
    fontSize: 18,
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 180,

    '&.old': {
      backgroundColor: colors.functionals.alertLight,
      textDecoration: 'line-through',
    },

    '&.new': {
      backgroundColor: colors.functionals.successLight,
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));
