import { memo } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme';

export const StarFilledIcon = memo(
  ({
    className,
    filledColor = colors.primary.light,
    strokeColor = colors.primary.main,
    fontSize = 24,
  }: {
    className?: string;
    filledColor?: string;
    strokeColor?: string;
    fontSize?: number;
  }) => (
    <SvgIcon className={className} style={{ fontSize }} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4603 3.24527L16.9603 8.52527C17.0903 8.79657 17.3303 8.97396 17.6103 9.02614L23.2003 9.87135C23.9103 9.9757 24.2003 10.894 23.6803 11.4157L19.6403 15.527C19.4403 15.7357 19.3403 16.0383 19.3903 16.3305L20.3403 22.1322C20.4603 22.8731 19.7203 23.4366 19.0803 23.0922L14.0803 20.3479C13.8303 20.2122 13.5203 20.2122 13.2703 20.3479L8.27032 23.0922C7.63032 23.447 6.89032 22.8731 7.01032 22.1322L7.96032 16.3305C8.01032 16.0383 7.91032 15.7357 7.71032 15.527L3.67032 11.4157C3.15032 10.894 3.44032 9.9757 4.15032 9.87135L9.74032 9.02614C10.0203 8.9844 10.2703 8.79657 10.3903 8.52527L12.8903 3.24527C13.2203 2.56701 14.1403 2.56701 14.4603 3.24527Z"
        fill={filledColor}
      />
      <path
        d="M5.87006 21.767C5.56006 21.767 5.25006 21.6626 4.99006 21.4643C4.53006 21.1096 4.30006 20.5252 4.40006 19.9409L5.35006 14.1391C5.36006 14.0557 5.34006 13.9722 5.28006 13.9096L1.24006 9.79826C0.830057 9.38087 0.690057 8.76522 0.870057 8.20174C1.05006 7.63826 1.51006 7.2313 2.08006 7.13739L7.67006 6.29217C7.75006 6.28174 7.82006 6.22957 7.85006 6.15652L10.3501 0.876522C10.6001 0.333913 11.1101 0 11.6801 0C12.2501 0 12.7701 0.333913 13.0201 0.866087L15.5201 6.14609C15.5601 6.21913 15.6201 6.2713 15.7001 6.28174L21.2901 7.12696C21.8601 7.21043 22.3201 7.61739 22.5001 8.1913C22.6801 8.75478 22.5301 9.37043 22.1201 9.78783L18.0801 13.8991C18.0201 13.9617 18.0001 14.0452 18.0101 14.1287L18.9701 19.9304C19.0701 20.5252 18.8401 21.1096 18.3701 21.4539C17.9001 21.7983 17.3001 21.8504 16.8001 21.5687L11.8001 18.8243C11.7301 18.7826 11.6401 18.7826 11.5701 18.8243L6.57006 21.5687C6.35006 21.7043 6.11006 21.767 5.87006 21.767ZM11.6801 1.30435C11.6201 1.30435 11.5201 1.32522 11.4601 1.45043L8.96006 6.73043C8.74006 7.18956 8.32006 7.51304 7.84006 7.58609L2.25006 8.4313C2.12006 8.45217 2.07006 8.54609 2.06006 8.6087C2.04006 8.66087 2.02006 8.76522 2.12006 8.86957L6.16006 12.9809C6.51006 13.3357 6.67006 13.8574 6.59006 14.3583L5.64006 20.16C5.62006 20.2957 5.69006 20.3791 5.74006 20.4104C5.79006 20.4417 5.88006 20.4939 6.00006 20.4313L11.0001 17.687C11.4401 17.447 11.9601 17.447 12.3901 17.687L17.3901 20.4313C17.5101 20.4939 17.6001 20.4417 17.6501 20.4104C17.7001 20.3791 17.7701 20.2957 17.7501 20.16L16.7901 14.3583C16.7101 13.847 16.8701 13.3357 17.2201 12.9809L21.2601 8.86957C21.3501 8.77565 21.3401 8.66087 21.3201 8.6087C21.3001 8.55652 21.2501 8.45217 21.1201 8.4313L15.5301 7.58609C15.0401 7.51304 14.6201 7.18956 14.4001 6.73043L11.9001 1.45043C11.8401 1.32522 11.7401 1.30435 11.6801 1.30435Z"
        fill={strokeColor}
      />
    </SvgIcon>
  ),
);
