import SvgIcon from '@material-ui/core/SvgIcon';

export const OxfordCapIcon = ({ fontSize }: { fontSize?: number }) => (
  <SvgIcon width={58} height={54} viewBox="0 0 58 54" fill="none" style={{ fontSize }}>
    <path
      d="M55.3393 37.1393L50.7529 32.8691V18.9199L52.7913 18.1608C52.9952 18.0659 53.199 17.8761 53.199 17.5915C53.199 17.3068 52.9952 17.117 52.7913 17.0221L29.5533 9.14604C29.4513 9.05115 29.2475 9.05115 29.1456 9.14604L5.8056 17.117C5.49984 17.2119 5.39792 17.4017 5.39792 17.6863C5.39792 17.971 5.49984 18.1608 5.8056 18.2557L14.8766 21.5769V31.8253C14.8766 32.4895 15.2843 33.1538 15.9977 33.5334C20.2784 35.4312 24.8649 36.3801 29.4513 36.3801C34.0378 36.3801 38.6243 35.4312 42.9049 33.5334C43.6184 33.2487 44.0261 32.5844 44.0261 31.8253V21.482L49.7337 19.3944V32.8691L45.0453 37.1393C44.9434 37.2341 44.8415 37.4239 44.8415 37.5188C44.8415 37.7086 44.9434 37.8035 45.0453 37.8984L49.6318 42.1685V46.2489C49.6318 46.5336 49.9375 46.8183 50.2433 46.8183C50.549 46.8183 50.8548 46.5336 50.8548 46.2489V42.1685L55.4413 37.8984C55.6451 37.8035 55.6451 37.4239 55.3393 37.1393Z"
      fill="#EFD1AE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5324 18.3269C12.8842 18.3269 13.1694 18.5924 13.1694 18.9199V29.453C13.1694 29.7963 13.3093 29.9766 13.4824 30.041C13.4949 30.0457 13.5073 30.0507 13.5194 30.0561C21.7031 33.6799 31.1811 33.6794 39.2598 30.0597C39.4317 29.9777 39.618 29.7218 39.618 29.453V18.9199C39.618 18.5924 39.9032 18.3269 40.255 18.3269C40.6068 18.3269 40.892 18.5924 40.892 18.9199V29.453C40.892 30.1314 40.4691 30.823 39.8264 31.1221L39.8179 31.1261L39.8179 31.126C31.392 34.9032 21.5145 34.9042 12.9887 31.1345C12.1588 30.8147 11.8954 30.0536 11.8954 29.453V18.9199C11.8954 18.5924 12.1806 18.3269 12.5324 18.3269Z"
      fill="#EA7200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8148 30.3997C47.0636 30.1681 47.4669 30.1681 47.7157 30.3997L52.4722 34.8282C52.721 35.0598 52.721 35.4353 52.4722 35.6669L47.7157 40.0954C47.4669 40.327 47.0636 40.327 46.8148 40.0954L42.0583 35.6669C41.8095 35.4353 41.8095 35.0598 42.0583 34.8282L46.8148 30.3997ZM47.2653 31.6578L43.4096 35.2476L47.2653 38.8373L51.1209 35.2476L47.2653 31.6578Z"
      fill="#33753B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.2875 15.575C47.6393 15.575 47.9245 15.8405 47.9245 16.168V30.7814C47.9245 31.109 47.6393 31.3745 47.2875 31.3745C46.9357 31.3745 46.6505 31.109 46.6505 30.7814V16.168C46.6505 15.8405 46.9357 15.575 47.2875 15.575Z"
      fill="#33753B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.2875 39.0134C47.6393 39.0134 47.9245 39.2789 47.9245 39.6064V43.8766C47.9245 44.2041 47.6393 44.4697 47.2875 44.4697C46.9357 44.4697 46.6505 44.2041 46.6505 43.8766V39.6064C46.6505 39.2789 46.9357 39.0134 47.2875 39.0134Z"
      fill="#33753B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1743 6.78628C26.3159 6.73791 26.4714 6.73791 26.613 6.78628L49.953 14.7572C50.2013 14.842 50.3676 15.061 50.3706 15.3072C50.3736 15.5535 50.2128 15.7759 49.9666 15.866L26.6266 24.4063C26.4769 24.4611 26.3104 24.4611 26.1606 24.4063L2.82066 15.866C2.57445 15.7759 2.41367 15.5535 2.4167 15.3072C2.41973 15.061 2.58593 14.842 2.8343 14.7572L26.1743 6.78628ZM4.84743 15.3331L26.3936 23.2171L47.9398 15.3331L26.3936 7.97479L4.84743 15.3331Z"
      fill="#EA7200"
    />
    <path
      d="M26.3936 15.5987C27.1254 15.5987 27.7186 15.1739 27.7186 14.6498C27.7186 14.1257 27.1254 13.7009 26.3936 13.7009C25.6618 13.7009 25.0686 14.1257 25.0686 14.6498C25.0686 15.1739 25.6618 15.5987 26.3936 15.5987Z"
      fill="#2F743B"
    />
    <path
      d="M5.43032 26.99C5.32999 25.155 4.39113 24.2809 2.42741 24.1942C2.41307 24.1942 2.41307 24.1808 2.42741 24.1808C4.39829 24.0874 5.33715 23.2133 5.43032 21.385C5.43032 21.3717 5.44466 21.3717 5.44466 21.385C5.54499 23.22 6.48385 24.0941 8.44757 24.1808C8.46191 24.1808 8.46191 24.1942 8.44757 24.1942C6.47669 24.2876 5.53783 25.1617 5.44466 26.99C5.44466 27.0033 5.43032 27.0033 5.43032 26.99Z"
      fill="#E9F0EB"
    />
    <path
      d="M45.3053 10.115C45.205 8.28003 44.2661 7.40592 42.3024 7.31917C42.2881 7.31917 42.2881 7.30583 42.3024 7.30583C44.2733 7.21241 45.2122 6.3383 45.3053 4.51001C45.3053 4.49666 45.3197 4.49666 45.3197 4.51001C45.42 6.34497 46.3589 7.21908 48.3226 7.30583C48.3369 7.30583 48.3369 7.31917 48.3226 7.31917C46.3517 7.41259 45.4128 8.2867 45.3197 10.115C45.3197 10.1283 45.3053 10.1283 45.3053 10.115Z"
      fill="#E9F0EB"
    />
    <path
      d="M4.18792 25.8384C4.03797 24.882 3.49066 24.3655 2.45602 24.2259C2.40354 24.2189 2.40354 24.1561 2.45602 24.1491C3.48316 24.0095 4.03797 23.4999 4.18792 22.5366C4.19542 22.4878 4.26289 22.4878 4.27039 22.5366C4.42034 23.493 4.96765 24.0095 6.0023 24.1491C6.05478 24.1561 6.05478 24.2189 6.0023 24.2259C4.97515 24.3655 4.42034 24.8751 4.27039 25.8384C4.26289 25.8872 4.19542 25.8872 4.18792 25.8384Z"
      fill="#226937"
    />
    <path
      d="M44.0629 8.96335C43.913 8.00704 43.3657 7.4905 42.331 7.35089C42.2785 7.34391 42.2785 7.28109 42.331 7.27411C43.3582 7.1345 43.913 6.62494 44.0629 5.66165C44.0704 5.61278 44.1379 5.61278 44.1454 5.66165C44.2953 6.61796 44.8427 7.1345 45.8773 7.27411C45.9298 7.28109 45.9298 7.34391 45.8773 7.35089C44.8501 7.4905 44.2953 8.00006 44.1454 8.96335C44.1379 9.01222 44.0704 9.01222 44.0629 8.96335Z"
      fill="#226937"
    />
    <path d="M49.6277 6.11774L49.098 5.62454L48.5683 6.11774L49.098 6.61095L49.6277 6.11774Z" fill="white" />
    <path
      d="M20.5191 44.9811C20.3477 42.8473 19.2288 41.7972 16.937 41.646C16.9099 41.646 16.9099 41.604 16.937 41.604C19.2288 41.4444 20.3567 40.4027 20.5191 38.2689C20.5191 38.2437 20.5642 38.2437 20.5642 38.2689C20.7357 40.4027 21.8545 41.4528 24.1464 41.604C24.1734 41.604 24.1734 41.646 24.1464 41.646C21.8545 41.8056 20.7266 42.8473 20.5642 44.9811C20.5552 45.0063 20.5191 45.0063 20.5191 44.9811Z"
      fill="#E9F0EB"
    />
    <path
      d="M23.0463 43.972C22.8672 41.8377 21.8075 40.778 19.6731 40.5989C19.6358 40.5989 19.6358 40.5466 19.6731 40.5466C21.8075 40.3675 22.8672 39.3078 23.0463 37.1735C23.0463 37.1362 23.0985 37.1362 23.0985 37.1735C23.2776 39.3078 24.3373 40.3675 26.4716 40.5466C26.5089 40.5466 26.5089 40.5989 26.4716 40.5989C24.3373 40.778 23.2776 41.8377 23.0985 43.972C23.0985 44.0093 23.0463 44.0093 23.0463 43.972Z"
      fill="#226937"
    />
    <path d="M23.562 40.5731L23.0712 40.0824L22.5805 40.5731L23.0712 41.0639L23.562 40.5731Z" fill="white" />
  </SvgIcon>
);
