import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import { TabList, TabPanel } from '@material-ui/lab';

import { colors, RiftFont } from 'assets';

type StylesProps = {
  isShowLeftGradient: boolean;
  isShowRightGradient: boolean;
};

export const TabsListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: colors.grey20,
        borderRadius: '4px',
        padding: '7px',
      },
    },
    indicator: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      backgroundColor: colors.primary.main,
    },
  }),
)(TabList);

export const TabStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      padding: '21px 12px',

      '& .MuiTab-wrapper': {
        fontFamily: RiftFont,
        fontSize: '18px',
        fontWeight: 'bold',
        color: colors.grey140,
        lineHeight: '22px',
      },

      '& .MuiTouchRipple-root': {
        color: colors.primary.main,
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 'fit-content',
        padding: '6px 8px',
        minHeight: '0px',
      },
    },
    selected: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: colors.white,
        // padding: '6px 10px',
        borderRadius: '4px',
      },
      '& .MuiTab-wrapper': {
        color: colors.primary.main,
      },
    },
  }),
)(Tab);

export const TabPanelStyled = withStyles(() =>
  createStyles({
    root: {
      padding: '24px 0px',
    },
  }),
)(TabPanel);

export const useStyles = makeStyles((theme: Theme) => ({
  tabsWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',

      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  tabsList: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 16px',
      minWidth: 'max-content',
    },
  },
  tabsContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginLeft: '-16px',
      marginRight: '-16px',

      '&:before, &:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        height: '100%',
        width: 51,
      },

      '&:before': {
        transition: 'opacity 200ms linear',
        opacity: ({ isShowLeftGradient }: StylesProps) => (isShowLeftGradient ? 1 : 0),
        left: 0,
        background: `
          linear-gradient(
            to right, 
            ${colors.grey20} 0%, 
            ${fade(colors.grey20, 0)} 100%
          )
        `,
      },

      '&:after': {
        transition: 'opacity 200ms linear',
        opacity: ({ isShowRightGradient }: StylesProps) => (isShowRightGradient ? 1 : 0),
        right: 0,
        background: `
          linear-gradient(
            to left, 
            ${colors.grey20} 0%, 
            ${fade(colors.grey20, 0)} 100%
          )
        `,
      },
    },
  },
}));
