import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: 0,
    },
    popoverRoot: {
      pointerEvents: 'none',
    },
    popoverPaper: {
      pointerEvents: 'all',
    },
    menuItem: {
      color: colors.grey160,
      '&:hover, &:active, &.Mui-selected': {
        color: colors.grey190,
      },
    },
  }),
);
