import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckboxCircleIcon = ({
  className,
  checked,
  size = 'medium',
}: {
  className?: string;
  checked?: boolean;
  size?: 'medium' | 'small';
}) => {
  return (
    <SvgIcon
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      fontSize={size === 'small' ? 'small' : 'default'}
    >
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.4974 10.8582C19.4974 5.37825 15.1328 0.935852 9.7487 0.935852C4.36464 0.935852 0 5.37825 0 10.8582C0 16.3382 4.36464 20.7806 9.7487 20.7806C15.1328 20.7806 19.4974 16.3382 19.4974 10.8582Z"
          fill="#F0CBA7"
        />
        {checked && (
          <>
            <path
              d="M19.4974 10.1243C19.4974 4.64436 15.1328 0.201965 9.7487 0.201965C4.36464 0.201965 0 4.64436 0 10.1243C0 15.6043 4.36464 20.0467 9.7487 20.0467C15.1328 20.0467 19.4974 15.6043 19.4974 10.1243Z"
              fill="#EA7200"
            />
            <path
              d="M14.3665 7.51318L8.0175 13.7799L5.13086 10.9317"
              stroke="white"
              strokeWidth="1.473"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )}
      </svg>
    </SvgIcon>
  );
};
