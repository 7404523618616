import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { ListStyled } from 'components/alertsList/AlertsList.styles';

import { SchedulesListProps } from './SchedulesList.types';

export const SchedulesList = ({ schedules }: SchedulesListProps) => {
  return (
    <ListStyled>
      {schedules.map((schedule, i) => (
        <ListItem key={i}>
          <Typography variant="body1">
            {moment(schedule.startDate).format('h:mma')} - {moment(schedule.endDate).format('h:mma')}
          </Typography>
        </ListItem>
      ))}
    </ListStyled>
  );
};
