import { memo } from 'react';

import Typography from '@material-ui/core/Typography';

import { PricingItem } from './pricingItem/PricingItem';
import { ListStyled, useStyles } from './PricingList.styles';
import { PricingListProps } from './PricingList.types';

export const PricingList = memo(({ pricingsMap }: PricingListProps) => {
  const styles = useStyles();

  return (
    <div>
      {pricingsMap &&
        Object.keys(pricingsMap).length > 0 &&
        Object.entries(pricingsMap).map(([category, pricings], i) => (
          <div className={styles.categoryContainer} key={i}>
            <div>
              <Typography variant="h5">{category}</Typography>
            </div>
            <div>
              <ListStyled>
                {pricings.map((pricing, i) => (
                  <PricingItem pricingItem={{ id: pricing.id, name: pricing.name, price: pricing.price }} key={i} />
                ))}
              </ListStyled>
            </div>
          </div>
        ))}
      {!pricingsMap ||
        (Object.keys(pricingsMap).length === 0 && (
          <div className={styles.noPricingContainer}>
            <Typography variant="h6">No Pricing Information Available</Typography>
          </div>
        ))}
    </div>
  );
});
