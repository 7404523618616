import { forwardRef, memo } from 'react';

import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import { SubNavigationItemProps } from './subMenu.types';
import { useStyles } from './SubNavigationItem.styles';

export const SubNavigationItem = memo(
  forwardRef<HTMLDivElement, SubNavigationItemProps>(({ id, subItemName, listItemProps, badge, isHover }, ref) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <ListItem
        id={id}
        className={clsx(styles.subItem, {
          [styles.hover]: isHover,
          [styles.subItemMobile]: isMobile,
        })}
        {...listItemProps}
      >
        <Typography variant="h5" color="inherit">
          {subItemName}
        </Typography>

        {badge && (
          <Badge
            {...badge}
            classes={{
              badge: clsx(badge.classes?.badge ?? styles.badge, badge.variant === 'dot' && styles.badgeDot),
            }}
          />
        )}
      </ListItem>
    );
  }),
);
