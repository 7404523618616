import 'react-datepicker/dist/react-datepicker.css';
import { memo, useMemo } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import { useStyles } from './CalendarRangePicker.styles';
import { CalendarRangePickerProps } from './CalendarRangePicker.types';
import { CalendarRangePickerHeader } from './header/Header';
import { CalendarRangePickerYearHeader } from './header/YearHeader';

export const CalendarRangePicker = memo(
  ({
    startDate,
    endDate,
    minDate,
    maxDate,
    isRange,
    maxDuration,
    showMonthYearPicker,
    showFullMonthYearPicker,
    headerProps,
    onChange,
  }: CalendarRangePickerProps) => {
    const styles = useStyles();

    const handleChange = (date: Date | [Date, Date] | null) => {
      if (Array.isArray(date)) {
        const dateYear = new Date(date[0]).getFullYear();
        const currentYear = new Date().getFullYear();

        if (dateYear === currentYear && showMonthYearPicker) {
          onChange([new Date(), new Date()]);
        } else {
          onChange(date);
        }

        return;
      }

      if (date) {
        onChange([date]);

        return;
      }

      onChange([]);
    };

    const maxDateValue = useMemo(() => {
      return isRange && maxDuration && startDate && !endDate
        ? moment(startDate)
            .clone()
            .add(maxDuration - 1, 'days')
            .toDate()
        : maxDate
        ? maxDate
        : undefined;
    }, [isRange, maxDate, maxDuration, startDate, endDate]);

    return (
      <DatePicker
        calendarClassName={styles.datePicker}
        onChange={handleChange}
        onYearChange={handleChange}
        onMonthChange={handleChange}
        minDate={minDate}
        selected={!isRange ? startDate : undefined}
        startDate={isRange ? startDate : undefined}
        endDate={isRange ? endDate : undefined}
        selectsRange={isRange}
        inline
        disabledKeyboardNavigation
        renderCustomHeader={props =>
          showMonthYearPicker ? (
            <CalendarRangePickerYearHeader {...headerProps} {...props} />
          ) : (
            <CalendarRangePickerHeader {...headerProps} {...props} />
          )
        }
        useWeekdaysShort={true}
        maxDate={maxDateValue}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
      />
    );
  },
);
