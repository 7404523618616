import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const PlayIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 40,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon style={{ fontSize }} width="40" height="40" viewBox="0 0 40 40" fill="none" fontSize="inherit">
      <rect width="40" height="40" rx="20" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 23.613C17.0005 24.2544 17.3758 24.8363 17.9598 25.1014C18.5438 25.3664 19.2289 25.2658 19.712 24.844L25.25 20L19.712 15.153C19.2291 14.7303 18.5436 14.6291 17.9592 14.8944C17.3748 15.1596 16.9997 15.7422 17 16.384V23.613Z"
        fill="white"
      />
    </SvgIcon>
  );
};
