import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const WarningIcon = ({
  className,
  color = colors.primary.main,
  bgColor = '#FFFFFF',
  fontSize,
}: SvgIconProps) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      fontSize="inherit"
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.71625 0.78389C4.94829 0.319823 5.42053 0 5.9759 0C6.53127 0 7.00351 0.319823 7.23554 0.78389L7.23605 0.784899L11.8759 10.113C11.8825 10.1261 11.8885 10.1396 11.8939 10.1532C12.0333 10.5015 12.0733 11.0096 11.7757 11.4064C11.5501 11.7072 11.1589 11.9622 10.7124 11.9622H1.28769C0.841206 11.9622 0.450024 11.7072 0.224383 11.4064C0.213171 11.3914 0.202737 11.3759 0.193122 11.3599C-0.0514109 10.9523 -0.044292 10.5294 0.106194 10.1532C0.111371 10.1403 0.117044 10.1275 0.123201 10.115L4.71625 0.78389Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45844 8.91944C5.571 8.80688 5.75066 8.69977 5.97583 8.69977C6.20101 8.69977 6.38067 8.80688 6.49323 8.91944C6.60579 9.032 6.7129 9.21166 6.7129 9.43683C6.7129 9.662 6.60579 9.84166 6.49323 9.95422C6.38067 10.0668 6.20101 10.1739 5.97583 10.1739C5.75066 10.1739 5.571 10.0668 5.45844 9.95422C5.34588 9.84166 5.23877 9.662 5.23877 9.43683C5.23877 9.21166 5.34588 9.032 5.45844 8.91944Z"
          fill={bgColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.97586 3.5766C6.27616 3.5766 6.5196 3.82004 6.5196 4.12033V7.79357C6.5196 8.09387 6.27616 8.33731 5.97586 8.33731C5.67557 8.33731 5.43213 8.09387 5.43213 7.79357V4.12033C5.43213 3.82004 5.67557 3.5766 5.97586 3.5766Z"
          fill={bgColor}
        />
      </svg>
    </SvgIcon>
  );
};
