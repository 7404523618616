import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { TimePicker } from '@material-ui/pickers';
import moment, { MomentInput } from 'moment';

import { ClockIcon } from '../../../assets';
import { Button } from '../../button/Button';
import { ConfirmationTooltip } from '../../confirmationTooltip/confirmationTooltip';
import { Paper } from 'components/paper/Paper';
import { USADateTimeFormat } from 'utils';

import { useStyles } from './ClockTimepicker.styles';
import { ClockTimepickerProps } from './ClockTimepicker.types';

export const ClockTimepicker = memo(
  ({
    value,
    format = 'HH:mm',
    handleConfirm,
    inputStyledButton,
    placement,
    disabled,
    buttonClasses,
    size = 'medium',
    maxDate,
    maxDateErrorText,
    minDate,
    minDateErrorText,
    ...rest
  }: ClockTimepickerProps) => {
    const styles = useStyles({ disabled, size });

    const [selectedTime, setSelectedTime] = useState(value);
    const [state, setState] = useState({
      isFuture: false,
      isPast: false,
    });

    const checkDate = useCallback(
      (date: MomentInput) => {
        const errors = {
          isFuture: false,
          isPast: false,
        };

        if (maxDate && maxDate.isBefore(date, 'minutes')) {
          errors.isFuture = true;
        }

        if (minDate && minDate.isAfter(date, 'minutes')) {
          errors.isPast = true;
        }

        setState(errors);
      },
      [minDate, maxDate],
    );

    const handleTimeChange = useCallback(
      dateTime => {
        checkDate(dateTime);
        setSelectedTime(dateTime);
      },
      [checkDate],
    );

    const handleClose = useCallback(() => {
      setSelectedTime(value);
    }, [value, setSelectedTime]);

    useEffect(() => {
      if (value) {
        checkDate(moment(value));
        setSelectedTime(value);
      }
    }, [value, checkDate]);

    const valueAsText = useMemo(
      () => (selectedTime ? moment(selectedTime).format(format) : 'Select'),
      [format, selectedTime],
    );

    const errorText = useMemo(() => {
      if (maxDate && state.isFuture) {
        return maxDateErrorText || `Time should be before MAX date: (${maxDate.format(USADateTimeFormat)})`;
      }

      if (minDate && state.isPast) {
        return minDateErrorText || `Time should be before MIN date: (${minDate.format(USADateTimeFormat)})`;
      }

      return;
    }, [maxDate, maxDateErrorText, minDate, minDateErrorText, state.isFuture, state.isPast]);

    return (
      <ConfirmationTooltip
        toggle={
          inputStyledButton ? (
            <Paper variant={!disabled ? 'outlined' : 'elevation'}>
              <div className={styles.customButton}>
                <ClockIcon />

                <div className={styles.valueText}>
                  <Typography variant="h5" component="span" color="secondary">
                    {valueAsText}
                  </Typography>
                </div>
              </div>
            </Paper>
          ) : (
            <Button
              buttonType="outlined"
              startIcon={
                <div className={styles.icon}>
                  <ClockIcon />
                </div>
              }
              disabled={disabled}
              classes={buttonClasses}
            >
              {valueAsText}
            </Button>
          )
        }
        fullWidthButton={inputStyledButton}
        placement={placement}
        zIndex={1300}
        onConfirm={() => {
          if (selectedTime) {
            handleConfirm(selectedTime);
          }
        }}
        confirmationDisabled={!selectedTime || state.isFuture || state.isPast}
        onClose={handleClose}
        disabled={disabled}
      >
        <TimePicker
          {...rest}
          variant="static"
          value={new Date(selectedTime || '')}
          onChange={handleTimeChange}
          disabled={disabled}
        />

        {errorText && (
          <div className={styles.error}>
            <Typography color="inherit" variant="subtitle1" align="center">
              {errorText}
            </Typography>
          </div>
        )}
      </ConfirmationTooltip>
    );
  },
);
