import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles({
  subItem: {
    minWidth: '150px',
    borderRadius: 0,
    padding: '8px 16px',
    margin: 0,
    color: colors.grey160,
    transition: 'background-color 250ms ease-out, color 250ms ease-out',

    '&:hover, &:active, &.Mui-selected': {
      backgroundColor: colors.grey10,
      color: colors.grey190,
    },
  },
  hover: {
    color: colors.primary.main,
    backgroundColor: colors.primary.superlight,
    borderRadius: 4,
  },
  subItemMobile: {
    width: 'calc(100% - 32px)',
    marginRight: 16,
    marginLeft: 16,
  },
  badge: {
    borderRadius: '100%',
    right: 'initial',
  },
  badgeDot: {
    height: 10,
    width: 10,
  },
});
