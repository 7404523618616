import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

type Props = {
  color?: string;
};

const fadeBg = (color: string) => fade(color, 1);

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    borderRadius: 12,
    width: 'fit-content',

    '& .MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '21px',
    },
    '& svg': {
      width: 12,
      height: 12,
      marginRight: 4,
    },
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },

  large: {
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4,
    },
  },
  medium: {
    '& svg': {
      width: 14,
      height: 14,
      marginRight: 4,
    },
  },
  small: {
    '& svg': {
      width: 12,
      height: 12,
      marginRight: 4,
    },
  },

  disabled: {
    backgroundColor: fadeBg(colors.functionals.disabledLight),

    '& .MuiTypography-root': {
      color: colors.functionals.disabled,
    },
    '& svg': {
      color: colors.functionals.disabled,
    },
  },

  disabledLight: {
    backgroundColor: fadeBg(colors.grey10),

    '& .MuiTypography-root': {
      color: colors.grey140,
    },
    '& svg': {
      color: colors.grey140,
    },
  },

  error: {
    backgroundColor: fadeBg(colors.functionals.alertLight),

    '& .MuiTypography-root': {
      color: colors.functionals.alert,
    },
    '& svg': {
      color: colors.functionals.alert,
    },
  },

  info: {
    backgroundColor: fadeBg(colors.functionals.infoLight),

    '& .MuiTypography-root': {
      color: colors.functionals.info,
    },
    '& svg': {
      color: colors.functionals.info,
    },
  },

  success: {
    backgroundColor: fadeBg(colors.functionals.successLight),

    '& .MuiTypography-root': {
      color: colors.functionals.success,
    },
    '& svg': {
      color: colors.functionals.success,
    },
  },

  warning: {
    backgroundColor: fadeBg(colors.functionals.warningLight),

    '& .MuiTypography-root': {
      color: colors.functionals.warning,
    },
    '& svg': {
      color: colors.functionals.warning,
    },
  },

  customColor: {
    backgroundColor: ({ color }: Props) => color && fade(color, 0.1),

    '& .MuiTypography-root': {
      color: ({ color }: Props) => color,
    },
    '& svg': {
      color: ({ color }: Props) => color,
    },
  },
}));
