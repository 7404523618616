import { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './Loader.styles';
import { LoaderProps } from './Loader.types';

export const Loader = memo(({ size = 'large', customColor, message }: LoaderProps) => {
  const styles = useStyles();

  return (
    <Grid
      className={clsx(
        styles.loader,
        size === 'xs' && styles.loaderXS,
        size === 'small' && styles.loaderSmall,
        size === 'medium' && styles.loaderMedium,
        size === 'large' && styles.loaderLarge,
      )}
      container
      justify="center"
      alignItems="center"
      data-testid="loader"
      direction="column"
    >
      <Grid item>
        <div className={styles.container}>
          <div className={styles.circle}>
            <div className={styles.quarter} style={{ borderColor: customColor }} />
          </div>
        </div>
      </Grid>

      {message && (
        <Grid item>
          <Typography variant="body2" className={styles.message}>
            {message}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});
