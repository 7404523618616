import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

type Props = {
  fullWidthButton?: boolean;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    maxWidth: 'initial',

    '& .MuiTooltip-tooltip': {
      maxWidth: 'initial',
    },
  },
  label: {
    marginBottom: 16,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tooltipTitle: {
    marginBottom: 16,
  },
  icon: {
    width: 16,
    height: 16,
  },
  toggleContainer: {
    display: ({ fullWidthButton }) => (fullWidthButton ? 'block' : 'inline-block'),
  },
}));
