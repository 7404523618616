import Button from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets/theme/palette';

export const ButtonStyled = withStyles(() =>
  createStyles({
    root: {
      minWidth: 'auto',
      '& a': {
        color: 'inherit',
        textDecoration: 'inherit',
        display: 'inherit',
      },
      fontSize: '16px',
      lineHeight: '25px',
      padding: '8px 16px',
    },
    sizeSmall: { fontSize: '14px', padding: '4px 10px' },
    sizeLarge: { fontSize: '20px', padding: '13px 19px' },
    label: {
      zIndex: 1,
    },
    contained: {
      backgroundColor: colors.primary.main,
      color: colors.basic.white,
      borderRadius: '3px',
      '&:hover': {
        backgroundColor: colors.primary.dark,
      },
      /*
       * Media target only: smartphones, touchscreens.
       * Disable hover on touch device, because it
       * sometimes cause that first button click not fire the click ever
       */
      '@media (hover: none) and (pointer: coarse)': {
        backgroundColor: colors.primary.main,
        '&:hover': {
          backgroundColor: colors.primary.dark,
        },
      },

      '&.Mui-disabled': {
        color: colors.basic.white,
      },
    },
    outlined: {
      color: colors.secondary.main,
      padding: '6px 14px', //root padding-2px cause of border, same in twoTone
      border: `2px solid ${colors.primary.main}`,
      borderRadius: '0',
      '&.Mui-disabled': {
        color: colors.grey120,
        border: `2px solid ${colors.grey120}`,
        borderRadius: '0',
      },
    },
    outlinedSizeLarge: {
      padding: '11px 17px',
    },
    outlinedSizeSmall: {
      padding: '2px 8px',
    },
    focusVisible: {
      outline: `-webkit-focus-ring-color auto 1px`,
    },
  }),
)(Button);

export const useStyles = (size: 'small' | 'medium' | 'large', loading?: boolean) => {
  const shift = size === 'small' ? 4 : size === 'medium' ? 5 : 7;
  const borderWidth = 2;

  const style = makeStyles({
    root: {
      opacity: loading ? 0.5 : 'initial',
    },
    twoTone: {
      zIndex: 1,
      position: 'relative',
      padding: '0!important',
      borderRadius: 0,
      color: colors.secondary.main,

      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: `-${shift}px`,
        right: `-${shift}px`,
        width: '100%',
        height: '100%',
        background: colors.primary.light,
        zIndex: -1,
        transition: 'all .025s linear',
      },

      '&:active': {
        '&::before': {
          bottom: 0,
          right: 0,
          transition: 'all .01s linear',
        },
      },

      '&:hover': {
        background: colors.primary.light,
      },

      /*
       * Media target only: smartphones, touchscreens.
       * Disable hover on touch device, because it
       * sometimes cause that first button click not fire the click ever
       */
      '@media (hover: none) and (pointer: coarse)': {
        '&:hover': {
          background: 'inherit',
        },
      },

      '&.Mui-disabled': {
        '& $twoToneContent': {
          color: colors.grey120,
          border: `${borderWidth}px solid ${colors.grey120}`,
        },
        '&::before': {
          background: colors.grey20,
        },
        '&:hover': {
          background: colors.grey20,
        },
      },

      '&.selected': {
        '& $twoToneContent': {
          background: colors.primary.main + ' !important',
          color: colors.basic.white + ' !important',
        },
      },

      '&.MuiButton-fullWidth': {
        width: `calc(100% - ${shift + borderWidth}px)`,
      },
    },
    twoToneContent: {
      display: 'flex',
      padding: '6px 14px',
      border: `${borderWidth}px solid ${colors.primary.main}`,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    twoToneContentLarge: {
      padding: '11px 17px',
    },
    twoToneContentSmall: {
      padding: '2px 8px',
    },
    buttonContainer: {
      position: 'relative',
    },
    loader: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: 1,
      pointerEvents: 'all',
    },
    outlinedError: {
      color: colors.functionals.alert,
      border: `2px solid ${colors.functionals.alert}`,
    },
  });

  return style();
};
