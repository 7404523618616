import { createElement, memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { CloseIcon, colors } from 'assets';
import { getFileIcon } from 'utils';

import { useStyles } from './AttachmentItem.styles';
import { AttachmentItemProps } from './AttachmentItem.types';

export const AttachmentItem = memo(({ name, description, permissions, onPreview, onDelete }: AttachmentItemProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={clsx(styles.content, !!onPreview && 'cursorPointer')} onClick={onPreview}>
        <div className={styles.fileIconWrapper}>
          {createElement(getFileIcon({ fileName: name }), { className: styles.fileIcon })}
        </div>

        <div className="overflowHidden">
          <Typography variant="body1" className={styles.name}>
            {name}
          </Typography>

          {description && (
            <Typography variant="body1" className={styles.description}>
              {description}
            </Typography>
          )}
        </div>
      </div>

      {!permissions?.hideDelete && (
        <IconButton className={styles.removeIconButton} onClick={onDelete}>
          <CloseIcon color={colors.grey80} fontSize={12} />
        </IconButton>
      )}
    </div>
  );
});
