import List from '@material-ui/core/List';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

export const ListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
  }),
)(List);

export const useStyles = makeStyles((theme: Theme) => ({
  parentList: {
    margin: '-8px',
  },
}));
