import { FC, memo, MouseEventHandler, useCallback, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from '../GeneralTopbar.styles';
import { NavigationPinIcon } from 'assets';
import { NewDropdownMenu } from 'components/dropdownMenu/newDropdownMenu/NewDropdownMenu';

import { LocationSelectProps } from './LocationSelect.types';

export const LocationSelect: FC<LocationSelectProps> = memo(({ location, locationOptions }) => {
  const styles = useStyles({});
  const [menuPosition, setMenuPosition] = useState<HTMLDivElement | null>(null);

  const onDropdownMenuOpen = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event: { currentTarget: HTMLDivElement }) => locationOptions.length && setMenuPosition(event.currentTarget),
    [],
  );

  const onDropdownMenuClose = useCallback(() => setMenuPosition(null), []);

  return (
    <>
      <div onClick={onDropdownMenuOpen}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <NavigationPinIcon className={styles.navigationPinIcon} />
          </Grid>
          <Grid item>
            <Typography variant="body1" className={styles.locationName}>
              {location.name}
            </Typography>
          </Grid>
        </Grid>
      </div>

      {locationOptions.length ? (
        <NewDropdownMenu
          open={!!menuPosition}
          anchorEl={menuPosition}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          menuItems={locationOptions}
          onClose={onDropdownMenuClose}
        />
      ) : (
        ''
      )}
    </>
  );
});
