import { useRef, useMemo, useCallback, memo, ChangeEvent } from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { TextArea } from '../textArea/TextArea';
import { colors } from 'assets';
import { useAlert } from 'hooks';
import { getFlooredFixed } from 'utils';

import { AttachmentItem } from './attachmentItem/AttachmentItem';
import { BrowseFileButton } from './browseFileButton/BrowseFileButton';
import { useStyles } from './uploadAttachment.styles';
import { Attachment, UploadAttachmentProps } from './uploadAttachment.types';

export const UploadAttachment = memo(
  ({
    selectedFile,
    description,
    attachments,
    acceptFormats,
    required,
    hasError,
    hideLabel,
    fileSizeValidation,
    loading,
    changeSelectedFile,
    changeDescription,
    onPreviewAttachment,
    onDeleteAttachment,
  }: UploadAttachmentProps) => {
    const styles = useStyles();

    const { showAlert } = useAlert();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const colorData = useMemo(() => (hasError ? colors.functionals.alert : colors.basic.black), [hasError]);

    const isMulti = useMemo(() => !!attachments, [attachments]);

    const showDescription = useMemo(() => typeof description === 'string', [description]);

    const noAttachments = useMemo(() => {
      if (isMulti) {
        return attachments?.length === 0;
      }

      return !selectedFile;
    }, [attachments?.length, isMulti, selectedFile]);

    const onChangeSelectedFile = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];

        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }

        if (!!fileSizeValidation && file.size / 1024 >= fileSizeValidation.maxFileSizeKb) {
          if (fileSizeValidation.showAlert) {
            const maxFileSizeMb = fileSizeValidation.maxFileSizeKb / 1024;

            showAlert(
              fileSizeValidation.alert ??
                `Error! File is too large. There is a maximum limit of ${
                  Number.isInteger(maxFileSizeMb) ? maxFileSizeMb : getFlooredFixed(maxFileSizeMb, 1)
                }MB.`,
              {
                variant: fileSizeValidation.alertType ?? 'error',
              },
            );
          }

          return;
        }

        changeSelectedFile(file);
      }
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
      if (changeDescription) {
        changeDescription(e.target.value);
      }
    };

    const onDeleteSelectedFile = () => {
      changeSelectedFile(null);

      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    };

    const handleDeleteAttachment = useCallback(
      (id: number) => {
        if (onDeleteAttachment) {
          onDeleteAttachment(id);
        }
      },
      [onDeleteAttachment],
    );

    const handlePreviewAttachment = useCallback(
      (url: string, attachment?: Attachment) => {
        if (onPreviewAttachment) {
          onPreviewAttachment(url, attachment);
        }
      },
      [onPreviewAttachment],
    );

    return (
      <div className={styles.root}>
        {showDescription && (
          <TextArea
            variant="outlined"
            label="Description"
            size="medium"
            fullWidth
            value={description}
            onChange={onChangeDescription}
          />
        )}

        {isMulti && (
          <>
            <BrowseFileButton
              inputFileRef={inputFileRef}
              acceptFormats={acceptFormats}
              onChange={onChangeSelectedFile}
            />

            <Divider />
          </>
        )}

        <div className={styles.attachmentsSection}>
          {!hideLabel && (
            <Typography variant="body1" style={{ color: colorData, fontWeight: 'bold' }}>
              Attachments
            </Typography>
          )}

          <div>
            {isMulti && (
              <div className={styles.attachmentsList}>
                {attachments?.map(attachment => (
                  <AttachmentItem
                    key={attachment.id}
                    name={attachment.name}
                    description={attachment.description}
                    permissions={{ hideDelete: !(attachment.canBeDeleted ?? true) }}
                    onPreview={() => handlePreviewAttachment(attachment.url, attachment)}
                    onDelete={() => handleDeleteAttachment(attachment.id)}
                  />
                ))}
              </div>
            )}

            {!isMulti && selectedFile && <AttachmentItem name={selectedFile.name} onDelete={onDeleteSelectedFile} />}

            {loading && (
              <Typography variant="body1" style={{ color: colorData }}>
                Loading...
              </Typography>
            )}

            {!loading && noAttachments && (
              <Typography variant="body1" style={{ color: colorData }}>
                No attachments added yet.
              </Typography>
            )}
          </div>

          {required && (
            <Typography variant="body2" style={{ color: colorData }}>
              *required
            </Typography>
          )}
        </div>

        {!isMulti && !selectedFile && (
          <BrowseFileButton inputFileRef={inputFileRef} acceptFormats={acceptFormats} onChange={onChangeSelectedFile} />
        )}
      </div>
    );
  },
);
