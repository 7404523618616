import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    margin: '6px 0',
    padding: '4px 8px',
    color: colors.black,
    borderRadius: 4,
    transition: 'background-color 250ms ease-out, color 250ms ease-out',

    '&:hover, &:active, &.Mui-selected': {
      backgroundColor: colors.primary.superlight,
      color: colors.primary.main,

      '& $showMoreIcon': {
        color: colors.primary.main,
      },
    },
  },
  listItemMobile: {
    width: 'calc(100% - 32px)',
    marginRight: 16,
    marginLeft: 16,
  },
  hover: {
    color: colors.primary.main,
    backgroundColor: colors.primary.superlight,
    borderRadius: 4,
  },
  menuIcon: {
    minWidth: 'auto',
    marginRight: 8,
    color: 'inherit',

    [theme.breakpoints.down(1400)]: {
      display: 'none',
    },

    [theme.breakpoints.down(1100)]: {
      display: 'inline-flex',
    },

    '& svg': {
      fontSize: '16px !important',

      [theme.breakpoints.down('lg')]: {
        fontSize: '14px !important',
      },
    },
  },
  expandMoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -4,
  },
  focusVisibleClassName: {
    backgroundColor: 'transparent',
  },
  divider: {
    margin: '4px 16px',
  },
  showMoreIcon: {
    display: 'block',
    fontSize: 22,
    color: colors.grey80,
    transition: 'transform 150ms ease-out',
  },
  showLessIcon: {
    transform: 'rotateZ(-180deg)',
  },
}));
