import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

import { ClockTimepickerProps } from './ClockTimepicker.types';

type Props = {
  disabled?: boolean;
  size?: ClockTimepickerProps['size'];
};

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  customButton: {
    cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    padding: ({ size }) => `${size === 'small' ? '8.7px' : '14.4px'} 13px`,
    backgroundColor: ({ disabled }) => (disabled ? colors.grey20 : colors.white),

    '&:hover': {
      backgroundColor: ({ disabled }: Props) => (disabled ? colors.grey20 : colors.grey10),
    },
  },

  valueText: {
    marginLeft: 8,
    paddingRight: 18,
    flex: 1,
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },

  icon: { display: 'flex', alignItems: 'center' },

  error: {
    color: colors.functionals.alert,
    maxWidth: 260,
    margin: '0 auto',
  },
}));
