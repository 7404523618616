import SvgIcon from '@material-ui/core/SvgIcon';

import { SvgIconProps } from './SvgIcon.types';

export const FilePdfIcon = ({ className, fontSize }: Omit<SvgIconProps, 'color'>) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="40" height="48" viewBox="0 0 40 48" fill="none">
      <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M40 16V46.4C40 47.3 39.3 48 38.4 48H1.6C0.7 48 0 47.3 0 46.4V1.6C0 0.7 0.7 0 1.6 0H24L40 16Z"
            fill="#E9554E"
          />
          <path
            d="M14.4001 33.5C15.2001 32.2 18.5001 27.1 19.9001 19.7C20.0001 19.4 19.9001 19.2 19.9001 18.9C19.8001 18.5 19.4001 17.9 18.5001 17.9C17.7001 17.9 17.4001 18.3 17.2001 18.7C17.0001 19 17.0001 19.4 17.1001 19.8C17.5001 21.5 19.7001 28.8 25.8001 29.9C26.3001 30 26.8001 29.9 27.2001 29.7C27.5001 29.5 27.8001 29.1 27.8001 28.6C27.8001 27.9 27.3001 27.6 26.8001 27.4C26.5001 27.3 26.1001 27.3 25.7001 27.4C24.2001 27.6 18.3001 28.7 12.5001 31.8C12.1001 32 11.8001 32.3 11.7001 32.6C11.5001 33 11.5001 33.5 12.0001 34C12.6001 34.6 13.2001 34.4 13.6001 34.2C13.9001 34.1 14.2001 33.8 14.4001 33.5Z"
            stroke="white"
            strokeMiterlimit="10"
          />
          <g opacity="0.56" filter="url(#filter0_d)">
            <path d="M40 16H25.6C24.7 16 24 15.3 24 14.4V0L40 16Z" fill="#FCEBEA" />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="20"
            y="0"
            width="24"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.291667 0 0 0 0 0 0 0 0 0 1.04308e-07 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect width="40" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
