import Typography from '@material-ui/core/Typography';

import { useStyles } from './NasaBar.styles';

export const NasaBar = () => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Typography variant="h4" color="inherit">
        NASA Partner
      </Typography>
    </div>
  );
};
