import { fade, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

type Props = {
  fullWidth?: boolean;
  fitContent?: boolean;
  customPallete?: string;
  ignoreMobile?: boolean;
};

export const useStyles = makeStyles<Theme, Props>(theme => ({
  select: {
    width: ({ fullWidth, fitContent }) => (fullWidth ? '100%' : fitContent ? 'fit-content' : 100),
    minWidth: ({ fitContent }) => (fitContent ? 'initial' : 100),
    maxWidth: ({ fitContent }) => (fitContent ? '100%' : 'initial'),

    color: colors.grey140,
    backgroundColor: colors.grey10,
    fontWeight: 'bold',
    borderRadius: '60px!important',

    '& .MuiSelect-root': {
      color: colors.grey140,
      borderRadius: 60,

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '18px',
        lineHeight: '25.2px',
      },

      '& .MuiSelect-icon': {
        color: colors.grey140,
      },
    },

    '& .MuiSelect-icon': {
      color: colors.grey140,
    },

    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },

    '& $success': {
      backgroundColor: colors.functionals.successLight,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.functionals.success,
        backgroundColor: colors.functionals.successLight,
      },

      '& .MuiSelect-icon': {
        color: colors.functionals.success,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.functionals.successLight,
      },
    },

    '& $empty': {
      backgroundColor: colors.grey10,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.grey60,
        backgroundColor: colors.grey10,
      },

      '& .MuiSelect-icon': {
        color: colors.grey60,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.grey10,
      },
    },

    '& $basic': {
      backgroundColor: colors.grey10,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.grey140,
        backgroundColor: colors.grey10,
      },

      '& .MuiSelect-icon': {
        color: colors.grey140,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.grey10,
      },
    },

    '& $info': {
      backgroundColor: colors.functionals.infoLight,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.functionals.info,
        backgroundColor: colors.functionals.infoLight,
      },

      '& .MuiSelect-icon': {
        color: colors.functionals.info,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.functionals.infoLight,
      },
    },

    '& $error': {
      backgroundColor: colors.functionals.alertLight,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.functionals.alert,
        backgroundColor: colors.functionals.alertLight,
      },

      '& .MuiSelect-icon': {
        color: colors.functionals.alert,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.functionals.alertLight,
      },
    },

    '& $warning': {
      backgroundColor: colors.functionals.warningLight,

      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        color: colors.functionals.warning,
        backgroundColor: colors.functionals.warningLight,
      },

      '& .MuiSelect-icon': {
        color: colors.functionals.warning,
      },

      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: colors.functionals.warningLight,
      },
    },

    '& $small': {
      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        paddingRight: 20,
      },
    },

    '& $large': {
      '& .MuiSelect-root, & .MuiSelect-root:focus': {
        paddingRight: 36,
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  customPallete: {
    backgroundColor: ({ customPallete }) => customPallete && `${fade(customPallete, 0.05)} !important`,

    '& .MuiSelect-root, & .MuiSelect-root:focus': {
      color: ({ customPallete }) => `${customPallete} !important`,
    },

    '& .MuiSelect-icon': {
      color: ({ customPallete }) => `${customPallete} !important`,
    },

    '& .MuiOutlinedInput-root:hover': {
      backgroundColor: ({ customPallete }) => customPallete && `${fade(customPallete, 0.05)} !important`,
    },
  },

  success: {},
  empty: {},
  basic: {},
  info: {},
  error: {},
  warning: {},

  small: {},
  medium: {},
  large: {},
}));
