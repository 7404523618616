import { FC, memo, useCallback, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { Button } from '../button/Button';
import { Tooltip } from '../tooltip/Tooltip';

import { useStyles } from './confirmationTooltip.styles';
import { ConfirmationTooltipProps } from './confirmationTooltip.types';

export const ConfirmationTooltip: FC<ConfirmationTooltipProps> = memo(
  ({
    disabled,
    confirmationDisabled,
    onConfirm,
    onClose,
    toggle,
    confirmButtonText,
    cancelButtonText,
    isLoading,
    title,
    children,
    fullWidthButton,
    placement,
    zIndex,
  }) => {
    const styles = useStyles({ fullWidthButton });
    const [isVisible, setVisibility] = useState(false);

    const handleToggle = useCallback(() => {
      setVisibility(!isVisible);
    }, [isVisible]);

    const handleClose = useCallback(() => {
      onClose && onClose();
      setVisibility(false);
    }, [onClose]);

    const handleConfirm = useCallback(async () => {
      try {
        await onConfirm();
        setVisibility(false);
      } catch (error) {}
    }, [onConfirm]);

    return (
      <Tooltip
        isClick
        isArrow
        interactive
        placement={placement}
        zIndex={zIndex}
        open={isVisible && !disabled}
        className={styles.root}
        title={
          <div>
            {title && (
              <Typography variant="h5" className={styles.label}>
                {title}
              </Typography>
            )}

            <div className={styles.tooltipTitle}>{children}</div>

            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Button type="button" size="medium" color="primary" onClick={handleClose}>
                  {cancelButtonText || 'Cancel'}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  buttonType="twoTone"
                  size="medium"
                  style={{ width: 136 }}
                  onClick={handleConfirm}
                  isLoading={isLoading}
                  disabled={confirmationDisabled}
                >
                  {confirmButtonText || 'Confirm'}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        onClose={handleClose}
      >
        <div className={clsx('confirmation-toggle-container', styles.toggleContainer)} onClick={handleToggle}>
          {toggle}
        </div>
      </Tooltip>
    );
  },
);
