import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../../assets';

export const useStyles = makeStyles(theme => ({
  pickerDialog: {
    '& .MuiPickersToolbar-toolbar': {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& .MuiPickersTimePickerToolbar-hourMinuteLabel .MuiPickersToolbarText-toolbarTxt': {
      fontSize: theme.typography.h1.fontSize,
      lineHeight: theme.typography.h1.lineHeight,
    },
    '& .MuiPickersTimePickerToolbar-ampmLabel': {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontFamily: theme.typography.h4.fontFamily,
    },
    '& .MuiPickersClockNumber-clockNumber': {
      ...theme.typography.h5,
      color: colors.grey180,

      '&.MuiPickersClockNumber-clockNumberSelected': {
        color: colors.basic.white,
      },
    },
    '& .MuiPickersClockPointer-pointer, & .MuiPickersClock-pin, & .MuiPickersClockNumber-clockNumberSelected': {
      backgroundColor: colors.secondary.main,
    },
    '& .MuiPickersClockPointer-thumb': {
      backgroundColor: colors.secondary.main,
      borderColor: colors.secondary.main,
    },
  },
  disabledMinutesNonDivisibleBy15: {
    '& .MuiPickersClockNumber-clockNumber:not(:nth-child(3n+1))': {
      color: colors.grey60,
    },
  },
  customInput: {
    '&, & *': {
      cursor: 'pointer',
    },
  },
  arrowDownIcon: {
    marginRight: 10,
    marginBottom: 16,
    color: colors.secondary.main,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    marginBottom: 16,

    '& > *:first-of-type': {
      marginLeft: 'auto',
      marginRight: 16,
    },

    '& button.MuiButtonBase-root': {
      width: 76,
    },
  },
}));
