import { useState, useEffect, useMemo, memo, useCallback, MouseEvent as MouseEventReact } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';

import { DashedSeparator } from '../../dashedSeparator/DashedSeparator';
import { NestedMenuItem } from '../nestedMenuItem/NestedMenuItem';
import { colors } from 'assets';
import { Loader } from 'components/loader/Loader';

import { useStyles } from './NewDropdownMenu.styles';
import { INewDropdownMenuProps, MenuItem as MenuItemType } from './NewDropdownMenu.types';

export const NewDropdownMenu = memo(
  ({
    isNestedMenu = false,
    className,
    open,
    menuItems,
    onMenuItemClick,
    handleNestedMenuItemClick,
    onClose,
    ...props
  }: INewDropdownMenuProps) => {
    const classes = useStyles();

    const [parentMenuShow, setParentMenuShow] = useState(true);

    const headerMenuItems = useMemo(() => menuItems?.filter(menuItem => menuItem.type === 'header'), [menuItems]);
    const bodyMenuItems = useMemo(() => menuItems?.filter(menuItem => menuItem.type !== 'header'), [menuItems]);
    const thereNestedItems = useMemo(() => menuItems?.find(menuItem => menuItem.items), [menuItems]);

    const handleMenuItemClick = useCallback(
      (e: MouseEventReact<HTMLElement, MouseEvent>, menuItem: MenuItemType) => {
        if (menuItem.onMenuItemClick) {
          menuItem.onMenuItemClick();
        }

        if (!menuItem.notCloseOnClick && onClose) {
          onClose(e, 'escapeKeyDown');
        }
      },
      [onClose],
    );

    useEffect(() => {
      if (open) {
        setParentMenuShow(true);
      }
    }, [open]);

    return (
      <Popover
        open={open}
        anchorEl={props.anchorEl}
        classes={{
          root: clsx(classes.popoverRoot, open ? classes.animateOpenPopover : classes.animateClosePopover),
          paper: classes.popoverPaper,
        }}
      >
        <Menu
          open={open}
          keepMounted={open}
          className={clsx([className])}
          classes={{ paper: classes.menuWrapper, list: classes.list }}
          style={{ display: parentMenuShow ? 'block' : 'none' }}
          onClose={onClose}
          {...props}
        >
          {headerMenuItems?.map((menuItem, index) => (
            <div key={menuItem.id} className="listItem">
              <MenuItem
                id={menuItem.htmlId ?? `menu-header-option-${index + 1}`}
                disabled={menuItem.disabled || menuItem.isLoading}
                className={clsx(classes.menuItem, {
                  [classes.divider]: menuItem?.isDivider,
                })}
                onClick={e => handleMenuItemClick(e, menuItem)}
              >
                {menuItem.isLoading && (
                  <div>
                    <Loader size="small" />
                  </div>
                )}

                {menuItem.name}
              </MenuItem>
            </div>
          ))}

          {headerMenuItems && !!headerMenuItems.length && (
            <DashedSeparator dashedItemProps={{ width: 7, color: colors.grey80 }} />
          )}

          {bodyMenuItems?.map((menuItem, index) =>
            menuItem.items ? (
              <div key={menuItem.id} className="listItem">
                <NestedMenuItem
                  menuItem={{
                    htmlId: menuItem.htmlId ?? `menu-option-${index + 1}`,
                    name: menuItem.name,
                    items: menuItem.items.map((item, nestedIndex) => ({
                      name: item,
                      htmlId: menuItem.htmlId ? `${menuItem.htmlId}-nested-option-${nestedIndex}` : undefined,
                    })),
                  }}
                  handleNestedMenuItemClick={handleNestedMenuItemClick}
                  setParentMenuShow={setParentMenuShow}
                />
              </div>
            ) : (
              <div key={menuItem.id} className="listItem">
                <MenuItem
                  id={menuItem.htmlId ?? `menu-option-${index + 1}`}
                  disabled={menuItem.disabled || menuItem.isLoading}
                  className={clsx(classes.menuItem, {
                    [classes.divider]: menuItem?.isDivider,
                    [classes.menuItemIfThereNested]: thereNestedItems,
                  })}
                  onClick={e => handleMenuItemClick(e, menuItem)}
                >
                  {menuItem.isLoading && (
                    <div>
                      <Loader size="small" />
                    </div>
                  )}

                  {menuItem.name}
                </MenuItem>
              </div>
            ),
          )}
        </Menu>
      </Popover>
    );
  },
);
