import { CSSProperties } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme';

export const FlagIcon = ({
  className,
  color = colors.functionals.warning,
  fontSize = 18,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
  styles?: CSSProperties;
}) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize, display: 'block', fill: 'none' }}
      width={17}
      height={19}
      viewBox="0 0 17 19"
      fill="none"
      fontSize="inherit"
    >
      <path
        d="M15.6454 9.17941C15.6475 9.42456 15.5084 9.65591 15.271 9.80219C15.0336 9.94848 14.7263 9.99216 14.4438 9.9198C12.3762 9.3512 10.1181 9.50493 8.18937 10.3456C5.85167 11.3502 3.07215 11.3537 0.731939 10.3549L0.722259 2.61335C3.06248 3.61206 5.84197 3.60859 8.17969 2.60402C10.322 1.67798 12.8508 1.59334 15.0752 2.37324C15.4182 2.4937 15.6401 2.77336 15.6377 3.08221L15.6454 9.17941Z"
        fill="#FEF4EB"
      />

      <path d="M0.709106 1.06766L0.730364 18.0676" stroke={color} strokeLinecap="round" strokeLinejoin="round" />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6356 10.3361C13.2953 9.33743 10.5158 9.3409 8.17813 10.3455C5.84043 11.3501 3.06091 11.3536 0.720703 10.3548L0.711022 2.61323C3.05124 3.61195 5.83073 3.60847 8.16845 2.60391C10.5062 1.59934 13.2857 1.59586 15.6259 2.59458L15.6356 10.3361Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path d="M0.709106 1.06766L0.730364 18.0676" stroke={color} strokeLinecap="round" strokeLinejoin="round" />

      <path
        d="M8.16845 2.60391C10.5062 1.59934 13.2857 1.59586 15.6259 2.59458L15.6356 10.3361C13.2953 9.33743 10.5158 9.3409 8.17813 10.3455C5.84043 11.3501 3.06091 11.3536 0.720703 10.3548L0.711022 2.61323"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
