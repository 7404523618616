import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useFormCheckboxGroupStyles = makeStyles(() => ({
  formGroup: {
    margin: '0px !important',
  },
  formControlLabel: {
    margin: '0px !important',
  },
}));

export const useFormCheckboxStyles = makeStyles(() => ({
  formControl: {
    verticalAlign: 'middle',
  },
}));

type FormTypographyInputStyles = { editMode?: boolean };

export const useFormTypographyInputStyles = makeStyles<Theme, FormTypographyInputStyles>(() => ({
  wrapper: {
    display: ({ editMode }) => (editMode ? 'flex' : 'none'),
    alignItems: 'center',
    minHeight: 42,
  },

  iconButton: {
    display: 'inline-block',
    marginLeft: 8,
  },
}));
