import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const ArrowBackLong = ({
  className,
  color = colors.grey100,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize }}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      fontSize="inherit"
    >
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none">
        <path
          d="M18 6C18 5.44772 17.5523 5 17 5H3.83L6.70647 2.11549C7.09518 1.7257 7.09474 1.09474 6.70549 0.705492C6.31586 0.315859 5.68414 0.315859 5.29451 0.705492L0.707107 5.29289C0.316583 5.68342 0.316583 6.31658 0.707107 6.70711L5.29451 11.2945C5.68414 11.6841 6.31586 11.6841 6.70549 11.2945C7.09474 10.9053 7.09518 10.2743 6.70647 9.88451L3.83 7H17C17.5523 7 18 6.55228 18 6Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
