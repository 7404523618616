import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  userCheckbox: {
    display: 'flex',
    alignItems: 'center',
    height: 41,
    borderRadius: 4,
    marginTop: 16,
    marginRight: 8,
    padding: '8px 16px',
    border: `1px solid ${colors.grey60}`,

    '&.MuiCheckbox-root:hover': {
      border: `1px solid ${colors.primary.main}`,
    },

    '&.Mui-checked': {
      border: `1px solid ${colors.primary.main}`,
      backgroundColor: colors.primary.main,

      '&:hover': {
        backgroundColor: colors.primary.dark,

        '@media (hover: none)': {
          backgroundColor: colors.primary.main,
        },
      },
    },
  },
  userIdentifierUsernameChecked: {
    color: colors.basic.white,
  },
}));
