import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from '../../assets';

export const useStyles = makeStyles(() => ({
  contentRoot: {
    width: '100%',
    marginTop: 8,
  },
}));

export const AccordionStyled = withStyles(() =>
  createStyles({
    root: {
      boxShadow: 'none',
      minHeight: 25,
      borderRadius: 4,

      '&$disabled': {
        backgroundColor: colors.white,
      },
    },
    disabled: {
      backgroundColor: colors.white,
    },
  }),
)(Accordion);

export const AccordionSummaryStyled = withStyles(() =>
  createStyles({
    root: {
      minHeight: 25,

      '&$expanded': {
        minHeight: 25,
      },
    },
    content: {
      margin: 0,

      '&$expanded': {
        margin: 0,
      },
    },
    expanded: {},
  }),
)(AccordionSummary);

export const AccordionDetailsStyled = withStyles(() => ({
  root: {
    position: 'relative',
    backgroundColor: colors.white,
    padding: 16,
    borderRadius: 4,

    '&.solid': {
      '&:before': {
        backgroundColor: colors.grey40,
      },
    },

    '&.dashed': {
      '&:before': {
        backgroundImage: `linear-gradient(to right, ${colors.grey40} 50% , rgba(255, 255, 255, 0) 50%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '8px 1px',
        backgroundRepeat: 'repeat-x',
      },
    },

    '&:before': {
      content: "''",
      display: 'block',
      width: 'calc(100% - 32px)',
      height: 1,
      position: 'absolute',
      top: 0,
      left: 16,
    },
  },
}))(AccordionDetails);
