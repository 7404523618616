import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../assets';

export const useStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root:first-child': {
      color: colors.primary.main,
    },

    '& .MuiSvgIcon-root:nth-of-type(2)': {
      color: colors.secondary.main,
    },
  },
});
