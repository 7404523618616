import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  content: {
    padding: 16,
    backgroundColor: colors.grey10,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    gap: 24,
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  submitButton: {
    width: 148,
  },
}));
