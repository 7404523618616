import { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';

import { PauseIcon, PlayIcon } from '../../assets';

import { CustomSlider } from './AudioPlayer.styles';
import { useStyles } from './AudioPlayer.styles';
import { AudioPlayerProps } from './AudioPlayer.types';
import { Duration } from './Duration';
import { usePlayer } from './usePlayerControls';

export const AudioPlayer = memo(({ url }: AudioPlayerProps) => {
  const styles = useStyles();

  const player = usePlayer();

  return (
    <div>
      <div className={styles.playButtonContainer}>
        <IconButton className={styles.playButton} onClick={player.controls.handlePlayPause}>
          {player.state.playing ? <PauseIcon fontSize={40} /> : <PlayIcon fontSize={40} />}
        </IconButton>
      </div>

      <div className={styles.durationContainer}>
        <CustomSlider
          min={0}
          max={0.999999}
          step={0.999999 / player.state.duration}
          value={player.state.played}
          onChange={(_, newVal) => {
            player.state.setPlayed(newVal as number);
          }}
          onChangeCommitted={(_, newVal) => {
            player.state.player?.seekTo(newVal as number);
          }}
        />

        <div className={styles.timeContainer}>
          <Typography variant="caption">
            <Duration seconds={player.state.duration * player.state.played} />
          </Typography>

          <Typography variant="caption">
            <Duration seconds={player.state.duration} />
          </Typography>
        </div>
      </div>

      <ReactPlayer
        ref={player.controls.ref}
        width={0}
        height={0}
        url={url}
        playing={player.state.playing}
        onPlay={player.controls.handlePlay}
        onPause={player.controls.handlePause}
        onProgress={player.controls.handleProgress}
        onDuration={player.controls.handleDuration}
      />
    </div>
  );
});
