import { FC, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { Button } from '../button/Button';
import { Paper } from '../paper/Paper';

import { LinearProgressStyled, useStyles } from './ProgressBar.styles';
import { ProgressBarProps } from './ProgressBar.types';

export const ProgressBar: FC<ProgressBarProps> = memo(
  ({ title, currentCount, totalCount, nextStep, mainAction, secondaryAction, isMobile, classes, ...props }) => {
    const styles = useStyles({ isMobile });

    return (
      <Paper variant="outlined" className={classes?.paper} {...props}>
        <div className={clsx(styles.wrapper, classes?.wrapper)}>
          <div className={styles.content}>
            <Typography variant="h6" component="span" className={styles.stepCount}>
              Job progress: {currentCount} / {totalCount}
            </Typography>

            <div className={styles.titleRow}>
              <Typography variant="h4" className={styles.title}>
                {title}
              </Typography>

              {nextStep && (
                <Typography variant="h6" component="span" className={styles.nextStep}>
                  next: {nextStep}
                </Typography>
              )}
            </div>

            <LinearProgressStyled variant="determinate" color="secondary" value={(currentCount * 100) / totalCount} />
          </div>

          <div className={styles.actions}>
            {secondaryAction && (
              <div className={styles.actionItem}>
                <Button buttonType="outlined" size="small" fullWidth onClick={secondaryAction.onClick}>
                  {secondaryAction.name}
                </Button>
              </div>
            )}

            {mainAction && (
              <div className={styles.actionItem}>
                <Button buttonType="twoTone" size="small" fullWidth onClick={mainAction.onClick}>
                  {mainAction.name}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Paper>
    );
  },
);
