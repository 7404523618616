import { useState, useMemo, memo } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { colors } from 'assets';
import { integerRange } from 'utils';

import { useStyles } from './Header.styles';
import { CalendarRangePickerHeaderProps } from './Header.types';

export const CalendarRangePickerHeader = memo(
  ({
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    yearsRange,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
  }: CalendarRangePickerHeaderProps) => {
    const styles = useStyles();

    const [yearAnchorEl, setYearAnchorEl] = useState<null | HTMLElement>(null);
    const [monthAnchorEl, setMonthAnchorEl] = useState<null | HTMLElement>(null);

    const integerRangeValue = useMemo(
      () =>
        (yearsRange
          ? integerRange(yearsRange[0], yearsRange[1])
          : integerRange(2000, moment(new Date()).get('year'))
        ).sort((a, b) => b - a),
      [yearsRange],
    );

    return (
      <Grid container alignItems="center" direction="row" wrap="nowrap" justify="space-between">
        <Grid item>
          <IconButton disabled={prevMonthButtonDisabled} className={styles.monthButton} onClick={decreaseMonth}>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                strokeWidth="2"
                stroke={colors.primary.main}
              />
            </SvgIcon>
          </IconButton>
        </Grid>

        <Grid item>
          <Grid container item alignItems="center" direction="row" wrap="nowrap">
            <Typography
              variant="h6"
              color="primary"
              className={styles.headerSelect}
              onClick={event => setMonthAnchorEl(event.currentTarget)}
            >
              {moment(date).format('MMMM')}
            </Typography>

            <Menu anchorEl={monthAnchorEl} open={Boolean(monthAnchorEl)} onClose={() => setMonthAnchorEl(null)}>
              {integerRange(1, 12).map(monthNumber => (
                <MenuItem
                  key={monthNumber}
                  selected={monthNumber === moment(date).get('month') + 1}
                  onClick={() => {
                    changeMonth(monthNumber - 1);
                    setMonthAnchorEl(null);
                  }}
                >
                  {moment(monthNumber, 'M').format('MMMM')}
                </MenuItem>
              ))}
            </Menu>

            <Typography
              variant="h6"
              color="primary"
              className={styles.headerSelect}
              onClick={event => setYearAnchorEl(event.currentTarget)}
            >
              {moment(date).get('year')}
            </Typography>

            <Menu anchorEl={yearAnchorEl} open={Boolean(yearAnchorEl)} onClose={() => setYearAnchorEl(null)}>
              {integerRangeValue.map(year => (
                <MenuItem
                  key={year}
                  value={year}
                  selected={year === moment(date).get('year')}
                  onClick={() => {
                    changeYear(year);
                    setYearAnchorEl(null);
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton disabled={nextMonthButtonDisabled} className={styles.monthButton} onClick={increaseMonth}>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                strokeWidth="2"
                stroke={colors.primary.main}
              />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Grid>
    );
  },
);
