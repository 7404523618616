import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const PlusIcon = ({
  className,
  size = 'medium',
  color = colors.basic.black,
  fontSize = 16,
}: {
  className?: string;
  size?: 'medium' | 'small';
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      fontSize={size === 'small' ? 'small' : 'default'}
      style={{ fontSize }}
    >
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.50787 9.83425L15.2393 9.83425C15.9198 9.83425 16.4698 9.28426 16.4698 8.60373C16.4698 7.9232 15.9198 7.37321 15.2393 7.37321L9.50787 7.37321L9.50787 1.6418C9.50787 0.961269 8.95787 0.411276 8.27734 0.411276C7.59681 0.411276 7.04682 0.961269 7.04682 1.6418L7.04682 7.37321L1.31541 7.37321C0.63488 7.37321 0.084887 7.9232 0.084887 8.60373C0.0848867 8.94313 0.222385 9.25119 0.445167 9.47397C0.667949 9.69676 0.976014 9.83425 1.31541 9.83425L7.04682 9.83425L7.04682 15.5657C7.04682 15.9051 7.18432 16.2131 7.4071 16.4359C7.62988 16.6587 7.93795 16.7962 8.27734 16.7962C8.95787 16.7962 9.50787 16.2462 9.50787 15.5657L9.50787 9.83425Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
