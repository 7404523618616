import { memo, useCallback, useMemo, useState } from 'react';

import { NavigationProps } from '../Navigation.types';
import { Button } from 'components/button/Button';
import { NewDropdownMenu } from 'components/dropdownMenu/newDropdownMenu/NewDropdownMenu';
import { ExpandMoreIcon } from 'components/expandMoreIcon/ExpandMoreIcon';

export const LocationSelect = memo(({ appLocation }: { appLocation: NavigationProps['appLocation'] }) => {
  const [menuPosition, setMenuPosition] = useState<HTMLButtonElement | null>(null);
  const handleDropdownMenuOpen = useCallback(
    (event: { currentTarget: HTMLButtonElement }) => setMenuPosition(event.currentTarget),
    [],
  );
  const handleDropdownMenuClose = useCallback(() => setMenuPosition(null), []);

  const selectedLocation = useMemo(
    () => appLocation.options.find(item => item.id === appLocation.selected),
    [appLocation.options, appLocation.selected],
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          id="navigation-location"
          endIcon={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: -8,
              }}
            >
              <ExpandMoreIcon isOpen={Boolean(menuPosition)} />
            </div>
          }
          onClick={handleDropdownMenuOpen}
        >
          {selectedLocation?.name.replace('>    ', '')}
        </Button>
      </div>

      <NewDropdownMenu
        menuItems={appLocation.options}
        open={!!menuPosition}
        anchorEl={menuPosition}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleDropdownMenuClose}
        variant="selectedMenu"
      />
    </>
  );
});
