import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  text: {
    padding: '0px 4px',
  },
  outlined: {
    border: `1px solid ${colors.primary.main}`,
    borderRadius: 100,
    paddingTop: 5,
    paddingBottom: 3,
    paddingLeft: 16,
    paddingRight: 16,

    '& .MuiTypography-root': {
      fontSize: 18,
    },
  },
  disabled: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'inherit',
    },
  },
  titleBox: {
    marginRight: 8,
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: 4,

    '&:hover': {
      backgroundColor: colors.grey20,
    },
  },
}));
