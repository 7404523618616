import { memo } from 'react';

import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';

import { Paper } from 'components';

import { useStyles } from './styles';

export const TaskCardDesktopSkelton = memo(() => {
  const styles = useStyles();

  return (
    <Paper variant="elevation" elevation={24} className={styles.root}>
      <Typography variant="body1" color="primary" className={styles.taskIdSection}>
        <Skeleton variant="text" width={75} />
      </Typography>

      <div className={styles.summarySection}>
        <Typography variant="body1" color="inherit">
          <Skeleton variant="text" width="90%" />
        </Typography>
      </div>

      <div className={styles.dueDateSection}>
        <Skeleton variant="rect" width={84} height={21.5} style={{ borderRadius: 60 }} />
      </div>

      <div className={styles.dueTimeSection}>
        <Typography variant="body1" color="inherit">
          <Skeleton variant="text" width={62} />
        </Typography>
      </div>

      <div className={styles.boardSwimlaneSection}>
        <div className={styles.boardItem}>
          <Skeleton variant="rect" width={95} height={21.5} style={{ borderRadius: 60 }} />
        </div>

        <div className={styles.swimlaneItem}>
          <Typography variant="body1" className={styles.swimlane}>
            <Skeleton variant="text" width={45} />
          </Typography>
        </div>
      </div>

      <div className={styles.flagItem}>
        <Skeleton variant="rect" width={18} height={18} style={{ borderRadius: '100%' }} />
      </div>
    </Paper>
  );
});
