import Input from '@material-ui/core/Input';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { TypographyInputProps } from './TypographyInput.types';

export const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    root: (props: TypographyInputProps) => {
      const variant = (props.variant !== 'inherit' && props.variant !== 'srOnly' && props.variant) || 'body1';

      return {
        ...theme.typography[variant],
        outline: 'none',
        border: 'none',

        '&:before, &:after': {
          display: 'none',
        },
      };
    },
    input: { padding: 0 },
  }),
)(Input);
