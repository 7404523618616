import { memo, useCallback, useMemo, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';

import { TaskViewAppointmentsProps } from './model';
import { AppointmentCard, ViewSection } from './ui';

export const TaskViewAppointments = memo(({ appointments }: TaskViewAppointmentsProps) => {
  const [visibility, setVisibility] = useState(false);
  const toggleList = useCallback(() => setVisibility(!visibility), [visibility]);

  const [first, ...rest] = useMemo(() => appointments || [], [appointments]);

  const action = useMemo(
    () =>
      rest.length
        ? {
            title: visibility ? 'View only primary' : 'View all',
            onClick: toggleList,
          }
        : undefined,
    [rest.length, toggleList, visibility],
  );

  return (
    <ViewSection title="Appointment information" button={action}>
      <AppointmentCard appointment={first} separator={false} />

      <Collapse in={visibility}>
        {rest.map(appointment => (
          <AppointmentCard key={appointment.id} appointment={appointment} />
        ))}
      </Collapse>
    </ViewSection>
  );
});
