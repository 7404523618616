import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../assets';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  avatar: {
    marginLeft: '4px',
    border: 'none',
    width: '24px',
    height: '24px',
    '&:first-child': {
      marginLeft: 0,
    },
    '& .MuiTypography-root': {
      color: colors.basic.white,
    },
  },
  additionalAvatars: {
    '&:last-child': {
      background: 'none',
      color: colors.secondary.main,
      maxWidth: 'fit-content',
      fontFamily: 'Rift Soft',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
});
