import { memo } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

import { UserIdentifier } from '../userIdentifier/UserIdentifier';

import { useStyles } from './UserCheckbox.styles';
import { UserCheckboxProps } from './UserCheckbox.types';

export const UserCheckbox = memo(
  ({ userName, imageUrl, checkboxIconSize = 'medium', checked, ...rest }: UserCheckboxProps) => {
    const styles = useStyles();

    return (
      <Checkbox
        color="primary"
        className={styles.userCheckbox}
        checked={checked}
        icon={<UserIdentifier name={userName} imageUrl={imageUrl} size={checkboxIconSize} />}
        checkedIcon={
          <UserIdentifier
            name={userName}
            imageUrl={imageUrl}
            size={checkboxIconSize}
            classes={{ username: clsx(checked && styles.userIdentifierUsernameChecked) }}
          />
        }
        {...rest}
      />
    );
  },
);
