import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ListDataStyleProps } from './ListData.types';

type Props = Required<Pick<ListDataStyleProps, 'direction' | 'gap' | 'align' | 'justify'>>;

export const useStyles = makeStyles<Theme, Props>(() => ({
  list: {
    display: 'flex',
    flexDirection: ({ direction }) => {
      switch (direction) {
        case 'col':
          return 'column';

        case 'col-reverse':
          return 'column-reverse';

        case 'row':
        case 'row-reverse':
          return direction;

        default:
          return 'initial';
      }
    },
    gap: ({ gap }) => gap,
    alignItems: ({ align }) => {
      switch (align) {
        case 'start':
        case 'end':
          return `flex-${align}`;

        case 'center':
        case 'baseline':
        case 'stretch':
          return align;

        default:
          return 'initial';
      }
    },
    justifyContent: ({ justify }) => {
      switch (justify) {
        case 'start':
        case 'end':
          return `flex-${justify}`;

        case 'between':
        case 'around':
        case 'evenly':
          return `space-${justify}`;

        case 'normal':
        case 'center':
        case 'stretch':
          return justify;

        default:
          return 'initial';
      }
    },
  },
}));
