import { FC, memo, useCallback } from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components';

import { useStyles } from './styles';
import { SectionProps } from './types';

export const ViewSection: FC<SectionProps> = memo(({ button, children, title }) => {
  const styles = useStyles();

  const renderButton = useCallback(() => {
    if (button) {
      const { title: btnLabel, ...rest } = button;

      return (
        <Button {...rest} variant="text" size="small" color="primary" className={styles.actionButton}>
          {btnLabel}
        </Button>
      );
    }

    return <></>;
  }, [button, styles.actionButton]);

  return (
    <>
      <Divider className={styles.divider} />

      <div className={styles.root}>
        <Typography>
          <b>{title}</b>
        </Typography>

        {renderButton()}
      </div>

      <div>{children}</div>
    </>
  );
});
