import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listTypography: {
      lineHeight: '25px',
      fontSize: '18px',
    },
    title: {
      color: colors.grey120,
      fontWeight: 'bold',
    },
    price: {
      color: '#000000',
    },
  }),
);
