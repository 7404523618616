import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from '../theme';

export const CloseIcon = ({
  className,
  color = colors.grey180,
  fontSize = 18,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      style={{ fontSize }}
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      fontSize="inherit"
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5451 9L17.4722 3.0729C18.1759 2.36913 18.1759 1.23159 17.4722 0.527824C16.7684 -0.175941 15.6309 -0.175941 14.9271 0.527824L9 6.45493L3.0729 0.527824C2.36913 -0.175941 1.23159 -0.175941 0.527824 0.527824C-0.175941 1.23159 -0.175941 2.36913 0.527824 3.0729L6.45493 9L0.527824 14.9271C-0.175941 15.6309 -0.175941 16.7684 0.527824 17.4722C0.878806 17.8232 1.33958 17.9995 1.80036 17.9995C2.26114 17.9995 2.72191 17.8232 3.0729 17.4722L9 11.5451L14.9271 17.4722C15.2781 17.8232 15.7389 17.9995 16.1996 17.9995C16.6604 17.9995 17.1212 17.8232 17.4722 17.4722C18.1759 16.7684 18.1759 15.6309 17.4722 14.9271L11.5451 9Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
