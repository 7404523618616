import { memo } from 'react';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './MessageBadge.styles';
import { MessageBadgeProps } from './MessageBadge.types';

export const MessageBadge = memo(
  ({ id, type, title, message, align, variant = 'default', zeroRounded, classes }: MessageBadgeProps) => {
    const styles = useStyles();

    return (
      <div
        id={id}
        className={clsx(
          styles.root,
          classes?.root,
          zeroRounded && styles.zeroRounded,
          variant === 'outlined' && styles.rootOutlined,
          type === 'success' && styles.successType,
          type === 'error' && styles.errorType,
          type === 'info' && styles.infoType,
          type === 'warning' && styles.warningType,
        )}
      >
        {title && (
          <Typography
            variant="h4"
            color="inherit"
            className={clsx(styles.title, classes?.title)}
            align={align || 'left'}
            component="div"
          >
            {title}
          </Typography>
        )}

        <Typography
          variant="body1"
          color="inherit"
          className={clsx(styles.message, classes?.message)}
          align={align || 'left'}
          component="div"
        >
          {message}
        </Typography>
      </div>
    );
  },
);
