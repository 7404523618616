import { FC, memo } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MaterialTooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import { useStyles } from './Tooltip.styles';
import { ITooltipProps } from './Tooltip.types';

export const Tooltip: FC<ITooltipProps> = memo(
  ({
    isClick,
    isHover,
    isArrow,
    interactive,
    open,
    children,
    placement = 'right',
    title,
    zIndex = 1000,
    onClose = () => {},
    ...props
  }) => {
    const styles = useStyles({ zIndex });

    if (isClick) {
      return (
        <ClickAwayListener onClickAway={onClose}>
          <div>
            <MaterialTooltip
              {...props}
              classes={{
                popper: styles.popper,
                tooltip: clsx(
                  styles.tooltip,
                  placement === 'right' && 'placementRight',
                  placement === 'left' && 'placementLeft',
                  placement === 'top' && 'placementTop',
                  placement === 'bottom' && 'placementBottom',
                ),
                arrow: styles.arrow,
                tooltipArrow: styles.tooltipArrow,
              }}
              title={title}
              interactive={interactive}
              arrow={isArrow}
              open={open}
              placement={placement}
              disableFocusListener={props.disableFocusListener ?? isClick}
              disableTouchListener={props.disableTouchListener ?? isClick}
              disableHoverListener={props.disableHoverListener ?? isClick}
              onClose={onClose}
            >
              {children}
            </MaterialTooltip>
          </div>
        </ClickAwayListener>
      );
    }

    return (
      <MaterialTooltip
        {...props}
        classes={{
          popper: styles.popper,
          tooltip: styles.tooltip,
          arrow: styles.arrow,
          tooltipArrow: styles.tooltipArrow,
        }}
        title={title}
        interactive={interactive}
        arrow={isArrow}
        open={open}
        placement={placement}
        disableFocusListener={props.disableFocusListener ?? isHover}
        disableTouchListener={props.disableTouchListener ?? isHover}
        onClose={onClose}
      >
        {children}
      </MaterialTooltip>
    );
  },
);
