import { omitBy } from 'utils/general/omit-by';

const countryMap: Record<string, string> = {
  CA: 'Canada',
  US: 'USA',
};

export const generateStringAddress = (
  address: {
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    postal?: string;
    country?: string;
  },
  ignorCommas?: {
    address?: boolean;
    address2?: boolean;
    city?: boolean;
    state?: boolean;
    postal?: boolean;
    country?: boolean;
  },
  fullCountryName = true,
) => {
  const omittedAddress = omitBy(
    {
      ...address,
      country:
        fullCountryName && address.country && Object(countryMap).hasOwnProperty(address.country)
          ? countryMap[address.country]
          : address.country,
    },
    (val: unknown) => val === '' || val === undefined,
  );

  const omittedAddressEntries = Object.entries(omittedAddress);

  return omittedAddressEntries
    .map((el, i) => {
      if (Object(ignorCommas).hasOwnProperty(el[0])) {
        return el[1];
      } else if (i !== omittedAddressEntries.length - 1) {
        return `${el[1]},`;
      } else {
        return el[1];
      }
    })
    .join(' ');
};
