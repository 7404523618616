import { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';

import { SchedulesList } from '../../schedulesList/SchedulesList';
import { GeneralTopbarProps } from '../GeneralTopbar.types';
import { useDialogDispatch } from 'hooks';

export const useSchedulesDialog = () => {
  const setDialog = useDialogDispatch();

  const handleSchedulesDialogClose = useCallback(() => {
    setDialog({ open: false });
  }, [setDialog]);

  const handleSchedulesDialogOpen = useCallback(
    (schedules: GeneralTopbarProps['schedules']) => {
      setDialog({
        open: true,
        variant: 'withSeparator',
        title: 'Schedules list',
        disableBackdropClick: true,
        dialogContent: (
          <Grid container style={{ maxWidth: '473px' }}>
            <div style={{ width: '473px' }}>
              <SchedulesList schedules={schedules} />
            </div>
          </Grid>
        ),
        onClose: handleSchedulesDialogClose,
      });
    },
    [handleSchedulesDialogClose, setDialog],
  );

  return { handleSchedulesDialogClose, handleSchedulesDialogOpen };
};
