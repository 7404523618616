import React, { createElement, useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import moment from 'moment';

import { DownloadIcon, EyeIcon, TrashIcon } from '../../assets';
import { Paper } from '../paper/Paper';
import { getFileIcon } from 'utils';

import { useStyles } from './AttachmentCard.styles';
import { AttachmentCardProps } from './AttachmentCard.types';

export const AttachmentCard: React.FC<AttachmentCardProps> = ({
  author,
  createdDate,
  description,
  fileName,
  undefinedCreatedDataText = 'Recently',
  classes,
  onView,
  onDownload,
  onDelete,
  hideInfo,
  ...props
}) => {
  const styles = useStyles();

  const info = useMemo(() => {
    if (hideInfo) {
      return;
    }

    let text = '';

    if (author) {
      text += `by ${author} • `;
    }

    text += createdDate ? moment(createdDate).format('MM/DD/YYYY') : undefinedCreatedDataText;

    return text;
  }, [author, createdDate, hideInfo, undefinedCreatedDataText]);

  return (
    <Paper elevation={24} className={classes?.paper} {...props}>
      <div className={clsx(styles.wrapper, classes?.wrapper)}>
        <div className={styles.info}>
          <div className={styles.fileIconHolder}>
            {createElement(getFileIcon({ fileName }), { className: styles.fileIcon })}
          </div>

          <div>
            <Typography variant="subtitle1" className={styles.primaryText}>
              {fileName}
            </Typography>

            {!!info && (
              <Typography variant="body1" className={styles.secondaryText}>
                {info}
              </Typography>
            )}
          </div>
        </div>

        <div className={styles.actions}>
          {onView && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onView}>
              <EyeIcon className={styles.actionButton} />
            </IconButton>
          )}

          {onDownload && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onDownload}>
              <DownloadIcon className={styles.actionButton} />
            </IconButton>
          )}

          {onDelete && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onDelete}>
              <TrashIcon className={styles.actionButton} />
            </IconButton>
          )}
        </div>

        {description && (
          <div className={styles.description}>
            <Typography variant="body1" className={clsx(styles.secondaryText, styles.descriptionText)}>
              {description}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};
