import { memo, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';

import { colors } from 'assets';
import { Paper, UserIdentifier } from 'components';

export const CommentAddButton = memo(
  ({ onClick, userImage, userName }: { onClick: () => void; userImage?: string; userName: string }) => {
    const handleClick = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      },
      [onClick],
    );

    return (
      <Paper variant="outlined" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <div style={{ padding: '12px 16px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8 }}>
            <UserIdentifier imageUrl={userImage} isDisplayName={false} name={userName} size="large" />
          </div>

          <div style={{ color: colors.grey140 }}>
            <Typography variant="subtitle1" color="inherit">
              Add a comment
            </Typography>
          </div>
        </div>
      </Paper>
    );
  },
);
