import { createElement, memo, useMemo } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from './GeneralTopbar.styles';
import { GeneralTopbarProps } from './GeneralTopbar.types';
import { NasaBar } from './nasaBar/NasaBar';
import { GeneralTopbarDesktop, GeneralTopbarMobile } from './views';

export const GeneralTopbar = memo(
  ({
    account,
    category,
    categoryDisabled,
    categories,
    convertedAppointmentId,
    handleKebabMenuMenuItemClick = () => {},
    handleShortcutMenuMenuItemClick = () => {},
    jobId,
    jobType,
    jobTypes,
    jobTypeDisabled,
    jobTypeTooltip,
    kebabMenuItems,
    location,
    locationOptions,
    isNasa,
    onChangeCategory,
    onChangeJobType,
    onChangeStatus,
    onReschedule,
    onSetSchedule = () => {},
    rescheduleDisabled,
    status,
    statusDisabled,
    statuses,
    scheduleDate,
    schedules,
    shortcuts,
  }: GeneralTopbarProps) => {
    const styles = useStyles({ isNasa });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const View = useMemo(() => (!isMobile ? GeneralTopbarDesktop : GeneralTopbarMobile), [isMobile]);

    return (
      <>
        {isNasa && <NasaBar />}
        <div className={styles.root}>
          {createElement(View, {
            account,
            category,
            categoryDisabled,
            categories,
            handleKebabMenuMenuItemClick,
            handleShortcutMenuMenuItemClick,
            jobId,
            kebabMenuItems,
            location,
            locationOptions,
            onChangeCategory,
            onChangeStatus,
            scheduleDate,
            schedules,
            shortcuts,
            status,
            statusDisabled,
            statuses,
            jobTypeDisabled,
            jobTypeTooltip,
            jobType,
            jobTypes,
            isNasa,
            onChangeJobType,
            onReschedule,
            onSetSchedule,
            rescheduleDisabled,
            convertedAppointmentId,
          })}
        </div>
      </>
    );
  },
);
