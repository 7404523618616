import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets';
import { Input } from 'components/input/Input';

export const InputStyled = withStyles({
  input: {
    width: 45,
    height: 24,
    textAlign: 'center',
  },
})(Input);

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },

  inputSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  label: {
    color: colors.grey100,
  },
}));
