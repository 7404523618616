import Checkbox from '@material-ui/core/Checkbox';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets';
import { fontWeight } from 'assets/theme/typography';

export const CheckboxStyled = withStyles(() =>
  createStyles({
    root: {
      '&.Mui-disabled': {
        '& rect': {
          stroke: colors.grey80,
        },
      },
    },
  }),
)(Checkbox);

export const useStyles = makeStyles(
  createStyles({
    label: {
      '& .MuiFormControlLabel-label': {
        fontSize: '16px',
        color: colors.basic.black,
        fontWeight: fontWeight.bold,
      },
    },
    labelSmall: {
      '& .MuiFormControlLabel-label': {
        marginLeft: 0,
        fontSize: '14px',
      },
    },
  }),
);
