import { FC, memo } from 'react';

import { useSplitTreatments } from '@splitsoftware/splitio-react';

import { Loader } from 'components/loader/Loader';

import { SplitTreatmentsProps } from './SplitTreatments.types';

export { useSplitTreatments } from '@splitsoftware/splitio-react';

export const SplitTreatments: FC<SplitTreatmentsProps> = memo(({ flagNames, attributes, trafficType, children }) => {
  const { treatments, isReady, isTimedout } = useSplitTreatments({
    names: flagNames,
    updateOnSdkTimedout: true,
    attributes,
    trafficType,
  });

  if (isReady) {
    return <>{children(treatments)}</>;
  }

  if (isTimedout) {
    return <div>Split.io: SDK timed out (check your SDK key)</div>;
  }

  return <Loader />;
});
