import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../assets';

export const useStyles = makeStyles({
  alert: {
    color: colors.basic.white,
    '& .MuiAlert-message': {
      flex: '1',
      textAlign: 'center',
    },
    '& .MuiAlert-action': {
      paddingLeft: '0',
    },
  },
  snackbar: {
    position: 'unset',
    top: 'auto',
    right: 'auto',
    left: 'auto',
    transform: 'inherit',
  },
});
