import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme';

import { SvgIconProps } from './SvgIcon.types';

export const ChatIcon = ({ className, fontSize = 20, color = colors.basic.black }: SvgIconProps) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize, fill: 'none' }}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.125 7.875H9.125L5.375 11.625V7.875H2.875C2.18464 7.875 1.625 7.31536 1.625 6.625V2.875C1.625 2.18464 2.18464 1.625 2.875 1.625H19.125C19.8154 1.625 20.375 2.18464 20.375 2.875V6.625C20.375 7.31536 19.8154 7.875 19.125 7.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.875 10.375C2.18464 10.375 1.625 10.9346 1.625 11.625V15.375C1.625 16.0654 2.18464 16.625 2.875 16.625H12.875L16.625 20.375V16.625H19.125C19.8154 16.625 20.375 16.0654 20.375 15.375V11.625C20.375 10.9346 19.8154 10.375 19.125 10.375H10.375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
