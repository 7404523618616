import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets';

export const HelperTxt = withStyles(() => ({
  root: {
    fontSize: 12,
    lineHeight: 2,
    color: colors.grey140,
  },
}))(FormHelperText);
