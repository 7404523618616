import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmationDialog } from 'components/confirmationDialog/ConfirmationDialog';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { ConfirmationTitle } from './Confirmation.title';
import { CancelFnc, ConfirmFnc, UseConfirmDialogParams } from './ConfirmationDialog.types';

export const useConfirmationDialog = ({
  title,
  titleType,
  message,
  confirmButtonText,
  cancelButtonText,
  confirmationButtonType,
  cancelButtonType,
  confirmationButtonColor,
  cancelButtonColor,
  hideConfirmButton,
  hideCancelButton,
  maxWidth,
  hideDialogCloseButton,
}: UseConfirmDialogParams) => {
  const setDialog = useDialogDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>();
  const [onConfirm, setConfirmFnc] = useState<ConfirmFnc | null>(null);
  const [onCancel, setCancelFnc] = useState<CancelFnc>();

  const onClose = useCallback(
    (type: 'cancel' | 'closeDialog' = 'cancel') => {
      setConfirmFnc(null);
      setIsConfirmationDialogOpen(false);

      setDialog({ open: false, variant: 'custom' });

      if (type !== 'closeDialog' && onCancel) {
        onCancel();
      }
    },
    [onCancel, setDialog],
  );

  const defaultCustomState = useMemo(
    () => ({ message, confirm: confirmButtonText, cancel: cancelButtonText, title }),
    [cancelButtonText, confirmButtonText, message, title],
  );
  const [customState, setCustomState] = useState(defaultCustomState);

  const openConfirmation = useCallback(
    (
      confirmFnc: ConfirmFnc,
      cancelFnc?: CancelFnc,
      custom?: {
        cancel?: string;
        confirm?: string;
        message?: string | JSX.Element;
        title?: string;
      },
    ) => {
      setCustomState({
        cancel: custom?.cancel || cancelButtonText,
        confirm: custom?.confirm || confirmButtonText,
        message: custom?.message || message,
        title: custom?.title || title,
      });

      setIsConfirmationDialogOpen(true);
      setConfirmFnc(() => confirmFnc);
      setCancelFnc(() => cancelFnc);
    },
    [cancelButtonText, confirmButtonText, message, title],
  );

  useEffect(() => {
    if (isConfirmationDialogOpen) {
      setDialog({
        open: true,
        variant: 'basic',
        title: <ConfirmationTitle title={customState.title} titleType={titleType} />,
        dialogContent: (
          <ConfirmationDialog
            onConfirm={onConfirm as ConfirmFnc}
            onClose={onClose}
            message={customState.message}
            confirmButtonText={customState.confirm}
            cancelButtonText={customState.cancel}
            confirmationButtonType={confirmationButtonType}
            cancelButtonType={cancelButtonType}
            confirmationButtonColor={confirmationButtonColor}
            cancelButtonColor={cancelButtonColor}
            isLoading={isLoading}
            hideConfirmButton={hideConfirmButton}
            hideCancelButton={hideCancelButton}
            maxWidth={maxWidth}
          />
        ),
        onClose,
        disableBackdropClick: true,
        hideCloseButton: hideDialogCloseButton,
      });
    } else if (isConfirmationDialogOpen === false) {
      onClose('closeDialog');
    }
  }, [
    cancelButtonColor,
    cancelButtonType,
    confirmationButtonColor,
    confirmationButtonType,
    customState.cancel,
    customState.confirm,
    customState.message,
    hideCancelButton,
    hideConfirmButton,
    hideDialogCloseButton,
    isConfirmationDialogOpen,
    isLoading,
    maxWidth,
    onClose,
    onConfirm,
    setDialog,
    title,
    titleType,
  ]);

  return { closeConfirmation: onClose, openConfirmation, setLoader: setLoading, isOpen: isConfirmationDialogOpen };
};
