import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useTaskViewStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 740,
    maxWidth: '100%',
    overflowY: 'auto',
  },

  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  formContainer: {
    flex: 1,
    padding: 32,

    [theme.breakpoints.down('xs')]: {
      padding: 24,
    },
  },

  formHeader: {
    marginBottom: 8,
  },

  bottomBar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 5,
    boxShadow: '-1px 0px 10px 5px rgba(0, 0, 0, 0.05)',
    padding: 16,
    backdropFilter: 'blur(8px)',
  },

  scrollNavTitle: {
    textWrap: 'nowrap',
  },
}));
