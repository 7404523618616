import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { Button, Checkbox, Paper, TextEditor } from 'components';

import { useStyles } from './styles';
import { CommentEditorProps } from './types';

export const CommentEditor: FC<CommentEditorProps> = memo(
  ({
    comment = '',
    showInAccountPage = false,
    showInJobPage = false,
    showAccountCheckxox,
    showAppointmentCheckxox,
    Component,
    disabled = false,
    isVisible = false,
    mentionProps,
    setIsVisible,
    onSubmit,
    showSaveButton,
  }) => {
    const styles = useStyles();

    const [visibility, setVisibility] = useState(isVisible);
    const [text, setText] = useState(comment);
    const [showIn, setShowIn] = useState({ showInAccountPage, showInJobPage });

    const isBlank = useMemo(
      () =>
        text
          .replaceAll('&nbsp;', '')
          .replaceAll('&ZeroWidthSpace;', '')
          .replaceAll(' ', '')
          .replaceAll('<br>', '')
          .replaceAll('<p></p>', '') === '',
      [text],
    );

    const isTouched = useMemo(() => !(text === '<p><br></p>' || text === ''), [text]);

    const isError = useMemo(
      () => !!(comment && isBlank) || (isBlank && isTouched),
      [comment, isBlank, isTouched, text],
    );

    const setShowInValue = useCallback((key: 'showInAccountPage' | 'showInJobPage') => {
      return (val: boolean) => {
        setShowIn(prev => ({ ...prev, [key]: val }));
      };
    }, []);

    const openEditor = useCallback(() => {
      if (!comment) {
        setText('');
      }

      setVisibility(true);

      if (setIsVisible) {
        setIsVisible(true);
      }
    }, [comment, setIsVisible]);

    const closeEditor = useCallback(() => {
      setVisibility(false);

      if (setIsVisible) {
        setIsVisible(false);
      }
    }, [setIsVisible]);

    const onSave = useCallback(() => {
      if (!isBlank) {
        onSubmit({
          text,
          showInAccountPage: showIn.showInAccountPage,
          showInJobPage: showIn.showInJobPage,
        });

        closeEditor();

        if (!comment) {
          setText('');
        }
      } else if (!comment && !isTouched) {
        closeEditor();
      }
    }, [closeEditor, comment, isBlank, isTouched, onSubmit, showIn.showInAccountPage, showIn.showInJobPage, text]);

    const onClickAway = useCallback(() => {
      if (!showSaveButton && visibility) {
        onSave();
      }
    }, [onSave, showSaveButton, visibility]);

    useEffect(() => {
      setText(comment);
      setShowIn({ showInAccountPage, showInJobPage });
    }, [comment, showInAccountPage, showInJobPage]);

    useEffect(() => {
      setVisibility(isVisible);
    }, [isVisible]);

    return (
      <div className={styles.root}>
        <Collapse in={!visibility}>
          <Component onClick={openEditor} />
        </Collapse>

        <Collapse in={visibility} unmountOnExit>
          <ClickAwayListener onClickAway={onClickAway} mouseEvent="onMouseDown">
            <div>
              <Paper roundingValue={0} variant="outlined" className={styles.content}>
                <TextEditor
                  value={text}
                  onChange={setText}
                  onInputType={setText}
                  mentionProps={mentionProps}
                  disabled={disabled}
                  error={isError}
                  options={{
                    buttonList: [
                      ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
                      ['align', 'list'],
                      ['fontSize'],
                      ['undo', 'redo'],

                      [
                        '%899',
                        [
                          ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
                          ['align', 'list'],
                          ['fontSize'],
                          ['undo', 'redo'],
                        ],
                      ],
                      [
                        '%599',
                        [
                          ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
                          ['align', 'list'],
                          ['fontSize'],
                          ['undo', 'redo'],
                        ],
                      ],
                    ],
                  }}
                />

                {isError && <Typography color="error">Comment can't be blank</Typography>}

                <div className={styles.actionsContainer}>
                  {showAccountCheckxox && (
                    <Checkbox
                      variant="outline"
                      label="Add to account"
                      color="primary"
                      checked={showIn.showInAccountPage}
                      className="checkbox"
                      onChange={setShowInValue('showInAccountPage')}
                    />
                  )}

                  {showAppointmentCheckxox && (
                    <Checkbox
                      variant="outline"
                      label="Add to job"
                      color="primary"
                      checked={showIn.showInJobPage}
                      className="checkbox"
                      onChange={setShowInValue('showInJobPage')}
                    />
                  )}

                  {showSaveButton && (
                    <div className={styles.submitButtonContainer}>
                      <Button buttonType="twoTone" fullWidth className={styles.submitButton} onClick={onSave}>
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Paper>
            </div>
          </ClickAwayListener>
        </Collapse>
      </div>
    );
  },
);
