import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PopoverActions } from '@material-ui/core/Popover';

import { useStyles } from './Popover.styles';
import { PopoverControlsProps } from './Popover.types';

export const usePopoverControls = ({ variant, disabled, open, onOpen, onClose }: PopoverControlsProps) => {
  const styles = useStyles();

  const popoverAnchor = useRef<HTMLElement | null>(null);
  const popoverActions = useRef<PopoverActions | null>(null);

  const [isVisible, setVisibility] = useState<boolean>(false);
  const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });

  const isHoverVariant = useMemo(() => variant === 'hover', [variant]);

  const popoverClass = useMemo(() => (isHoverVariant ? styles.root : undefined), [isHoverVariant, styles.root]);

  const paperClass = useMemo(
    () => (isHoverVariant ? { paper: styles.paper } : undefined),
    [isHoverVariant, styles.paper],
  );

  const popoverOpen = useCallback(() => (!disabled && onOpen ? onOpen() : setVisibility(true)), [disabled, onOpen]);

  const popoverClose = useCallback(() => (onClose ? onClose() : setVisibility(false)), [onClose]);

  const triggerProps = useMemo(
    () =>
      isHoverVariant
        ? { onMouseEnter: popoverOpen, onMouseLeave: popoverClose }
        : { onClick: () => (!isVisible ? popoverOpen() : popoverClose()) },
    [isHoverVariant, isVisible, popoverOpen, popoverClose],
  );

  useEffect(() => {
    if (typeof open === 'boolean') {
      setVisibility(open);
    }
  }, [open]);

  const interval = useRef<ReturnType<typeof setInterval> | null>();
  useEffect(() => {
    if (isVisible && popoverAnchor.current) {
      interval.current = setInterval(() => {
        const rect = popoverAnchor.current?.getBoundingClientRect();

        if (rect) {
          setAnchorPosition({ x: rect.left, y: rect.top });
        }
      }, 10);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [anchorPosition.x, anchorPosition.y]);

  return {
    popoverAnchor,
    popoverActions,
    isVisible,
    popoverOpen,
    popoverClose,
    isHoverVariant,
    triggerProps,
    popoverClass,
    paperClass,
  };
};
