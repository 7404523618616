import { memo, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from '../button/Button';
import { TextArea } from '../textArea/TextArea';

import { useStyles } from './ReportIncident.styles';
import { ReportIncidentProps } from './ReportIncident.types';

export const ReportIncident = memo(({ onCancel }: ReportIncidentProps) => {
  const styles = useStyles();

  const [message, setMessage] = useState<string>('');

  return (
    <Grid container className={styles.root}>
      <Grid container className={styles.container}>
        <Grid container>
          <TextArea
            rowsMax={7}
            rows={7}
            fullWidth
            variant="outlined"
            placeholder="What happened?"
            size="medium"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Grid>
        <Grid container spacing={1} justify="flex-end" className={styles.buttonsContainer}>
          <Grid item>
            <Button
              buttonType="text"
              size="medium"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button buttonType="twoTone" size="medium" onClick={() => {}} style={{ width: '136px' }}>
              Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
