import { fade } from '@material-ui/core/styles/colorManipulator';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors, fontWeight, NoahFont } from 'assets';

export const useStyles = makeStyles(
  createStyles({
    datePicker: {
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: '0px',

      '& .react-datepicker__header': {
        textAlign: 'center',
        backgroundColor: 'transparent',
        borderBottom: '0px',
        paddingTop: '0px',
      },

      '& .react-datepicker__week': {
        margin: '2px 0',
      },

      '& .react-datepicker__day': {
        width: '36px',
        height: '36px',
        lineHeight: '36px',
        borderRadius: '18px',
        fontFamily: NoahFont,
        fontSize: '14px',
        fontWeight: fontWeight.bold,
        margin: 0,
        outline: 'none',
        '&.react-datepicker__day--today:not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range)': {
          color: colors.secondary.main,
        },
        '&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range': {
          borderRadius: 0,
        },
        '&.react-datepicker__day--range-start, &.react-datepicker__day--selecting-range-start': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: '18px',
          borderBottomLeftRadius: '18px',
        },
        '&.react-datepicker__day--range-end, &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover':
          {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: '18px',
            borderBottomRightRadius: '18px',
          },
        '&.react-datepicker__day--range-start.react-datepicker__day--range-end,  &.react-datepicker__day--selecting-range-start:hover':
          {
            borderRadius: '18px',
          },
      },

      '& .react-datepicker__day--outside-month': {
        color: colors.grey80,
      },

      '& .react-datepicker__day-name': {
        width: '36px',
        fontFamily: NoahFont,
        fontSize: '10px',
        fontWeight: fontWeight.bold,
        textTransform: 'uppercase',
        color: colors.secondary.main,
        margin: 0,
      },

      '& .react-datepicker__day--selected, .react-datepicker__day--in-range': {
        backgroundColor: colors.secondary.main,
      },

      '& .react-datepicker__month-text--keyboard-selected, .react-datepicker__month--selected': {
        backgroundColor: colors.primary.main,
        '&:hover': {
          backgroundColor: colors.primary.main,
        },
      },
      '& .react-datepicker__month-text.react-datepicker__month--selected:hover': {
        backgroundColor: colors.primary.main,
      },
      '& .react-datepicker__month .react-datepicker__month-text': {
        margin: '15px',
        fontSize: '15px',
        width: '75px',
      },

      '& .react-datepicker__day--in-selecting-range': {
        backgroundColor: fade(colors.secondary.main, 0.8),
      },
    },
  }),
);
