import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import { useTheme } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Palette } from '@material-ui/core/styles/createPalette';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { colors } from 'assets';

export const ListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #ECEFF3',
      padding: 0,
      '& .MuiListItem-root:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
  }),
)(List);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noAlertsContainer: {
      padding: '20px 0 12px',
      '& .MuiTypography-root': {
        color: colors.functionals.alert,
      },
    },
  }),
);
