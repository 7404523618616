import React, { ChangeEvent, FC, ReactNode, useCallback, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';

import { SelectStyled, useStyles } from './Select.styles';
import { SelectProps } from './Select.types';

export const Select: FC<SelectProps> = React.forwardRef(
  (
    {
      size = 'medium',
      variant = 'standard',
      label,
      placeholder,
      onChange,
      multiple,
      value,
      inputLabelShrink,
      ...props
    },
    ref,
  ) => {
    const styles = useStyles(size);
    const selectVariant = variant === 'pill' ? 'outlined' : variant === 'clean' ? 'outlined' : variant;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClose = () => {
      setIsMenuOpen(false);
    };

    const handleChange = useCallback(
      (e: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => {
        onChange && onChange(e, child);
        !multiple && handleClose();
      },
      [multiple, onChange],
    );

    return (
      <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
        <FormControl
          fullWidth
          className={clsx(
            styles.root,
            props.className,
            size === 'small' && styles.rootSmall,
            size === 'medium' && styles.rootMedium,
            size === 'large' && styles.rootLarge,
            variant === 'pill' && styles.rootPill,
            variant === 'outlined' && styles.rootOutlined,
            variant === 'clean' && styles.rootClean,
            props.error && styles.rootError,
            props.disabled && styles.rootDisabled,
          )}
          variant={selectVariant}
        >
          {label && (
            <InputLabel id={props.labelId || `select-${props.name}`} htmlFor={props.name} shrink={inputLabelShrink}>
              {label}
            </InputLabel>
          )}

          <SelectStyled
            {...props}
            labelId={props.labelId || `select-${props.name}`}
            open={isMenuOpen}
            onClose={handleClose}
            onOpen={() => setIsMenuOpen(true)}
            value={value}
            onChange={handleChange}
            variant={selectVariant}
            ref={ref}
            placeholder={!label ? placeholder : ''}
            className={props.className}
            fullWidth
            displayEmpty
            color="secondary"
            multiple={multiple}
            MenuProps={{
              ...props.MenuProps,
              disablePortal: true,
              open: isMenuOpen,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              style: {
                marginTop: 1,
              },
            }}
          >
            {props.children}
          </SelectStyled>
        </FormControl>
      </ClickAwayListener>
    );
  },
);
