import { ChangeEvent, memo, useCallback } from 'react';

import MuiSlider, { SliderProps } from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { Tooltip } from 'components/tooltip/Tooltip';

import { useSliderStyles } from './Slider.styles';

function ValueLabelComponent(props: { children: React.ReactElement; open: boolean; value: number }) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} placement="top" isArrow title={<Typography variant="subtitle2">{value}</Typography>}>
      {children}
    </Tooltip>
  );
}

export const Slider = memo(
  ({
    onChange,
    value,
    min,
    max,
    step,
  }: Omit<SliderProps, 'onChange'> & { onChange: (value: number | number[]) => void }) => {
    const styles = useSliderStyles();

    const handleSliderChange = useCallback(
      (event: ChangeEvent<unknown>, newValue: number | number[]) => onChange(newValue),
      [onChange],
    );

    return (
      <MuiSlider
        ValueLabelComponent={ValueLabelComponent}
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        classes={styles}
        value={value}
        onChange={handleSliderChange}
        min={min}
        max={max}
        step={step}
      />
    );
  },
);
