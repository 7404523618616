import { useContext } from 'react';

import { SplitDispatchContext } from 'context/split/SplitContextController';

export const useSplit = () => {
  const context = useContext(SplitDispatchContext);

  if (context === undefined) {
    throw new Error('useSplit must be used within an AlertProvider');
  }

  return context;
};
