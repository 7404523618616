import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      wrap: 'nowrap',
    },
  },
  headerTitleSection: {
    order: 1,

    [theme.breakpoints.up('sm')]: {
      flex: 1.5,
    },
  },
  headerDateSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    order: 3,

    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginTop: 0,
      flex: 1.5,
    },
  },
  headerDate: {
    color: colors.grey120,
  },
  headerActionsSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 16,
    order: 2,

    [theme.breakpoints.up('sm')]: {
      order: 3,
      flex: 1,
    },
  },
  calendarIcon: {
    fontSize: 18,
    display: 'block',
  },
  divider: {
    margin: '16px 0px',
  },
  comment: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    color: colors.grey190,
  },
}));
