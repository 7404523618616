import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ControlProps, StylesConfig } from 'react-select';

import { colors, NoahFont } from '../../assets';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlCSSForState = (state: ControlProps<any, boolean>, isValid: boolean, forFilter?: boolean) => {
  const focusedStyles = {
    borderColor: colors.primary.main,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.primary.main,
      boxShadow: `0px 0px 8px ${colors.primary.main}3d`,
    },
  };

  const errorStyles = {
    borderColor: colors.functionals.alert,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.functionals.alert,
      boxShadow: `0px 0px 8px ${colors.functionals.alert}3d`,
    },
  };

  if (state.isFocused && state.menuIsOpen && isValid) {
    return focusedStyles;
  }

  if (!isValid) {
    return errorStyles;
  }

  return {
    borderColor: forFilter ? colors.primary.main : colors.grey20,
    boxShadow: 'none',

    '&:hover': {
      borderColor: forFilter ? colors.primary.main : colors.grey40,
      boxShadow: forFilter ? 'none' : '0px 0px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: forFilter ? colors.primary.superlight : 'initial',
    },
  };
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
// It was needed to type it as any,
// react-select has some problems with generic typings when passing e.g number/string or T generic
export const selectStyles = (
  isValid: boolean,
  hasLabel: boolean,
  hasValue: boolean,
  isMulti: boolean,
  virtualize?: boolean,
  forFilter?: boolean,
  startAdortmentWidth?: number,
): StylesConfig<any, boolean> => ({
  container: provided => ({
    ...provided,
    height: forFilter ? 'auto' : isMulti ? (hasValue ? 'auto' : 56) : 56,
    minHeight: forFilter ? 39.38 : 56,
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%',
    minHeight: forFilter ? 39.38 : 56,
    outline: 'none',
    borderRadius: forFilter ? 25 : state.menuIsOpen ? '4px 4px 0px 0px' : 4,
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? colors.grey20 : colors.white,
    ...getControlCSSForState(state, isValid, forFilter),
  }),
  input: provided => ({
    ...provided,
    color: isValid ? colors.secondary.main : colors.functionals.alert,
    paddingTop: hasLabel ? (isMulti && hasValue ? 5 : 22) : 0,
    marginLeft: isMulti ? 3.5 : 2,
    fontWeight: 'bold',
    fontFamily: NoahFont,
    fontSize: 16,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '100%',
    color: colors.secondary.main,
    fontWeight: 'bold',
    paddingTop: hasLabel && isMulti && hasValue ? 17 : 2,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colors.secondary.main,
    '&:hover': {
      color: colors.secondary.main,
    },
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 3,
  }),
  menu: provided => ({
    ...provided,
    marginTop: provided.bottom === '100%' ? 10 : 0,
    marginBottom: provided.top === '100%' ? 10 : 0,
    border: 'none',
    borderRadius: provided.top === '100%' ? '0px 0px 8px 8px' : '8px 8px 0px 0px',
    boxShadow: '0px 0px 8px rgba(134, 145, 166, 0.32)',
    overflow: 'hidden',
    zIndex: 100,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    zIndex: 100,
  }),
  group: provided => ({
    ...provided,
    paddingBottom: 0,
    '& div[id*="option"]': {
      background: colors.basic.white,

      '&:nth-of-type(2n)': {
        background: colors.grey10,
      },
    },
  }),
  groupHeading: provided => ({
    ...provided,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: NoahFont,
    textTransform: 'none',
    paddingLeft: 8,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? colors.grey140 : colors.basic.black,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: '8px 8px 8px 16px',
    cursor: 'pointer',
    background: state.isDisabled
      ? `${colors.grey30}!important`
      : state.isSelected
      ? `${colors.grey20}!important`
      : 'inherit',
    minHeight: virtualize ? 40 : 'initial',

    '&:hover': {
      background: state.isFocused || state.isSelected ? `${colors.grey30}!important` : colors.grey10,
    },

    '&:nth-of-type(2n)': {
      background: state.isFocused || state.isSelected ? `${colors.grey30}!important` : colors.grey10,

      '&:hover': {
        background: state.isFocused || state.isSelected ? `${colors.grey40}!important` : colors.grey20,
      },
    },
  }),
  singleValue: provided => ({
    ...provided,
    top: hasLabel ? '38px' : '28px',
    marginLeft: '3.5px',
    fontSize: '16px',
    color: colors.secondary.main,
    maxWidth: `calc(100% - ${startAdortmentWidth ? 20 + startAdortmentWidth : 12}px)`,
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey20}`,
    boxShadow: '0px 0px 16px rgba(134 145 166 / 16%)!important',
    borderRadius: 4,
    padding: '2px 8px 2px 8px',
    margin: 2,
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: '0px !important',
  }),
  multiValueRemove: provided => ({
    ...provided,
    padding: 0,
    marginLeft: 12,
    '&:hover': {
      background: 'none',
    },
  }),
});

type Props = {
  isMulti?: boolean;
  hasLabel?: boolean;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  label: {
    left: 14,
    pointerEvents: 'none',
    position: 'absolute',
    transition: 'transform 200ms ease-out, top 200ms ease-out',
    fontSize: 16,
    top: '50%',
    transform: 'scale(1) translate(0, -50%)',
    fontWeight: 'bold',
    color: fade(colors.grey190, 0.6),
    margin: 0,
    zIndex: 1,
    transformOrigin: '0px 0px',
  },
  floatingLabel: {
    top: ({ isMulti, hasLabel }: Props) => (isMulti && hasLabel ? '4px' : '50%'),
    transform: ({ isMulti, hasLabel }: Props) =>
      isMulti && hasLabel ? 'translate(0px) scale(0.7)' : 'translate(0px, -20px) scale(0.7)',
    fontWeight: 'normal',
  },
  hideLabel: {
    display: 'none',
  },
  selectContainer: {
    '& input': {
      fontWeight: 'bold',
      fontFamily: NoahFont,
    },
  },
  categoryName: {
    color: colors.grey120,
  },
  errorLabel: {
    color: colors.functionals.alert,
  },
  multiItemText: {
    lineHeight: '24px',
  },
  multiItemUserIdentifier: {
    '& .MuiTypography-root': {
      color: colors.secondary.main,
      fontWeight: 700,
      fontSize: '16px!important',
    },
  },
  multiItemRemoveIconContainer: {
    padding: 8,
    margin: -8,
  },

  virtualOptionsList: {
    width: '100% !important',
    maxWidth: '100% !important',

    '& .ReactVirtualized__Grid__innerScrollContainer': {
      width: '100% !important',
      maxWidth: '100% !important',

      '& > div[class*="groupContainer"]': {
        background: colors.basic.white,

        '&:nth-child(even)': {
          background: colors.basic.white,
        },
      },

      '& > div[parent="[object Object]"]': {
        background: colors.basic.white,

        '&:nth-child(even)': {
          background: colors.grey10,
        },
      },
    },
  },
}));
