import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: colors.secondary.main,
      color: colors.white,
      borderRadius: '4px 4px 0 0',
      padding: '8px 32px',

      [theme.breakpoints.down('xs')]: {
        padding: '4px 16px',
      },
    },
  }),
);
