import { FC } from 'react';

import MaterialRadio, { RadioProps } from '@material-ui/core/Radio';

import { useStyles } from './Radio.styles';

export const Radio: FC<RadioProps & { color?: string }> = ({ color = 'primary', ...props }) => {
  const styles = useStyles();

  return <MaterialRadio className={styles.root} color={color} {...props} />;
};
