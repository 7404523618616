import { memo } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { TaskRelationAccount } from 'entities/tasks';
import { applyPhoneMask, generateStringAddress } from 'utils';

export const AccountCard = memo(
  ({ account, separator = true }: { account: TaskRelationAccount; separator?: boolean }) => {
    return (
      <>
        {separator && (
          <div style={{ margin: '16px 0' }}>
            <Divider />
          </div>
        )}

        <Grid container spacing={2} justify="space-between">
          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              Client name
            </Typography>
            <Typography>{account.firstName + ' ' + account.lastName}</Typography>
          </Grid>

          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              Repeat client
            </Typography>

            <Typography>{account.repeatClient ? 'Yes' : 'No'}</Typography>
          </Grid>

          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              Primary phone number
            </Typography>

            {account.phone ? (
              <Link href={`tel:${account.phone}`} variant="body1" color="textSecondary">
                {applyPhoneMask(account.phone)}
              </Link>
            ) : (
              '-'
            )}
          </Grid>

          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              Secondary phone number
            </Typography>
            <Typography>
              {account.cell ? (
                <Link href={`tel:${account.cell}`} variant="body1" color="textSecondary">
                  {applyPhoneMask(account.cell)}
                </Link>
              ) : (
                '-'
              )}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              Address
            </Typography>
            <Typography>
              {generateStringAddress(
                {
                  address: account.billingAddress.address,
                  city: account.billingAddress.city,
                  state: account.billingAddress.state,
                  postal: account.billingAddress.postal,
                  country: account.billingAddress.country,
                },
                {
                  postal: true,
                },
              )}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle1" color="secondary">
              E-mail
            </Typography>
            {account.email ? (
              <Link href={`tel:${account.email}`} variant="body1" color="textSecondary">
                {applyPhoneMask(account.email)}
              </Link>
            ) : (
              '-'
            )}
          </Grid>
        </Grid>
      </>
    );
  },
);
