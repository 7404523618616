import { memo, useCallback, useEffect, useState } from 'react';

import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { Paper } from '../paper/Paper';

import { AccordionStyled, AccordionSummaryStyled, AccordionDetailsStyled, useStyles } from './Accordion.styles';
import { AccordionProps } from './Accordion.types';

export const Accordion = memo(
  ({
    expanded,
    children,
    header,
    paperElevation,
    separator = 'dashed',
    expandIcon,
    expandIconColor,
    hideToggle,
    hideSeparator,
    disabled,
    className,
    accordionClasses,
    onChange,
    ...rest
  }: AccordionProps) => {
    const styles = useStyles();

    const [isExpanded, setExpanded] = useState(!!expanded);

    const handleChange = useCallback(
      (event, expanded) => {
        if (onChange) {
          onChange(event, expanded);
        } else {
          setExpanded(expanded);
        }
      },
      [onChange],
    );

    useEffect(() => {
      setExpanded(!!expanded);
    }, [expanded]);

    return (
      <Paper variant={paperElevation ? 'elevation' : 'outlined'} elevation={paperElevation} className={className}>
        <AccordionStyled
          expanded={isExpanded}
          disabled={disabled}
          classes={accordionClasses?.accordion}
          onChange={handleChange}
          {...rest}
        >
          <AccordionSummaryStyled
            expandIcon={!hideToggle && (expandIcon ?? <ExpandMore color={expandIconColor ?? 'primary'} />)}
            aria-controls="accordion-content"
            id="accordion-summary"
            classes={accordionClasses?.accordionSummary}
          >
            {header}
          </AccordionSummaryStyled>

          <AccordionDetailsStyled
            className={clsx(!hideSeparator && separator && separator)}
            classes={accordionClasses?.accordionDetails}
          >
            {isExpanded && <div className={clsx(styles.contentRoot, accordionClasses?.contentRoot)}>{children}</div>}
          </AccordionDetailsStyled>
        </AccordionStyled>
      </Paper>
    );
  },
);
