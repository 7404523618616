import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from '../../assets';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileIconHolder: {
      marginRight: 16,
    },

    fileIcon: {
      width: 40,
      height: '48px !important',
    },

    primaryText: {
      fontSize: 18,
      lineHeight: 1.4,
      color: colors.grey190,
    },

    secondaryText: {
      fontSize: 18,
      lineHeight: 1.4,
      color: colors.grey140,
    },

    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: 16,

      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },

    info: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      order: 1,
    },

    actions: {
      display: 'flex',
      alignItems: 'center',
      order: 1,

      [theme.breakpoints.down('xs')]: {
        order: 3,
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 16,
      },
    },

    action: {
      margin: '0 12px',
    },

    actionButton: {
      width: 24,
      height: '24px !important',

      [theme.breakpoints.down('xs')]: {
        fontSize: '32px !important',
        width: 32,
        height: '32px !important',
      },
    },

    description: {
      width: '100%',
      order: 3,
      marginTop: 18,

      [theme.breakpoints.down('xs')]: {
        order: 2,
      },
    },

    descriptionText: {
      whiteSpace: 'pre-wrap',
    },
  }),
);
