import { FC, memo, useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { TrashIcon } from 'assets';
import { Paper } from 'components';

import { useStyles } from './styles';
import { RelationCardProps } from './types';

export const RelationCard: FC<RelationCardProps> = memo(({ disabled, disabledLink, relation, getLink, onDelete }) => {
  const styles = useStyles();

  const link = useMemo(() => {
    if (disabledLink) {
      return;
    }

    return getLink(relation);
  }, [disabledLink, relation, getLink]);

  return (
    <Paper roundingValue={4} elevation={16} className={clsx(styles.root, disabled && 'disabled')}>
      <div className={styles.content}>
        <Typography className={styles.title}>{relation.type.label} ID:</Typography>

        <Link
          href={link}
          color={disabledLink ? 'inherit' : 'primary'}
          underline={disabledLink ? 'none' : 'always'}
          target="_blank"
        >
          <Typography color="inherit" className={styles.value}>
            <b>{relation.value}</b>
          </Typography>
        </Link>
      </div>

      {!disabled && (
        <IconButton className="iconButtonMedium" onClick={onDelete}>
          <TrashIcon fontSize={20} />
        </IconButton>
      )}
    </Paper>
  );
});
