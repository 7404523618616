import { fade } from '@material-ui/core/styles/colorManipulator';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';

import { fontWeight } from 'assets';
import { colors } from 'assets/theme/palette';

export const useStyles = () => {
  const style = makeStyles(
    createStyles({
      root: {
        '& .MuiInputLabel-root': {
          fontWeight: fontWeight.bold,
          fontSize: '16px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          '&.MuiInputLabel-shrink': {
            fontWeight: fontWeight.normal,
          },
        },
        '& ::placeholder': {
          fontWeight: fontWeight.bold,
        },
      },
      rootSmall: {
        '& ::placeholder': {
          fontSize: '14px',
        },
        '& .MuiInputBase-input': {
          margin: '7.25px',
          fontSize: '14px',
          lineHeight: 1.1,
        },
        '& .MuiInputLabel-root': {
          fontSize: '14px',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(6px, 24px) scale(1)',
            width: 'calc(100% - 6px)',
          },
        },
      },
      rootMedium: {
        '& ::placeholder': {
          fontSize: '16px',
        },
        '& .MuiInputBase-input': {
          margin: '8.875px',
          fontSize: '16px',
          lineHeight: 1.1,
        },
        '& .MuiInputLabel-root': {
          fontSize: '16px',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(8px, 30px) scale(1)',

            width: 'calc(100% - 8px)',
          },
        },
      },
      rootLarge: {
        '& ::placeholder': {
          fontSize: '18px',
        },
        '& .MuiInputBase-input': {
          margin: '12.125px',
          fontSize: '18px',
          lineHeight: 1.1,
        },
        '& .MuiInputLabel-root': {
          fontSize: '18px',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(10px, 32px) scale(1)',

            width: 'calc(100% - 10px)',
          },
        },
      },

      error: {
        '& .Mui-error': {
          color: colors.functionals.alert,
        },
        borderColor: `${colors.functionals.alert}!important`,
      },

      /* Outlined */
      rootOutlined: {
        background: colors.basic.white,
        border: `1px solid ${colors.grey20}`,
        borderRadius: '4px',
        transition: 'border-color 200ms ease 0s, box-shadow 200ms ease 0s ',
        '&:focus-within': {
          borderColor: colors.primary.main,
          boxShadow: `0px 0px 8px ${colors.primary.main}3d`,
        },
        '& label + .MuiOutlinedInput-root': {
          paddingTop: '16px',
        },
        '&$rootSmall': {
          '& label': {
            '&:not(.MuiInputLabel-shrink)': {
              transform: 'translate(10px, 16px) scale(1)',
              width: 'calc(100% - 10px)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(10px, 6px) scale(0.75)',
              width: 'calc((100% - 10px) * 0.6)',
            },
            '& + .MuiInputBase-root .MuiInputBase-input': {
              lineHeight: '16px',
              fontSize: '14px',
              padding: '0 4px',
            },
          },
        },
        '&$rootMedium': {
          '& label': {
            '&:not(.MuiInputLabel-shrink)': {
              transform: 'translate(14px, 18px) scale(1)',
              width: 'calc(100% - 14px)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, 8px) scale(0.75)', //scale to 12px
              width: 'calc((100% - 14px) * 0.6)',
            },
            '& + .MuiInputBase-root .MuiInputBase-input': {
              lineHeight: '18px',
              fontSize: '16px',
              padding: '0 5px',
            },
          },
        },
        '&$rootLarge': {
          '& label': {
            '&:not(.MuiInputLabel-shrink)': {
              transform: 'translate(18px, 20px) scale(1)',
              width: 'calc(100% - 18px)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(18px, 10px) scale(0.75)',
              width: 'calc((100% - 18px) * 0.6)',
            },
            '& + .MuiInputBase-root .MuiInputBase-input': {
              lineHeight: '21px',
              fontSize: '18px',
              padding: '0 6px',
            },
          },
        },
      },
      rootDisabled: {
        '& .MuiInputLabel-root': {
          zIndex: 1,
        },
        '&&': {
          background: colors.grey20,
        },
        '& .MuiInput-input:disabled, .MuiInput-input:disabled::placeholder': {
          '-webkit-text-fill-color': `${colors.secondary.main}` /* 1. sets text fill to current `color` for safari */,
          opacity: '1' /* 2. correct opacity on iOS */,
        },
        '& .Mui-disabled': {
          background: colors.grey20,
        },
      },
    }),
  );

  return style();
};

export const TextFieldStyled = withStyles(() =>
  createStyles({
    root: {
      '& ::placeholder': {
        color: fade(colors.grey190, 0.6),
        fontWeight: fontWeight.bold,
        opacity: 1,
      },
      '& .MuiInputBase-input': {
        color: colors.grey190,
      },
      '& .MuiInputBase-multiline': {
        padding: 0,
      },
      '& .MuiInputLabel-root': {
        color: fade(colors.grey190, 0.6),
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${colors.primary.main}`,
      },
      '& .MuiInput-underline:not(.Mui-disabled):after': {
        borderBottom: `2px solid ${colors.primary.main}`,
      },

      /* Outlined */
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '& .MuiInputBase-input': {
          color: colors.secondary.main,
          fontWeight: fontWeight.bold,
        },
      },
    },
  }),
)(TextField);
