import { ChangeEvent, FC, memo, useCallback, useState, useMemo } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import moment from 'moment';

import { Button } from '../../button/Button';
import { CustomerInfo } from '../../customerInfo/CustomerInfo';
import { KebabMenu, Shortcuts } from '../actionMenus';
import { AccordionDetailsStyled, AccordionStyled, AccordionSummaryStyled, useStyles } from '../GeneralTopbar.styles';
import { GeneralTopbarProps } from '../GeneralTopbar.types';
import { useSchedulesDialog } from '../hooks/useSchedulesDialog';
import { TooltipSelect } from '../tooltipSelect/TooltipSelect';
import { ArrowDown, CalendarIcon, ClockIcon, colors, PencilIcon, EyeIcon, CalendarFlipIcon } from 'assets';
import { Tooltip } from 'components/tooltip/Tooltip';

export const GeneralTopbarMobile: FC<GeneralTopbarProps> = memo(
  ({
    account,
    category,
    categoryDisabled,
    categories,
    handleKebabMenuMenuItemClick,
    handleShortcutMenuMenuItemClick,
    jobId,
    kebabMenuItems,
    location,
    onChangeCategory,
    onChangeStatus,
    onReschedule,
    onSetSchedule,
    rescheduleDisabled,
    scheduleDate,
    schedules,
    shortcuts,
    status,
    statusDisabled,
    statuses,
    jobTypeDisabled,
    jobTypeTooltip,
    jobType,
    jobTypes,
    onChangeJobType,
    convertedAppointmentId,
  }) => {
    const styles = useStyles({});
    const isSchedulesEmpty = useMemo(() => !Boolean(schedules.length), [schedules]);

    const [mobileTopbarExpanded, setMobileTopbarExpanded] = useState<string | false>(false);
    const [jobTypeTooltipVisibility, setJobTypeTooltipVisibility] = useState(false);

    const { handleSchedulesDialogOpen } = useSchedulesDialog();

    const handleChangeMobileTopbarExpanded = useCallback(
      (panel: string) => (event: ChangeEvent<unknown>, isExpanded: boolean) => {
        setMobileTopbarExpanded(isExpanded ? panel : false);
      },
      [setMobileTopbarExpanded],
    );

    return (
      <div className={styles.mobileRoot}>
        <Grid container alignItems={isSchedulesEmpty ? 'center' : 'flex-start'} justify="space-between">
          <Grid item>
            <div className={styles.idTypeContainer}>
              <Grid container className="MuiSpacingWidthAuto" justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body1" color="inherit">
                    ID: {jobId}
                  </Typography>
                </Grid>
                <Grid item>
                  <div style={{ margin: '0 -4px' }}>
                    <Tooltip
                      isClick
                      isArrow
                      interactive
                      placement="bottom"
                      open={!!jobTypeTooltip?.isShow && jobTypeTooltipVisibility}
                      onClose={() => setJobTypeTooltipVisibility(false)}
                      title={
                        <Typography variant="body1" style={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
                          {jobTypeTooltip?.tooltipTitle || ''}
                        </Typography>
                      }
                    >
                      <div onClick={() => setJobTypeTooltipVisibility(true)}>
                        <TooltipSelect
                          disabled={jobTypeDisabled}
                          selected={jobType}
                          list={jobTypes}
                          onChange={onChangeJobType}
                          typographyVariant="inherit"
                          placement="bottom"
                          classes={{
                            title: styles.jobTypesTooltipTitle,
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Shortcuts shortcuts={shortcuts} handleShortcutMenuMenuItemClick={handleShortcutMenuMenuItemClick} />
              </Grid>
              <Grid item>
                <KebabMenu
                  kebabMenuItems={kebabMenuItems}
                  handleKebabMenuMenuItemClick={handleKebabMenuMenuItemClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="space-between" wrap="nowrap" spacing={2} className={styles.categoryAndNameBlock}>
          <Grid item className={styles.categoryItem}>
            <div className={styles.categoryContainer} style={{ margin: '0 -4px' }}>
              <TooltipSelect
                selected={category}
                list={categories}
                onChange={onChangeCategory}
                typographyVariant="h4"
                placement="bottom"
                disabled={categoryDisabled}
              />
            </div>
          </Grid>
          <Grid item>
            <a
              href={`https://support-testing.chhj.com/hunkware/bookingflow/clientview?id=${account.id}&loc_id=${location.id}`}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              <Typography variant="h4" className={styles.accountInfoName}>
                {account.name}
              </Typography>
            </a>
          </Grid>
        </Grid>

        {mobileTopbarExpanded !== 'generalTopbar' ? (
          <Grid container justify="space-between" className={styles.dateAndTimeBlock}>
            <Grid item>
              <Grid container alignItems="center" spacing={1} className="MuiSpacingWidthAuto">
                <Grid item>
                  <CalendarIcon className={clsx(styles.calendarIcon, styles.icon)} color={colors.grey100} />
                </Grid>
                <Grid item>
                  <Typography variant="caption">{moment(scheduleDate).format('MMM DD, YYYY')}</Typography>
                </Grid>

                {onReschedule && (
                  <Grid item>
                    <IconButton className={styles.iconButtonMediumMargin} onClick={onReschedule}>
                      <PencilIcon className={styles.pencilIcon} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item className={styles.schedulesContainer}>
              <Grid
                container
                alignItems={schedules.length < 2 ? 'center' : 'flex-start'}
                spacing={1}
                className="MuiSpacingWidthAuto"
              >
                <Grid item className={isSchedulesEmpty ? styles.alignSelf : ''}>
                  <ClockIcon className={clsx(styles.clockIcon, styles.icon)} color={colors.grey100} />
                </Grid>
                <Grid item className={styles.timeContainerNotExpand}>
                  {schedules && schedules.length ? (
                    schedules.slice(0, 3).map((schedule, i) => (
                      <Typography key={i} variant="caption" component="p">
                        {moment(schedule.startDate).format('h:mma')} - {moment(schedule.endDate).format('h:mma')}
                      </Typography>
                    ))
                  ) : (
                    <Button
                      buttonType="text"
                      size="small"
                      color="primary"
                      className={styles.setScheduleButton}
                      disabled={rescheduleDisabled}
                      onClick={onSetSchedule}
                    >
                      Set Schedule
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div className={styles.statusSelectContainer}>
            <div className={styles.statusTooltipContainer}>
              <TooltipSelect
                selected={status}
                list={statuses}
                onChange={onChangeStatus}
                toggleVariant="outlined"
                placement="bottom"
                disabled={statusDisabled}
              />
            </div>
          </div>
        )}

        <div className={styles.accordionContainer}>
          <AccordionStyled
            expanded={mobileTopbarExpanded === 'generalTopbar'}
            onChange={handleChangeMobileTopbarExpanded('generalTopbar')}
          >
            <AccordionSummaryStyled
              expandIcon={<ArrowDown />}
              aria-controls="panel1bh-content"
            ></AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <div>
                <Divider />
                <div className={styles.accordionDetailsItem}>
                  <Grid container alignItems="center" spacing={1} className="MuiSpacingWidthAuto">
                    <Grid item>
                      <CalendarIcon className={clsx(styles.calendarIcon, styles.icon)} color={colors.grey100} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{moment(scheduleDate).format('MMM DD, YYYY')}</Typography>
                    </Grid>

                    {onReschedule && !rescheduleDisabled && (
                      <Grid item>
                        <IconButton className={styles.iconButtonMediumMargin} onClick={onReschedule}>
                          <PencilIcon className={styles.pencilIcon} />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <div className={styles.infoRow}>
                    <Grid
                      container
                      alignItems={schedules.length < 2 ? 'center' : 'flex-start'}
                      spacing={1}
                      className="MuiSpacingWidthAuto"
                    >
                      <Grid item className={isSchedulesEmpty ? styles.alignSelf : ''}>
                        <ClockIcon
                          className={clsx(
                            styles.clockIcon,
                            styles.icon,
                            schedules.length > 1 && styles.clockIconMargin,
                          )}
                          color={colors.grey100}
                        />
                      </Grid>
                      <Grid item className={styles.timeContainer}>
                        {schedules && schedules.length ? (
                          schedules.slice(0, 3).map((schedule, i) => (
                            <Typography key={i} variant="body1">
                              {moment(schedule.startDate).format('h:mma')} - {moment(schedule.endDate).format('h:mma')}
                            </Typography>
                          ))
                        ) : (
                          <Button
                            buttonType="text"
                            size="small"
                            onClick={onSetSchedule}
                            className={styles.setScheduleButton}
                          >
                            Set Schedule
                          </Button>
                        )}
                      </Grid>

                      {schedules.length > 2 && (
                        <Grid item>
                          <IconButton
                            className={styles.eyeIconButton}
                            onClick={() => {
                              handleSchedulesDialogOpen(schedules);
                            }}
                          >
                            <EyeIcon fontSize={20} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>

                    {convertedAppointmentId ? (
                      <div className={styles.infoRow}>
                        <Grid container spacing={1} wrap="nowrap" alignItems="center">
                          <Grid item>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CalendarFlipIcon />
                            </div>
                          </Grid>

                          <Grid item>
                            <Typography variant="body1">
                              Job:
                              <a
                                href={`/${convertedAppointmentId}`}
                                target="_blank"
                                className={styles.convertedJobLink}
                                rel="noreferrer"
                              >
                                #{convertedAppointmentId}
                              </a>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <Divider />
                <div className={styles.customerInfoContainer}>
                  <CustomerInfo
                    id={account.id}
                    clientType={account.clientType}
                    email={account.email}
                    phone={account.phone}
                    address={account.address}
                    referralSource={account.referralSource}
                    franchisee={account.franchise}
                  />
                </div>
              </div>
            </AccordionDetailsStyled>
          </AccordionStyled>
        </div>
      </div>
    );
  },
);
