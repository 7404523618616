import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { rgba } from 'polished';

import { colors } from '../../assets';

export const PaperStyled = withStyles(() => ({
  outlined: {
    borderColor: colors.grey30,
  },
  rounded: {
    borderRadius: 4,

    '&.borderRound_4': {
      borderRadius: 4,
    },

    '&.borderRound_8': {
      borderRadius: 8,
    },

    '&.borderRound_12': {
      borderRadius: 12,
    },

    '&.borderRound_16': {
      borderRadius: 16,
    },
  },
  elevation0: {
    boxShadow: 'none',
  },
  elevation1: {
    boxShadow: `0px 0px 1px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation2: {
    boxShadow: `0px 0px 2px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation3: {
    boxShadow: `0px 0px 3px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation4: {
    boxShadow: `0px 0px 4px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation5: {
    boxShadow: `0px 0px 5px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation6: {
    boxShadow: `0px 0px 6px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation7: {
    boxShadow: `0px 0px 7px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation8: {
    boxShadow: `0px 0px 8px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation9: {
    boxShadow: `0px 0px 9px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation10: {
    boxShadow: `0px 0px 10px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation11: {
    boxShadow: `0px 0px 11px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation12: {
    boxShadow: `0px 0px 12px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation13: {
    boxShadow: `0px 0px 13px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation14: {
    boxShadow: `0px 0px 14px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation15: {
    boxShadow: `0px 0px 15px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation16: {
    boxShadow: `0px 0px 16px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation17: {
    boxShadow: `0px 0px 17px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation18: {
    boxShadow: `0px 0px 18px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation19: {
    boxShadow: `0px 0px 19px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation20: {
    boxShadow: `0px 0px 20px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation21: {
    boxShadow: `0px 0px 21px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation22: {
    boxShadow: `0px 0px 22px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation23: {
    boxShadow: `0px 0px 23px ${rgba(colors.grey120, 0.16)}`,
  },
  elevation24: {
    boxShadow: `0px 0px 24px ${rgba(colors.grey120, 0.16)}`,
  },
}))(Paper);
