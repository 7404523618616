import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  searchQueryMatch: {
    color: colors.grey190,
  },
}));
