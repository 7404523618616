import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const ArrowDown = ({
  className,
  color = colors.primary.main,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize }}
      width={13}
      height={8}
      viewBox="0 0 13 8"
      fill="none"
      fontSize="inherit"
    >
      <path
        d="M0.998064 1.49774C0.997299 1.62935 1.02265 1.75981 1.07266 1.88165C1.12267 2.00349 1.19637 2.1143 1.28951 2.20774L6.31444 7.20774C6.50273 7.39399 6.75745 7.49854 7.02295 7.49854C7.28845 7.49854 7.54317 7.39399 7.73146 7.20774L12.7564 2.20774C12.921 2.01644 13.0071 1.77037 12.9973 1.51869C12.9875 1.26701 12.8827 1.02827 12.7037 0.85018C12.5247 0.672085 12.2848 0.567752 12.0319 0.558031C11.7789 0.54831 11.5316 0.633916 11.3394 0.797743L7.02798 5.08774L2.71659 0.797743C2.57662 0.657322 2.39795 0.561351 2.20317 0.521965C2.00839 0.48258 1.80625 0.50155 1.62231 0.576476C1.43836 0.651401 1.28088 0.778918 1.16976 0.942901C1.05865 1.10688 0.998897 1.29997 0.998064 1.49774Z"
        fill={color}
      />
    </SvgIcon>
  );
};
