import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const ArrowRight = ({
  className,
  color = colors.primary.main,
  fontSize = 12,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon className={className} style={{ fontSize }} width="8" height="13" viewBox="0 0 8 13" fill="none">
    <path
      d="M1.49774 12.0019C1.62935 12.0027 1.75981 11.9773 1.88165 11.9273C2.00349 11.8773 2.1143 11.8036 2.20774 11.7105L7.20774 6.68556C7.39399 6.49727 7.49853 6.24255 7.49853 5.97705C7.49853 5.71155 7.39399 5.45683 7.20774 5.26854L2.20774 0.243609C2.01644 0.0789656 1.77037 -0.00706724 1.51869 0.00270236C1.26701 0.012472 1.02827 0.117324 0.85018 0.296306C0.672085 0.475289 0.567752 0.715218 0.558031 0.968149C0.54831 1.22108 0.633916 1.46838 0.797743 1.66064L5.08774 5.97202L0.797742 10.2834C0.657321 10.4234 0.56135 10.602 0.521965 10.7968C0.482579 10.9916 0.501549 11.1937 0.576475 11.3777C0.6514 11.5616 0.778917 11.7191 0.9429 11.8302C1.10688 11.9414 1.29997 12.0011 1.49774 12.0019Z"
      fill={color}
    />
  </SvgIcon>
);
