import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { colors } from 'assets';

export const Row = withStyles({
  root: {
    '&:nth-of-type(even):not(.Mui-selected)': {
      backgroundColor: colors.grey10,
    },

    '&$hover:hover': {
      backgroundColor: colors.primary.superlight,
    },
  },

  hover: {},
})(TableRow);

export const Cell = withStyles({
  root: {
    border: 'none',
    fontSize: 18,
  },

  head: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },

  paddingCheckbox: {
    width: 16,
  },

  stickyHeader: {
    backgroundColor: colors.grey10,
  },
})(TableCell);

export const SortLabel = withStyles({
  root: {
    '&:hover $icon': {
      opacity: 1,
    },
  },
  active: {
    '& $icon': {
      color: colors.primary.main + ' !important',
    },
  },
  icon: {
    color: colors.basic.black,
    opacity: 0.5,
  },
})(TableSortLabel);

export const NewTablePagination = withStyles(theme => ({
  root: {
    border: 'none',
    display: 'flex',
    padding: 0,
  },
  toolbar: {
    flex: 1,
    paddingLeft: 0,
  },
  spacer: {
    flex: 'initial',
  },
  caption: {
    '&:nth-of-type(2)': {
      order: -1,
      marginRight: 20,
      color: colors.grey140,
      fontWeight: 'bold',
    },
    '&:nth-of-type(1)': {
      flex: 1,
      textAlign: 'right',
      color: colors.grey140,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
  },
  input: {
    marginTop: 2,
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 4,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 12,
    },
  },
  select: {
    paddingLeft: 5,
    paddingRight: 18,
    color: colors.grey140,
    fontWeight: 'bold',
    fontSize: 14,
  },
  actions: {
    color: colors.grey140,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))(TablePagination);

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: colors.grey160,
    fontSize: 14,
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  filterSelect: {
    backgroundColor: colors.white,
    borderRadius: '4px!important',
  },
  filterSelectActive: {
    backgroundColor: `${colors.primary.main} !important`,
    color: colors.white,

    '& .MuiSelect-select.MuiInputBase-input': {
      color: colors.white + ' !important',
    },

    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: colors.white,
    },
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: 2,
    marginTop: 3,
    color: colors.functionals.alert,
  },

  filterList: {
    backgroundColor: colors.grey10,
    padding: '8px 16px',
    width: '100%',
    flex: 1,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'initial',
      justifyContent: 'flex-start',
      padding: 'initial',
    },
  },

  filterItem: {
    width: '100%',
    margin: '8px 0',

    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 8px)',
    },

    [theme.breakpoints.up('lg')]: {
      width: 'initial',
      marginRight: 16,
    },
  },

  filterButton: {
    border: `1px solid ${colors.primary.main}`,
    borderRadius: 4,
    padding: '5px 16px',
    fontSize: 18,
    color: colors.primary.main,
    cursor: 'pointer',
    backgroundColor: colors.white,

    '&.active': {
      backgroundColor: colors.primary.main,
      color: colors.white,
    },

    '&:not(.active):hover': {
      backgroundColor: colors.primary.superlight,
    },
  },
}));
