import { memo } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './OptionsList.styles';
import { OptionsListProps } from './OptionsList.types';

export const OptionsList = memo(
  ({ maxItems, options, onOptionSelect, getHighlightedText, onMouseDown }: OptionsListProps) => {
    const styles = useStyles({ maxItems });

    return (
      <div className={styles.optionsContainer}>
        <MenuList className={styles.optionsList}>
          {options.map((option, i) => (
            <MenuItem
              key={i}
              className={styles.optionItem}
              onClick={() => onOptionSelect(option)}
              onMouseDown={onMouseDown}
            >
              <Typography variant="subtitle1" className={styles.optionLabel}>
                {getHighlightedText(option.label)}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </div>
    );
  },
);
