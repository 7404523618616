import Input from '@material-ui/core/Input';
import { fade } from '@material-ui/core/styles/colorManipulator';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { fontWeight } from 'assets';
import { colors } from 'assets/theme/palette';

export const useStyles = () => {
  const style = makeStyles({
    root: {
      '&:not($rootError) ::placeholder': {
        color: fade(colors.grey190, 0.6),
      },
      '& .MuiInput-input': {
        '&:-webkit-autofill': {
          // Fix for input blue backroundColor on autocomplete on chrome.
          '-webkit-box-shadow': '0 0 0 30px white inset',
          '-webkit-text-fill-color': `${colors.secondary.main}`,
        },
      },
      '& .MuiInputLabel-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        fontWeight: fontWeight.bold,
        '&:not(.Mui-error)': {
          color: fade(colors.grey190, 0.6),
        },
      },
      '& .MuiInputLabel-shrink': {
        zIndex: 1,
        fontWeight: fontWeight.normal,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${colors.primary.main}`,
      },
      '& .MuiInput-underline:not(.Mui-disabled):after': {
        borderBottom: `2px solid ${colors.primary.main}`,
      },
    },
    rootDisabled: {
      '& .MuiInputLabel-root': {
        zIndex: 1,
      },
      '&&': {
        background: colors.grey20,
        color: colors.secondary.main,
      },
      '& .MuiInput-input:disabled, .MuiInput-input:disabled::placeholder': {
        '-webkit-text-fill-color': `${colors.secondary.main}` /* 1. sets text fill to current `color` for safari */,
        opacity: '1' /* 2. correct opacity on iOS */,
      },
      '& .Mui-disabled': {
        background: colors.grey20,
        color: colors.secondary.main,
      },
    },
    rootSmall: {
      '& ::placeholder': {
        fontSize: '14px',
      },
      '& .MuiInput-input': {
        padding: '6px',
        fontSize: '14px',
      },
      '& .MuiInputLabel-root': {
        fontSize: '14px',

        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(6px, 24px) scale(1)',
          width: 'calc(100% - 6px)',
        },
      },
    },
    rootMedium: {
      '& ::placeholder': {
        fontSize: '16px',
      },
      '& .MuiInput-input': {
        padding: '8px',
        fontSize: '16px',
      },
      '& .MuiInputLabel-root': {
        fontSize: '16px',
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(8px, 30px) scale(1)',
          width: 'calc(100% - 8px)',
        },
      },
    },
    rootLarge: {
      '& ::placeholder': {
        fontSize: '18px',
      },
      '& .MuiInput-input': {
        padding: '10px',
        fontSize: '18px',
      },
      '& .MuiInputLabel-root': {
        fontSize: '18px',
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(10px, 32px) scale(1)',
          width: 'calc(100% - 10px)',
        },
      },
    },
    rootOutlined: {
      display: 'flex', //fix for height change while input filling on safari
      border: `1px solid ${colors.grey20}`,
      borderRadius: '4px',
      transition: 'border-color 200ms ease 0s, box-shadow 200ms ease 0s ',
      background: colors.basic.white,
      '&:not($rootError):not($rootDisabled):not(:focus-within):hover': {
        borderColor: colors.grey60,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
      },
      '& .MuiInput-underline': {
        '&::before': {
          content: 'none',
        },
        '&::after': {
          content: 'none',
        },
      },
      '& .MuiInput-input': {
        color: colors.secondary.main,
        fontWeight: fontWeight.bold,
      },
      '&:focus-within': {
        borderColor: colors.primary.main,
        boxShadow: `0px 0px 8px ${colors.primary.main}3d`,
      },
      '&$rootSmall': {
        '& .MuiInputLabel-root + .MuiInput-root .MuiInput-input': {
          fontSize: '14px',
          margin: '0 4px',
        },
        '& .MuiInputLabel-root': {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(10px, 6px) scale(0.75)',
          },
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(10px, 16px) scale(1)',
            width: 'calc(100% - 20px)',
          },
        },
      },
      '&$rootMedium': {
        '& .MuiInputLabel-root + .MuiInput-root .MuiInput-input': {
          fontSize: '16px',
          margin: '0 5px',
          paddingTop: '10px',
        },
        '& .MuiInputLabel-root': {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, 8px) scale(0.75)', //scale to 12px fontSize
          },
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 20px) scale(1)',
            width: 'calc(100% - 28px)',
          },
        },
      },
      '&$rootLarge': {
        '& .MuiInputLabel-root + .MuiInput-root .MuiInput-input': {
          fontSize: '18px',
          margin: '0 6px',
          paddingTop: '12px',
        },
        '& .MuiInputLabel-root': {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(18px, 10px) scale(0.75)',
          },
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(18px, 22px) scale(1)',
            width: 'calc(100% - 36px)',
          },
        },
      },
      '& .MuiInputAdornment-root': {
        marginLeft: 8,
        marginRight: 8,
      },
    },
    rootError: {
      borderColor: colors.functionals.alert,
      '& ::placeholder': {
        color: colors.functionals.alert,
      },
    },
    checkAdornment: {
      color: colors.functionals.success,
      marginRight: 16,
      marginBottom: 12,
    },
    calendarAdornment: {
      marginTop: -16,
    },
  });

  return style();
};

export const InputStyled = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& ::placeholder': {
        color: colors.grey100,
        opacity: 1,
        fontWeight: fontWeight.bold,
      },
    },
    input: {
      color: colors.grey180,
      fontWeight: fontWeight.bold,
    },
    multiline: {
      padding: 0,
    },
  }),
)(Input);
