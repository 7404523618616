import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Palette } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const fontWeight: Record<
  string,
  number | 'lighter' | 'normal' | 'bold' | 'inherit' | 'initial' | 'revert' | 'unset' | 'bolder' | undefined
> = {
  light: 'lighter',
  normal: 'normal',
  bold: 'bold',
};
export const NoahFont = 'Noah';
export const RiftFont = 'Rift Soft';
export const BarlowFont = 'Barlow';

export const Noah: CSSProperties['@font-face'] = {
  fontFamily: NoahFont,
};
export const RiftSoft: CSSProperties['@font-face'] = {
  fontFamily: RiftFont,
};
export const Barlow: CSSProperties['@font-face'] = {
  fontFamily: BarlowFont,
};

const defaultTheme = createMuiTheme();

export const typography = (palette: Palette): TypographyOptions => ({
  fontFamily: NoahFont,
  h1: {
    fontFamily: RiftFont,
    lineHeight: '77px',
    fontSize: 64,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  h2: {
    fontFamily: RiftFont,
    lineHeight: '58px',
    fontSize: 48,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  h3: {
    fontFamily: RiftFont,
    lineHeight: '38px',
    fontSize: 32,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  h4: {
    fontFamily: RiftFont,
    lineHeight: '29px',
    fontSize: 24,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  h5: {
    fontFamily: RiftFont,
    lineHeight: '22px',
    fontSize: 18,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  h6: {
    fontFamily: RiftFont,
    lineHeight: '19px',
    fontSize: 16,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  subtitle1: {
    fontFamily: NoahFont,
    fontSize: 16,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  subtitle2: {
    fontFamily: NoahFont,
    fontSize: 14,
    fontWeight: fontWeight.bold,
    color: palette.common.black,
  },
  body1: {
    fontFamily: NoahFont,
    lineHeight: 1.5,
    fontSize: 18,
    fontWeight: fontWeight.normal,
    color: palette.common.black,

    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  body2: {
    fontFamily: NoahFont,
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: fontWeight.normal,
    color: palette.common.black,
  },
  button: {
    fontFamily: RiftFont,
    lineHeight: '25px',
    fontSize: 14,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    color: palette.secondary.main,
  },
  caption: {
    fontFamily: NoahFont,
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: fontWeight.normal,
    color: palette.common.black,
  },
  overline: {
    fontFamily: NoahFont,
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: fontWeight.normal,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    color: palette.common.black,
  },
});
