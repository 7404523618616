import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const MailIcon = ({ className, color = colors.primary.main }: { className?: string; color?: string }) => {
  return (
    <SvgIcon className={className} width="22" height="17" viewBox="0 0 22 17" fill="none" fontSize="inherit">
      <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 2.78355C1 2.02165 1.66667 1.35498 2.42857 1.35498H19.5714C20.3333 1.35498 21 2.02165 21 2.78355V14.2121C21 14.974 20.3333 15.6407 19.5714 15.6407H2.42857C1.66667 15.6407 1 14.974 1 14.2121V2.78355V2.78355Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.619 1.92639L12.9047 7.92639C11.7618 8.78353 10.238 8.78353 9.09517 7.92639L1.28564 1.92639"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
