import { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { applyPhoneMask } from 'utils';

import { Subtitle, useStyles } from './CustomerInfo.styles';
import { CustomerInfoProps } from './CustomerInfo.types';

export const CustomerInfo: FC<CustomerInfoProps> = ({
  id,
  name,
  clientType,
  email,
  phone,
  address,
  referralSource,
  franchisee,
  franchiseLocation,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Grid container spacing={2} direction="column">
        {name && (
          <Grid item>
            <Typography variant="h3" color="secondary" component="p">
              {name}
            </Typography>
            <Typography variant="body1">ID: {id}</Typography>
          </Grid>
        )}
        {clientType && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Client type
            </Subtitle>
            <Typography variant="body1">{clientType}</Typography>
          </Grid>
        )}
        {email && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              E-mail
            </Subtitle>
            <Link href={`mailto:${email}`} variant="body1" color="textSecondary">
              {email}
            </Link>
          </Grid>
        )}
        {phone && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Phone number
            </Subtitle>
            <Link href={`tel:${phone}`} variant="body1" color="textSecondary">
              {applyPhoneMask(phone)}
            </Link>
          </Grid>
        )}
        {address && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Address
            </Subtitle>
            <Typography variant="body1">{address}</Typography>
          </Grid>
        )}
        {referralSource && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Referral source
            </Subtitle>
            <Typography variant="body1">{referralSource}</Typography>
          </Grid>
        )}
        {franchisee && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Franchisee
            </Subtitle>
            <Typography variant="body1">{franchisee}</Typography>
          </Grid>
        )}
        {franchiseLocation && (
          <Grid item>
            <Subtitle variant="subtitle1" color="secondary">
              Franchise location
            </Subtitle>
            <Typography variant="body1">{franchiseLocation}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
