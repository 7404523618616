import { memo, useCallback, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import clsx from 'clsx';

import { Button, NewDropdownMenu } from 'components';

import { useStyles } from './styles';
import { NavSelectProps } from './types';

export const NavSelect = memo(({ value, items, classes }: NavSelectProps) => {
  const styles = useStyles();

  const [ref, setRef] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = useCallback((e: { currentTarget: HTMLButtonElement }) => setRef(e?.currentTarget), []);

  const handleMenuClose = useCallback(() => setRef(null), []);

  const handleMenuItemClick = useCallback((e: React.MouseEvent<Element, MouseEvent>) => handleMenuClose(), []);

  return (
    <>
      <Button onClick={handleMenuOpen}>
        <div className={styles.content}>
          <Typography variant="h6" component="span" className={clsx(classes, classes?.title)}>
            Jump to:
          </Typography>

          {value && (
            <Typography variant="h6" component="span" color="primary">
              {items.find(el => el.name === value)?.name ?? ''}
            </Typography>
          )}

          {ref ? <ArrowDropUp fontSize="small" color="primary" /> : <ArrowDropDown fontSize="small" color="primary" />}
        </div>
      </Button>

      <NewDropdownMenu
        open={!!ref}
        anchorEl={ref}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        menuItems={items}
        onMenuItemClick={handleMenuItemClick}
        onClose={handleMenuClose}
      />
    </>
  );
});
