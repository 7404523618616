import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  attachmentsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  attachmentsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));
