import { Fragment, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './styles';
import { TasksGroupSkeletonProps } from './types';

export const TasksGroupSkeleton = memo(({ renderCard }: TasksGroupSkeletonProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="h4">
        <Skeleton variant="text" width={110} />
      </Typography>

      {[...Array(3)].map((_, index) => (
        <Fragment key={index}>{renderCard()}</Fragment>
      ))}
    </div>
  );
});
