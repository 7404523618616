import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './PricingItem.styles';
import { PricingItemProps } from './PricingItem.types';

export const PricingItem = ({ pricingItem }: PricingItemProps) => {
  const styles = useStyles();

  return (
    <ListItem>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item style={{ flex: '1' }}>
          <Typography variant="subtitle1" className={clsx(styles.listTypography, styles.title)}>
            {pricingItem.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={clsx(styles.listTypography, styles.price)}>${pricingItem.price.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};
