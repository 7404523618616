import { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { useResizeDetector } from 'react-resize-detector';
import ReactSelect, {
  ActionMeta,
  components,
  ControlProps,
  OptionProps,
  FormatOptionLabelMeta,
  ValueType,
  MultiValueProps,
  IndicatorProps,
  ValueContainerProps,
  SingleValueProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import { CloseIcon, colors } from '../../assets';
import { UserIdentifier } from '../userIdentifier/UserIdentifier';

import { selectStyles, useStyles } from './NewSelect.styles';
import { NewSelectProps, SelectOptionType } from './NewSelect.types';

function formatOptionLabel<T extends unknown, S extends boolean = false>(
  { label }: SelectOptionType<unknown>,
  { inputValue }: FormatOptionLabelMeta<SelectOptionType<T>, S>,
) {
  if (!inputValue) {
    return label;
  }

  return (
    <Highlighter
      autoEscape // it turns off treating 'searchWords' as regex (which can results in library errors and app crash)
      highlightStyle={{ color: colors.secondary.main }}
      searchWords={[inputValue]}
      textToHighlight={label}
      highlightTag="span"
    />
  );
}

const Control = <T extends unknown, S extends boolean = false>({
  children,
  isValid,
  classes: selectClasses,
  ...props
}: ControlProps<SelectOptionType<T>, S> & {
  isValid: boolean;
  classes: NewSelectProps<T, S>['classes'];
}) => {
  const shouldFloatLabel =
    props.selectProps.label &&
    (props.hasValue || props.selectProps.inputValue || props.isFocused || props.selectProps.menuIsOpen);
  const classes = useStyles({ isMulti: props.isMulti, hasLabel: props.selectProps.label });
  const placeholder = props.selectProps.placeholder || props.selectProps.label || 'Select...';

  const hideLabel = !shouldFloatLabel && props.selectProps.inputValue;

  return (
    <>
      <p
        className={clsx(
          classes.label,
          shouldFloatLabel && classes.floatingLabel,
          hideLabel && classes.hideLabel,
          !isValid && classes.errorLabel,
          selectClasses?.label,
        )}
      >
        {props.hasValue ? props.selectProps.label : placeholder}
      </p>
      <components.Control {...props} className={selectClasses?.control}>
        {children}
      </components.Control>
    </>
  );
};

export const NewSelect = <T extends unknown, S extends boolean = false>({
  onCreateOption,
  isCreatable,
  isMulti,
  isUserName,
  onChange,
  error,
  isShowDropdownIndicator,
  forFilter,
  first50Only = true,
  virtualize = true,
  startAdornment,
  classes: selectClasses,
  returnArray,
  ...selectProps
}: NewSelectProps<T, S>) => {
  const classes = useStyles({});

  const { width: startAdornmentWidth, ref: startAdornmentRef } = useResizeDetector();

  const handleChange = useCallback(
    (option: ValueType<SelectOptionType<T>, S>, meta: ActionMeta<SelectOptionType<T>>) => {
      if (onChange) {
        if (returnArray && !Array.isArray(option)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange([option], meta);
        } else {
          onChange(option, meta);
        }
      }
    },
    [onChange, returnArray],
  );

  const GroupHeading = useCallback(
    props => (
      <components.GroupHeading {...props}>
        <Typography variant="h6" component="span" className={classes.categoryName}>
          {props.children}
        </Typography>
      </components.GroupHeading>
    ),
    [classes.categoryName],
  );

  const customOption = useCallback(
    ({ children, ...props }: OptionProps<SelectOptionType<T>, S>) => {
      const { data } = props;

      return (
        <components.Option {...props}>
          {isUserName ? <UserIdentifier name={data.label} imageUrl={data.userImgUrl} size="small" /> : children}
        </components.Option>
      );
    },
    [isUserName],
  );

  const CustomControl = useCallback(
    ({ children, ...rest }: ControlProps<SelectOptionType<T>, S>) => (
      <Control {...rest} isValid={!error} classes={selectClasses}>
        {children}
      </Control>
    ),
    [error],
  );

  const CustomSingleValue = useCallback(({ children, ...rest }: SingleValueProps<SelectOptionType<T>>) => {
    return (
      <components.SingleValue {...rest} className={selectClasses?.singleValue}>
        {children}
      </components.SingleValue>
    );
  }, []);

  const CustomValueContainer = useCallback(
    ({ children, ...rest }: ValueContainerProps<SelectOptionType<T>, S>) => {
      const value = rest.getValue();

      const label = selectProps.name + (value?.length ? `(${value?.length})` : '');

      return (
        <components.ValueContainer {...rest} className={selectClasses?.valueContainer}>
          {forFilter && (
            <div style={{ marginLeft: 8 }}>
              <Typography color="primary">{label}</Typography>
            </div>
          )}

          {startAdornment && <div ref={startAdornmentRef}>{startAdornment}</div>}

          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>{children}</div>
        </components.ValueContainer>
      );
    },
    [forFilter, selectProps.name, startAdornment, startAdornmentRef],
  );

  const CustomClearIndicator = useCallback(
    props => (forFilter ? <></> : <components.ClearIndicator {...props} />),
    [forFilter],
  );

  const CustomMultiValue = useCallback(
    ({ ...props }: MultiValueProps<SelectOptionType<T>>) => {
      const { data } = props;

      return forFilter ? (
        <></>
      ) : (
        <components.MultiValue {...props}>
          {isUserName ? (
            <UserIdentifier
              name={data.label}
              imageUrl={data.userImgUrl}
              size="large"
              className={classes.multiItemUserIdentifier}
            />
          ) : (
            <div style={{ color: colors.grey160 }}>
              <Typography variant="subtitle1" color="inherit" className={classes.multiItemText}>
                {data.label}
              </Typography>
            </div>
          )}
        </components.MultiValue>
      );
    },
    [forFilter, isUserName],
  );

  const CustomMultiValueRemove = useCallback(({ ...props }: MultiValueProps<SelectOptionType<T>>) => {
    return (
      <components.MultiValueRemove {...props}>
        <IconButton className={classes.multiItemRemoveIconContainer}>
          <CloseIcon fontSize={12} color={colors.primary.main} />
        </IconButton>
      </components.MultiValueRemove>
    );
  }, []);

  const CustomDropdownIndicator = useCallback(
    ({ ...props }: IndicatorProps<SelectOptionType<T>, S>) => {
      return isShowDropdownIndicator === false ? null : (
        <components.DropdownIndicator {...props}>
          {forFilter ? (
            <div style={{ fontSize: '1.125rem', display: 'flex' }}>
              <ArrowDropDown color="primary" fontSize="inherit" />
            </div>
          ) : (
            <components.DownChevron />
          )}
        </components.DropdownIndicator>
      );
    },
    [isShowDropdownIndicator, forFilter],
  );

  const BasicMenuList = useCallback(({ children, ...props }) => {
    return <components.MenuList {...props}>{children}</components.MenuList>;
  }, []);

  const CustomMenuList = useCallback(
    ({ children, ...props }) => {
      const cache = new CellMeasurerCache();

      const rowRenderer: ListRowRenderer = ({ key, index, style, ...restProps }) => {
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={restProps.parent}>
            {({ measure }) => (
              <div
                onLoad={measure}
                key={key}
                style={style}
                {...restProps}
                className={clsx(classes.optionWrapper, props.options[index]?.options?.length && 'groupContainer')}
              >
                {children[index]}
              </div>
            )}
          </CellMeasurer>
        );
      };

      const getRowHeight = (index: { index: number }) => {
        const i = index.index;

        if (
          Array.isArray(children) &&
          Array.isArray(children[i]?.props?.children) &&
          children[i]?.props?.children?.length
        ) {
          return 40 + children[i].props.children.length * 40;
        }

        return 40;
      };

      const itemsTotalHeight =
        40 * (children.length || 0) +
        40 *
          (Array.isArray(children)
            ? children
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((child: any) => (Array.isArray(child?.props?.children) ? child?.props?.children.length : 0))
                .reduce((partialSum: number, a: number) => partialSum + a, 0)
            : 0);

      return (
        <List
          style={{ maxWidth: '100%' }}
          width={1200}
          height={itemsTotalHeight < props.maxHeight ? itemsTotalHeight : props.maxHeight}
          rowHeight={getRowHeight}
          rowCount={children.length}
          rowRenderer={rowRenderer}
          className={classes.virtualOptionsList}
        />
      );
    },
    [classes.optionWrapper, classes.virtualOptionsList],
  );

  return (
    <>
      {isCreatable ? (
        <CreatableSelect<SelectOptionType<T>, S>
          {...selectProps}
          onChange={handleChange}
          className={clsx(classes.selectContainer, selectClasses?.selectContainer)}
          placeholder={selectProps.placeholder || ''}
          styles={selectStyles(
            !error,
            !!selectProps.label,
            selectProps.value?.length !== 0,
            !!isMulti,
            virtualize,
            forFilter,
            startAdornmentWidth,
          )}
          onCreateOption={onCreateOption}
          captureMenuScroll={false}
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          getOptionValue={option => option.value as any} // There is typing problem in React-select
          getOptionLabel={option => option.label}
          isMulti={isMulti}
          formatOptionLabel={formatOptionLabel}
          components={{
            ClearIndicator: CustomClearIndicator,
            Control: CustomControl,
            DropdownIndicator: CustomDropdownIndicator,
            MenuList: virtualize ? CustomMenuList : BasicMenuList,
            MultiValue: CustomMultiValue,
            MultiValueRemove: CustomMultiValueRemove,
            Option: customOption,
            Placeholder: () => null,
            ValueContainer: CustomValueContainer,
            SingleValue: CustomSingleValue,
            GroupHeading,
          }}
        />
      ) : (
        <ReactSelect
          {...selectProps}
          onChange={handleChange}
          className={clsx(classes.selectContainer, selectClasses?.selectContainer)}
          placeholder={selectProps.placeholder || ''}
          styles={selectStyles(
            !error,
            !!selectProps.label,
            selectProps.value?.length !== 0,
            !!isMulti,
            virtualize,
            forFilter,
            startAdornmentWidth,
          )}
          isMulti={isMulti}
          captureMenuScroll={false}
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          getOptionValue={option => option.value as any} // There is typing problem in React-select
          getOptionLabel={option => option.label}
          formatOptionLabel={formatOptionLabel}
          components={{
            ClearIndicator: CustomClearIndicator,
            Control: CustomControl,
            DropdownIndicator: CustomDropdownIndicator,
            MenuList: virtualize ? CustomMenuList : BasicMenuList,
            MultiValue: CustomMultiValue,
            MultiValueRemove: CustomMultiValueRemove,
            Option: customOption,
            Placeholder: () => null,
            ValueContainer: CustomValueContainer,
            SingleValue: CustomSingleValue,
            GroupHeading,
          }}
        />
      )}
    </>
  );
};
