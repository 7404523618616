import makeStyles from '@material-ui/core/styles/makeStyles';

import { DashedSeparatorProps } from './DashedSeparator.types';

export const useStyles = makeStyles(theme => ({
  dashed: {
    height: ({ dashedItemProps: { height } }: DashedSeparatorProps) => height,
    backgroundImage: ({ dashedItemProps: { color } }: DashedSeparatorProps) =>
      `linear-gradient(to right, ${color} 50%, rgba(255,255,255,0) 0%)`,
    backgroundSize: ({ dashedItemProps: { height, width } }: DashedSeparatorProps) => `${width * 2}px ${height}px`,
  },
}));
