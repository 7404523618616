import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from 'assets/theme/palette';

export const PauseIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 40,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon style={{ fontSize }} width="40" height="40" viewBox="0 0 40 40" fill="none" fontSize="inherit">
      <path
        d="M40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20Z"
        fill={color}
      />
      <rect x="15" y="14" width="3" height="12" rx="1" fill="white" />
      <rect x="22" y="14" width="3" height="12" rx="1" fill="white" />
    </SvgIcon>
  );
};
