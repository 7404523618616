import { FC, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';

import { Paper } from '../paper/Paper';

import { useStyles } from './ProgressBar.styles';

export const ProgressBarSkeleton: FC = memo(() => {
  const styles = useStyles({});

  return (
    <Paper variant="outlined">
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Typography variant="h6" component="span" className={styles.stepCount}>
            <Skeleton variant="text" animation="wave" width={100} />
          </Typography>

          <div className={styles.titleRow}>
            <Typography variant="h4" className={styles.title}>
              <Skeleton variant="text" animation="wave" width={160} />
            </Typography>

            <Typography variant="h6" component="span" className={styles.nextStep}>
              <Skeleton variant="text" animation="wave" width={80} />
            </Typography>
          </div>

          <Skeleton variant="rect" animation="wave" width="100%" height={5} />
        </div>

        <div className={styles.actions}>
          <div className={styles.actionItem}>
            <Skeleton variant="rect" animation="wave" width="100%" height={33} />
          </div>

          <div className={styles.actionItem}>
            <Skeleton variant="rect" animation="wave" width="100%" height={33} />
          </div>
        </div>
      </div>
    </Paper>
  );
});
