import List from '@material-ui/core/List';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import { colors } from 'assets';

export const ListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 0,
      '& .MuiListItem-root:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
  }),
)(List);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryContainer: {
      paddingTop: '24px',
      '&:first-child': {
        paddingTop: 0,
      },
    },
    noPricingContainer: {
      padding: '20px 0 12px',
      '& .MuiTypography-root': {
        color: colors.functionals.alert,
      },
    },
  }),
);
