import { memo, useCallback, useState, ChangeEvent, useMemo } from 'react';

import Typography from '@material-ui/core/Typography';

import { zeroPad } from 'utils';

import { InputStyled, useStyles } from './TimeInput.styles';
import { TimeInputProps } from './TimeInput.types';

export const TimeInput = memo(({ value, disabled, error, onChange }: TimeInputProps) => {
  const styles = useStyles();

  const [valueForDisplay, setValueForDisplay] = useState({ h: zeroPad(value.h), min: zeroPad(value.min) });

  const hoursDisabled = useMemo(() => {
    if (typeof disabled === 'boolean') {
      return disabled;
    }

    return !!disabled?.h;
  }, [disabled]);

  const minutesDisabled = useMemo(() => {
    if (typeof disabled === 'boolean') {
      return disabled;
    }

    return !!disabled?.min;
  }, [disabled]);

  const handleOnChangeHours = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      setValueForDisplay(prev => ({
        ...prev,
        h: inputValue,
      }));

      if (inputValue) {
        onChange({
          ...value,
          h: inputValue ? Number(inputValue) : 0,
        });
      }
    },
    [value, onChange],
  );

  const handleOnChangeMinutes = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      const newValue = inputValue ? String(Number(inputValue) > 59 ? 59 : inputValue) : inputValue;

      setValueForDisplay(prev => ({
        ...prev,
        min: newValue,
      }));

      if (inputValue) {
        onChange({
          ...value,
          min: newValue ? Number(newValue) : 0,
        });
      }
    },
    [value, onChange],
  );

  const handleOnBlur = useCallback((type: 'h' | 'min') => {
    if (type === 'h') {
      setValueForDisplay(prev => ({
        ...prev,
        h: zeroPad(Number(prev.h || 0)),
      }));
    } else if (type === 'min') {
      setValueForDisplay(prev => ({
        ...prev,
        min: zeroPad(Number(prev.min || 0)),
      }));
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.inputSection}>
        <InputStyled
          value={valueForDisplay.h}
          size="medium"
          variant="outlined"
          mask="positive-integer"
          color="secondary"
          disabled={hoursDisabled}
          error={error}
          onChange={handleOnChangeHours}
          onBlur={() => handleOnBlur('h')}
        />
        <Typography variant="h5" className={styles.label}>
          H
        </Typography>
      </div>
      <div className={styles.inputSection}>
        <InputStyled
          value={valueForDisplay.min}
          size="medium"
          variant="outlined"
          mask="positive-integer"
          color="secondary"
          disabled={minutesDisabled}
          error={error}
          inputProps={{ maxLength: 2 }}
          onChange={handleOnChangeMinutes}
          onBlur={() => handleOnBlur('min')}
        />
        <Typography variant="h5" className={styles.label}>
          MIN
        </Typography>
      </div>
    </div>
  );
});
