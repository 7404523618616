import { memo } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';

import { EditableCollapseProps } from './EditableCollapse.types';

export const EditableCollapse = memo(
  ({ isEditing, displayContent, editContent, unmountOnExit, onClickAway = () => {} }: EditableCollapseProps) => {
    return (
      <div>
        <Collapse in={!isEditing} unmountOnExit={unmountOnExit?.displayContent}>
          {displayContent}
        </Collapse>

        <Collapse in={isEditing} unmountOnExit={unmountOnExit?.editContent}>
          <ClickAwayListener onClickAway={onClickAway}>{editContent}</ClickAwayListener>
        </Collapse>
      </div>
    );
  },
);
