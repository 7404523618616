import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    backgroundColor: colors.basic.white,
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    gap: 16,

    '&.disabled': {
      backgroundColor: colors.grey20,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
    columnGap: 8,
  },
  title: {
    color: colors.grey120,
  },
  value: {
    wordBreak: 'break-word',
  },
}));
