import { memo, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { Paper, StatusBadge } from 'components';
import { USADateFormat, USATimeFormatAmPm } from 'utils';

import { useStyles } from './styles';
import { UpdateClaimButtonProps } from './types';

export const UpdateClaimButton = memo(({ dueDate, dueTime, onClick }: UpdateClaimButtonProps) => {
  const styles = useStyles();

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      onClick();
    },
    [onClick],
  );

  return (
    <Paper variant="outlined" className={styles.root} onClick={handleClick}>
      <div className={styles.content}>
        <div className={styles.titleSection}>
          <Typography variant="subtitle1" style={{ fontSize: 18 }} component="span">
            You must update by {moment(dueTime).format(USATimeFormatAmPm)}{' '}
            {moment(dueDate, USADateFormat).format(USADateFormat)}
          </Typography>
        </div>

        <div className={styles.statusSection}>
          <StatusBadge text="Awaiting Reply" type="error" size="small" />
        </div>

        <div className={styles.descriptionSection}>
          <Typography component="span">You can use the comment section to update the claim.</Typography>
        </div>
      </div>

      <div className={styles.updateButton}>
        <Typography variant="h6" color="inherit" component="span">
          Update
        </Typography>
      </div>
    </Paper>
  );
});
