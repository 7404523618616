import { ReactNode } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

const theme = createMuiTheme({
  palette,
  typography: (palette: Palette) => typography(palette),
  overrides,
});

export const CollegeHunksTheme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
