import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

export const useStyles = () => {
  const style = makeStyles(
    createStyles({
      wrapper: {
        maxWidth: 384,
        width: '90%',
        padding: 16,
      },
    }),
  );

  return style();
};

export const Subtitle = withStyles(() =>
  createStyles({
    root: {
      lineHeight: 1.25,
      fontSize: 16,
    },
  }),
)(Typography);
