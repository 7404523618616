import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Portal from '@material-ui/core/Portal';

import { Input } from 'components/input/Input';
import { InputProps } from 'components/input/Input.types';
import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';
import { regExpSpecialSymbols } from 'utils';

import { useStyles } from './InputAutocomplete.styles';
import { InputAutocompleteProps } from './InputAutocomplete.types';
import { OptionsList } from './optionsList/OptionsList';

export const InputAutocomplete = forwardRef<HTMLInputElement, InputProps & InputAutocompleteProps>(
  ({ options, onOptionSelect, maxItems = 3, menuPortalTarget, ...props }, ref) => {
    const styles = useStyles();

    const [windowWidth, windowHeight] = useWindowSize();

    const [menuTtop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [menuWidth, setMenuWidth] = useState(0);

    const innerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (innerRef.current) {
        setMenuTop(innerRef.current.getBoundingClientRect().top + innerRef.current.getBoundingClientRect().height);
        setMenuLeft(innerRef.current.getBoundingClientRect().left);
        setMenuWidth(innerRef.current.getBoundingClientRect().width);
      }
    }, [innerRef, windowWidth, windowHeight]);

    const [isInputFocus, setIsInputFocus] = useState(false);
    const isBlockedOnBlur = useRef(false);

    const filteredOptions = useMemo(
      () => options.filter(option => option.label.toLowerCase().includes(String(props.value).toLowerCase())),
      [props.value, options],
    );

    const isShowOptions = useMemo(
      () => (filteredOptions.length && isInputFocus && props.value ? true : false),
      [filteredOptions.length, props.value, isInputFocus],
    );

    const getHighlightedText = useCallback(
      (text: string) => {
        const regExpValue = (props.value as string).replace(regExpSpecialSymbols, '\\$&');
        const parts = text.split(new RegExp(`(${regExpValue})`, 'gi'));
        const highlightIndex = parts.findIndex(part => part.toLowerCase() === String(props.value).toLowerCase());

        return (
          <span>
            {parts.map((part, i) => (
              <span key={i} className={i === highlightIndex ? styles.searchQueryMatch : undefined}>
                {part}
              </span>
            ))}
          </span>
        );
      },
      [props.value, styles.searchQueryMatch],
    );

    return (
      <div className={styles.root}>
        <div ref={innerRef}>
          <Input
            {...props}
            ref={ref}
            onFocus={e => {
              setIsInputFocus(true);
              if (props.onFocus) {
                props.onFocus(e);
              }
            }}
            onBlur={e => {
              !isBlockedOnBlur.current && setIsInputFocus(false);
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
          />
        </div>
        {isShowOptions &&
          (menuPortalTarget ? (
            <Portal container={menuPortalTarget}>
              <div
                style={{
                  position: 'absolute',
                  top: menuTtop,
                  left: menuLeft,
                  width: menuWidth,
                }}
              >
                <OptionsList
                  options={filteredOptions}
                  maxItems={maxItems}
                  getHighlightedText={getHighlightedText}
                  onMouseDown={() => {
                    isBlockedOnBlur.current = true;
                  }}
                  onOptionSelect={option => {
                    onOptionSelect(option);
                    isBlockedOnBlur.current = false;
                    setIsInputFocus(false);
                  }}
                />
              </div>
            </Portal>
          ) : (
            <OptionsList
              options={filteredOptions}
              maxItems={maxItems}
              getHighlightedText={getHighlightedText}
              onMouseDown={() => {
                isBlockedOnBlur.current = true;
              }}
              onOptionSelect={option => {
                onOptionSelect(option);
                isBlockedOnBlur.current = false;
                setIsInputFocus(false);
              }}
            />
          ))}
      </div>
    );
  },
);
