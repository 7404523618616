import { Fragment, memo } from 'react';

import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { TasksGroupProps } from './types';

export const TasksGroup = memo(({ title, tasks, renderCard }: TasksGroupProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="h4">{title}</Typography>

      {tasks.map(task => (
        <Fragment key={task.id}>{renderCard(task)}</Fragment>
      ))}
    </div>
  );
});
