import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors } from 'assets/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      [theme.breakpoints.up('sm')]: {
        pointerEvents: 'none',
      },
      '& .menuItem:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
    list: {
      pointerEvents: 'auto',
    },
    header: {
      padding: '16px 20px',
      '& .MuiTypography-root': {
        color: colors.grey100,
      },
    },
    arrowIcon: {
      transform: 'rotate(180deg)',
      marginRight: '11px',
    },
  }),
);
