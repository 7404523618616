import Popover from '@material-ui/core/Popover';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { rgba } from 'polished';

import { colors } from '../../assets';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
    paper: {
      pointerEvents: 'auto',
    },
  }),
);

export const PopoverStyled = withStyles(() => ({
  paper: {
    '&.MuiPopover-paper0': {
      boxShadow: 'none',
    },
    '&.MuiPopover-paper1': {
      boxShadow: `0px 0px 1px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper2': {
      boxShadow: `0px 0px 2px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper3': {
      boxShadow: `0px 0px 3px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper4': {
      boxShadow: `0px 0px 4px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper5': {
      boxShadow: `0px 0px 5px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper6': {
      boxShadow: `0px 0px 6px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper7': {
      boxShadow: `0px 0px 7px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper8': {
      boxShadow: `0px 0px 8px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper9': {
      boxShadow: `0px 0px 9px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper10': {
      boxShadow: `0px 0px 10px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper11': {
      boxShadow: `0px 0px 11px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper12': {
      boxShadow: `0px 0px 12px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper13': {
      boxShadow: `0px 0px 13px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper14': {
      boxShadow: `0px 0px 14px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper15': {
      boxShadow: `0px 0px 15px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper16': {
      boxShadow: `0px 0px 16px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper17': {
      boxShadow: `0px 0px 17px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper18': {
      boxShadow: `0px 0px 18px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper19': {
      boxShadow: `0px 0px 19px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper20': {
      boxShadow: `0px 0px 20px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper21': {
      boxShadow: `0px 0px 21px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper22': {
      boxShadow: `0px 0px 22px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper23': {
      boxShadow: `0px 0px 23px ${rgba(colors.grey120, 0.16)}`,
    },
    '&.MuiPopover-paper24': {
      boxShadow: `0px 0px 24px ${rgba(colors.grey120, 0.16)}`,
    },
  },
}))(Popover);
