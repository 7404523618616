import { useCallback, useMemo, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';

import { TaskRelationLead } from 'entities/tasks';

import { LeadForm, LeadFormValues } from './features';
import { TaskViewLeadsProps } from './model';
import { ViewSection } from './ui';

export const TaskViewLeads = ({ leads, categories, lostReasons, setValue, watch }: TaskViewLeadsProps) => {
  const [initialLeads] = useState(leads);
  const [visibility, setVisibility] = useState(false);

  const relations = watch('relations');

  const [firstLead, ...restLeads] = useMemo(() => leads || [], [leads]);

  const toggleList = useCallback(() => setVisibility(!visibility), [visibility]);

  const action = useMemo(
    () =>
      restLeads.length
        ? {
            title: visibility ? 'View only primary' : 'View all',
            onClick: toggleList,
          }
        : undefined,
    [restLeads.length, toggleList, visibility],
  );

  const handleSubmit = useCallback(
    (lead: TaskRelationLead, data: LeadFormValues) => {
      const relationIndex = relations.findIndex(item => item.lead?.id === lead.id);
      const relation = relations[relationIndex];
      const initialLead = initialLeads.find(ld => ld.id === lead.id);

      if (!relation || !initialLead) {
        return;
      }

      setValue(
        `relations.${relationIndex}`,
        {
          ...relation,
          lead: {
            ...initialLead,
            category: {
              ...initialLead.category,
              id: data.category,
            },
            targetDate: data.targetDate,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            noSaleReason: data.reason
              ? {
                  id: data.reason.value,
                  name: data.reason.label,
                }
              : initialLead.noSaleReason,
            estimatedValue: {
              low: Number(data.lowEstimate),
              high: Number(data.highEstimate),
            },
          },
        },
        { shouldDirty: true },
      );
    },
    [initialLeads],
  );

  return (
    <ViewSection title="Lead information" button={action}>
      <LeadForm
        lead={firstLead}
        categories={categories}
        lostReasons={lostReasons}
        separator={false}
        onSubmit={data => handleSubmit(firstLead, data)}
      />

      <Collapse in={visibility}>
        {restLeads.map(lead => (
          <LeadForm
            key={lead.id}
            lead={lead}
            categories={categories}
            lostReasons={lostReasons}
            onSubmit={data => handleSubmit(lead, data)}
          />
        ))}
      </Collapse>
    </ViewSection>
  );
};
