import { memo } from 'react';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { AlersItemProps } from './alertsItem.types';

export const AlertsItem = memo(({ alert }: AlersItemProps) => (
  <ListItem>
    <div>
      <Typography variant="subtitle1">{alert}</Typography>
    </div>
  </ListItem>
));
