import { toBoolean } from "shared/utils";

/**
 * Getting env variable
 * @throwable
 */
const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || "";
};

/** API entrypoint */
export const API_URL = getEnvVar("REACT_APP_API_URL");

/** API training entrypoint */
export const TRAINING_API_URL = getEnvVar("REACT_APP_TRAINING_API_URL");

/** App model */
export const NODE_ENV = getEnvVar("NODE_ENV");

/** Development model */
export const isDevEnv = NODE_ENV === "development";

/** Production model */
export const isProdEnv = NODE_ENV === "production";

/** Hunkware link */
export const hunkwareLink = getEnvVar("REACT_APP_DOMAIN_URL");

/** Global login page link */
export const hunkwareLoginLink = `${getEnvVar(
  "REACT_APP_DOMAIN_URL",
)}/hunkware/login?redirect=%2Fhunkware%2Fdashboard-performance`;

/** Square application ID */
export const squareApplicationId = getEnvVar("REACT_APP_SQUARE_APP_ID");

/** Square application Android tender types */
export const squareApplicationAndroidTenderTypes = getEnvVar(
  "REACT_APP_SQUARE_APP_ANDROID_TENDER_TYPES",
);

/** Square application IOs tender types */
export const squareApplicationIOsTenderTypes = getEnvVar(
  "REACT_APP_SQUARE_APP_IOS_TENDER_TYPES",
).split(" ");

/** Square force production */
export const squareForceProduction = toBoolean(
  getEnvVar("REACT_APP_SQUARE_FORCE_PRODUCTION") ?? "",
);

//** Is HunkPay Enabled  */
export const isHunkPayEnabled = toBoolean(
  getEnvVar("REACT_APP_HUNKPAY_ENABLED") ?? "",
);

//** Is Digital Paperwork Enabled  */
export const isDigitalPaperworkEnabled = toBoolean(
  getEnvVar("REACT_APP_DIGITAL_PAPERWORK_ENABLED") ?? "",
);

/** Maximum attachment file size KB */
export const attachmentMaxSize = Number.parseInt(
  getEnvVar("REACT_APP_ATTACHMENT_MAX_SIZE"),
);

/** Split.io Auth Key **/
export const splitAuthKey = getEnvVar("REACT_APP_SPLIT_AUTH_KEY");

/** Split.io Key **/
export const splitKey = getEnvVar("REACT_APP_SPLIT_KEY");
