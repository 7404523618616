import { FC, memo, useMemo } from 'react';

import FormControl from '@material-ui/core/FormControl';
import { useController } from 'react-hook-form';

import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { TimeInput } from 'components/timeInput/TimeInput';

import { FormItem } from './FormItems.types';

export const FormTimeInput: FC<FormItem> = memo(({ control, name }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
  });

  const helperText = useMemo(() => {
    if (error) {
      return error.message;
    }

    return '';
  }, [error]);

  return (
    <FormControl>
      <TimeInput {...field} error={!!error} />

      {helperText && <HelperTxt>{helperText}</HelperTxt>}
    </FormControl>
  );
});
