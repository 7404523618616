import SvgIcon from '@material-ui/core/SvgIcon';

import { colors } from '../theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const DownloadIcon = ({ className, fontSize = 24, color = colors.primary.main }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.15479 9.66667L12.1992 15.7111L18.2437 9.66667H14.8659V3.71111C14.8659 3.35556 14.5992 3 14.1548 3H10.1548C9.79923 3.08889 9.53256 3.35556 9.53256 3.71111V9.75556H6.15479V9.66667Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1543 17.0443V19.711C22.1543 20.4221 21.5321 21.0443 20.821 21.0443H3.48763C2.77652 21.0443 2.1543 20.4221 2.1543 19.711V17.0443"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
