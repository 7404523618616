import { memo, useMemo } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { TaskCardDesktopSkelton } from './desktop';
import { TaskCardMobileSkeleton } from './mobile';
import { TaskCardProps } from './types';

export const UserTaskCardSkeleton = memo(({ isMobile: isMobileProp }: Pick<TaskCardProps, 'isMobile'>) => {
  const theme = useTheme();
  const isMobileTheme = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const isMobile = useMemo(() => isMobileProp ?? isMobileTheme, [isMobileProp, isMobileTheme]);

  const View = useMemo(() => (isMobile ? TaskCardMobileSkeleton : TaskCardDesktopSkelton), [isMobile]);

  return <View />;
});
