import { useCallback, useEffect, useState, useMemo } from 'react';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { CloseIcon, colors } from 'assets';
import { AttachmentCard, Checkbox, UploadAttachment } from 'components';
import { downloadFile, getFilenameFromUrl, getFlooredFixed, viewFile } from 'utils';

import { TaskViewAttachmentsProps } from './model';
import { ViewSection } from './ui';

export const TaskViewAttachments = ({
  loading,
  submiting,
  isDone,
  user,
  settings,
  watch,
  trigger,
  setValue,
  setError,
  clearErrors,
}: TaskViewAttachmentsProps) => {
  const [isShowAttachmentAdding, setIsShowAttachmentAdding] = useState(false);
  const [uploadAttachment, setUploadAttachment] = useState<File | null>(null);
  const [uploadAttachmentDescription, setUploadAttachmentDescription] = useState('');
  const [isViewableByCustomer, setIsViewableByCustomer] = useState(false);

  const attachments = watch('attachments');

  const hasError = useMemo(
    () => uploadAttachment === null && (uploadAttachmentDescription !== '' || isViewableByCustomer === true),
    [uploadAttachment, uploadAttachmentDescription, isViewableByCustomer],
  );

  const openAttachmentForm = useCallback(() => setIsShowAttachmentAdding(true), []);

  const closeAttachmentForm = useCallback(() => setIsShowAttachmentAdding(false), []);

  const clearAttachmentForm = useCallback(() => {
    setIsViewableByCustomer(false);
    setUploadAttachmentDescription('');
    setUploadAttachment(null);
  }, []);

  const removeAttachment = useCallback(
    (id: number) =>
      setValue(
        'attachments',
        attachments.filter(attachment => attachment.id !== id),
        { shouldDirty: true },
      ),
    [attachments, setValue],
  );

  useEffect(() => {
    if (uploadAttachment && user) {
      setValue(
        'attachments',
        [
          ...attachments,
          {
            id: new Date().getTime(),
            url: URL.createObjectURL(uploadAttachment),
            description: uploadAttachmentDescription,
            canBeDeleted: true,
            created: {
              datetime: '',
              name: `${user.firstName} ${user.lastName}`,
              id: user.id,
              imageUrl: user.imageApproved.url,
            },
            isDeleted: false,
            isPublic: isViewableByCustomer,
            file: uploadAttachment,
          },
        ],
        { shouldDirty: true },
      );
      closeAttachmentForm();
      clearAttachmentForm();
    }
  }, [uploadAttachment]);

  useEffect(() => {
    if (hasError) {
      setError('attachments', { type: 'custom' });
    } else {
      clearErrors('attachments');
      trigger('attachments');
    }
  }, [hasError]);

  useEffect(() => {
    trigger('attachments');
  }, [attachments]);

  useEffect(() => {
    if (submiting) {
      closeAttachmentForm();
      clearAttachmentForm();
    }
  }, [submiting]);

  return (
    <ViewSection
      title="Attachments"
      button={{
        title: isShowAttachmentAdding ? 'Close attachments' : 'Add attachments',
        onClick: isShowAttachmentAdding ? closeAttachmentForm : openAttachmentForm,
        disabled: loading || isDone,
      }}
    >
      <Collapse in={isShowAttachmentAdding}>
        <div
          style={{
            marginBottom: 16,
            padding: '24px 16px',
            backgroundColor: colors.grey10,
          }}
        >
          <div
            style={{
              marginRight: 8,
              marginBottom: 16,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ margin: -12 }}>
              <IconButton onClick={clearAttachmentForm} disabled={loading}>
                <CloseIcon fontSize={12} color={colors.primary.main} />
              </IconButton>
            </div>
          </div>

          <div style={{ marginBottom: 16, width: 'fit-content' }}>
            <Checkbox
              checked={isViewableByCustomer}
              label="Viewable by customer"
              variant="outline"
              disabled={loading}
              className="checkbox"
              onChange={value => setIsViewableByCustomer(value)}
            />
          </div>

          <UploadAttachment
            description={uploadAttachmentDescription}
            changeDescription={setUploadAttachmentDescription}
            selectedFile={uploadAttachment}
            changeSelectedFile={setUploadAttachment}
            required={true}
            hasError={hasError}
          />
        </div>
      </Collapse>

      {attachments.length ? (
        attachments.map((attachment, i) => (
          <div key={attachment.id} style={{ marginTop: i !== 0 ? 2 : 0 }}>
            <div style={{ opacity: attachment.created.datetime ? 1 : 0.6 }}>
              <AttachmentCard
                fileName={attachment.file ? attachment.file.name : getFilenameFromUrl(attachment.url)}
                description={attachment.description}
                author={attachment.created.name}
                createdDate={attachment.created.datetime ? moment(attachment.created.datetime).toDate() : undefined}
                onView={() => viewFile(attachment.url)}
                onDownload={
                  attachment.created.datetime
                    ? () => downloadFile(attachment.url, getFilenameFromUrl(attachment.url))
                    : undefined
                }
                onDelete={attachment.canBeDeleted && !isDone ? () => removeAttachment(attachment.id) : undefined}
              />
            </div>

            {settings?.attachmentMaxSize &&
              attachment.file &&
              attachment.file.size / 1024 >= settings.attachmentMaxSize && (
                <div style={{ color: colors.functionals.alert }}>
                  <Typography variant="caption" color="inherit" style={{ fontSize: 12, lineHeight: '24px' }}>
                    *File is too large. There is a maximum limit of{' '}
                    {Number.isInteger(settings.attachmentMaxSize / 1024)
                      ? settings.attachmentMaxSize / 1024
                      : getFlooredFixed(settings.attachmentMaxSize / 1024, 1)}
                    MB.
                  </Typography>
                </div>
              )}
          </div>
        ))
      ) : (
        <Typography>No attachment found</Typography>
      )}
    </ViewSection>
  );
};
