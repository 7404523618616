import { FC, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { Paper } from '../paper/Paper';

import { useStyles } from './AttachmentCard.styles';

export const AttachmentCardSkeleton: FC = memo(() => {
  const styles = useStyles();

  return (
    <Paper elevation={24}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.fileIconHolder}>
            <Skeleton variant="rect" animation="wave" classes={{ root: styles.fileIcon }} />
          </div>

          <div>
            <Typography variant="subtitle1" className={styles.primaryText}>
              <Skeleton variant="text" animation="wave" width={250} height={20} />
            </Typography>
            <Typography variant="body1" className={styles.secondaryText}>
              <Skeleton variant="text" animation="wave" width={210} height={14} />
            </Typography>
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.actions}>
            <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
          </div>
          <div className={styles.actions}>
            <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
          </div>
          <div className={styles.actions}>
            <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
          </div>
        </div>

        <div className={styles.description}>
          <Typography variant="body1" className={styles.secondaryText}>
            <Skeleton variant="text" animation="wave" height={14} />
            <Skeleton variant="text" animation="wave" height={14} />
            <Skeleton variant="text" animation="wave" width="45%" height={14} />
          </Typography>
        </div>
      </div>
    </Paper>
  );
});
