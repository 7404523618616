import { FC, memo, useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { PencilIcon, TrashIcon } from 'assets';

import { useStyles } from './UpdateList.styles';
import { Update, UpdateListProps } from './UpdateList.types';

export const UpdateList: FC<UpdateListProps> = memo(({ list }) => {
  const styles = useStyles();

  const changesType = useCallback(({ from, to }) => {
    if (!from || from === '') {
      return 'Set';
    }

    if (!to || to === '') {
      return 'Removed';
    }

    return 'Changed';
  }, []);

  const keys = useCallback((from: Update['from'], to: Update['to']): string[] | void => {
    if (from) {
      return Object.keys(from);
    }

    if (to) {
      return Object.keys(to);
    }
  }, []);

  if (!list.length) {
    return <></>;
  }

  return (
    <div className={styles.updateListWrapper}>
      {list.map((update, index) => (
        <div key={index} className={styles.updateItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <div className={clsx(styles.updateLabel, styles.flexCenter)}>
                <Typography>
                  <b>{update.name}</b>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={9}>
              {keys(update.from, update.to)?.map((key, fieldIndex) => (
                <div key={`${index}_${fieldIndex}`} className={styles.updateDataRow}>
                  <div className={styles.updateDataCol} style={{ flex: 1 }}>
                    <Typography variant="body2">
                      {`${changesType({ from: update.from?.[key], to: update.to?.[key] })}   `}
                      <b>{key}</b>
                    </Typography>
                  </div>

                  <div className={styles.updateDataCol} style={{ flex: 1 }}>
                    <div className={clsx(styles.value, 'old')}>
                      {update.from?.[key] || (
                        <div className={styles.flexCenter}>
                          <PencilIcon />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.updateDataCol}>
                    <svg width="34" height="15" viewBox="0 0 34 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.75 6.5C1.19772 6.5 0.75 6.94772 0.75 7.5C0.75 8.05228 1.19772 8.5 1.75 8.5V6.5ZM33.4571 8.20711C33.8476 7.81658 33.8476 7.18342 33.4571 6.79289L27.0931 0.428932C26.7026 0.0384078 26.0695 0.0384078 25.6789 0.428932C25.2884 0.819457 25.2884 1.45262 25.6789 1.84315L31.3358 7.5L25.6789 13.1569C25.2884 13.5474 25.2884 14.1805 25.6789 14.5711C26.0695 14.9616 26.7026 14.9616 27.0931 14.5711L33.4571 8.20711ZM1.75 8.5H32.75V6.5H1.75V8.5Z"
                        fill="#B3BDCE"
                      />
                    </svg>
                  </div>

                  <div className={styles.updateDataCol} style={{ flex: 1.5 }}>
                    <div className={clsx(styles.value, 'new')}>
                      {update.to?.[key] || (
                        <div className={styles.flexCenter}>
                          <TrashIcon />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
});
