import moment from 'moment';
import ReactNumberFormat from 'react-number-format';

import { USADateFormat } from 'utils';

import { NumberFormatProps } from './NumberFormat.types';

export const NumberFormat = ({ ...props }: NumberFormatProps) => {
  const { inputRef, onChange, format, ...other } = props;

  return (
    <ReactNumberFormat
      {...other}
      getInputRef={inputRef}
      format={format}
      onValueChange={values => {
        onChange &&
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
    />
  );
};

const limit = (value: string, max: string) => {
  if (value.length === 1 && value[0] > max[0]) {
    value = '0' + value;
  }

  if (value.length === 2) {
    if (Number(value) === 0) {
      value = '01';
      //this can happen when user paste number
    } else if (value > max) {
      value = max;
    }
  }

  return value;
};

export const dateFormat = (value: string) => {
  const month = limit(value.substring(0, 2), '12');
  const day = limit(value.substring(2, 4), '31');
  const year = value.substring(4, 8);

  return month + (day.length ? '/' + day : '') + (year.length ? '/' + year : '');
};

export const dateRangeFormat = (value: string) => {
  const date1 = value.substring(0, 8);
  const date2 = value.substring(8, 16);

  return dateFormat(date1) + (date2.length ? ' - ' + dateFormat(date2) : '');
};

export const humanDateFormat = (value: string) => {
  const momentDate = moment(new Date(value));
  const formattedDate = momentDate.format(USADateFormat);

  if (momentDate.isSame(moment().add(0, 'day'), 'day')) {
    return `Today (${formattedDate})`;
  }

  if (momentDate.isSame(moment().add(1, 'day'), 'day')) {
    return `Tomorrow (${formattedDate})`;
  }

  if (momentDate.isSame(moment().add(2, 'day'), 'day')) {
    return `In 2 days (${formattedDate})`;
  }

  if (momentDate.isSame(moment().add(3, 'day'), 'day')) {
    return `In 3 days (${formattedDate})`;
  }

  return formattedDate;
};
