import { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { colors } from 'assets';
import { Paper, UserIdentifier } from 'components';
import { useEllipsis } from 'hooks';
import { removeTagsFromString } from 'utils';

import { ActiviyCardProps } from './types';

export const ActivityCard = memo(({ details, createdBy, createdDate }: ActiviyCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { renderEllipsisText: renderDetails } = useEllipsis({
    text: (
      <Typography variant="body1" color="inherit">
        {removeTagsFromString(details)}
      </Typography>
    ),
  });

  return (
    <Paper elevation={16}>
      <div style={{ padding: '8px 16px' }}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item xs={12} sm={6}>
            <div style={{ color: colors.grey140, overflow: 'hidden' }}>
              {isMobile ? (
                <Typography variant="body1" color="inherit">
                  {removeTagsFromString(details)}
                </Typography>
              ) : (
                renderDetails()
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" spacing={1} justify="flex-end">
              <Grid item xs={6}>
                <UserIdentifier
                  name={createdBy.name}
                  imageUrl={createdBy.imageUrl}
                  size="large"
                  color={colors.grey140}
                />
              </Grid>

              <Grid item xs={6}>
                <div style={{ color: colors.grey140, textAlign: 'right' }}>
                  <Typography color="inherit">{createdDate}</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
});
