import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    maxWidth: '402px',
    paddingTop: '12.25px',
  },
  container: {
    width: '402px',
  },
  buttonsContainer: {
    paddingTop: '23px',
  },
});
