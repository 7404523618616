import { useCallback, useState, useRef, forwardRef, useMemo, memo } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import { ExpandMoreIcon } from '../../expandMoreIcon/ExpandMoreIcon';
import { DropdownMenu } from '../dropdownMenu/DropdownMenu';
import { listItemProps } from '../Navigation.utils';

import { useStyles } from './NavigationItem.styles';
import { NavigationItemProps } from './NavigationItem.types';
import { SubNavigationItem } from './subNavigationItem/SubNavigationItem';

export const NavigationItem = memo(
  forwardRef<HTMLDivElement, NavigationItemProps>(
    ({ isSubmenuOpen, menuItem, user, handleSubmenuItemOpen, handleCloseDrawer }, ref) => {
      const styles = useStyles();
      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('md'));
      const [submenuRef, setSubmenuRef] = useState<null | HTMLElement>(null);
      const [menuItemHover, setMenuItemHover] = useState<string>('');
      const anchorRef = useRef<HTMLElement>();

      const handleSubmenuOpen = useCallback(
        (event: { currentTarget: HTMLElement }, name: string) => setMenuItemHover(name),
        [setMenuItemHover],
      );

      const handleSubmenuClose = useCallback(() => {
        setSubmenuRef(null);
        setMenuItemHover('');
      }, [setSubmenuRef, setMenuItemHover]);

      const isXl = useMediaQuery(theme.breakpoints.up('xl'));
      const navItemSize = useMemo(() => {
        if (isXl) {
          return 'h5';
        }

        return 'h6';
      }, [isXl]);

      return (
        <>
          <ListItem
            {...listItemProps({ menuItem, user })}
            button
            innerRef={anchorRef}
            focusVisibleClassName={styles.focusVisibleClassName}
            className={clsx(styles.listItem, {
              [styles.hover]: !!submenuRef && menuItemHover === menuItem.name,
              [styles.listItemMobile]: isMobile,
            })}
            onClick={
              isMobile
                ? () =>
                    menuItem.isSubmenu
                      ? handleSubmenuItemOpen && handleSubmenuItemOpen(menuItem.name)
                      : handleCloseDrawer && handleCloseDrawer()
                : undefined
            }
            onMouseEnter={isMobile ? undefined : event => menuItem.isSubmenu && handleSubmenuOpen(event, menuItem.name)}
            onMouseLeave={isMobile ? undefined : () => menuItem.isSubmenu && handleSubmenuClose()}
          >
            {menuItem.icon && (
              <ListItemIcon className={styles.menuIcon} color="inherit">
                {menuItem.icon({})}
              </ListItemIcon>
            )}

            <ListItemText primaryTypographyProps={{ color: 'inherit' }}>
              <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                <Grid item>
                  <Typography variant={navItemSize} color="inherit">
                    {menuItem.name}
                  </Typography>
                </Grid>

                {menuItem.isSubmenu && (
                  <Grid item>
                    <div className={styles.expandMoreWrapper}>
                      <ExpandMoreIcon isOpen={Boolean(isSubmenuOpen)} />
                    </div>
                  </Grid>
                )}
              </Grid>
            </ListItemText>
          </ListItem>

          {menuItem.isSubmenu &&
            menuItem.submenuItems?.length !== 0 &&
            (isMobile ? (
              <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuItem?.submenuItems?.map(subItem => (
                    <SubNavigationItem
                      listItemProps={{ ...listItemProps({ menuItem: subItem, user }), onClick: handleCloseDrawer }}
                      key={subItem.name}
                      subItemName={subItem.name}
                      badge={subItem.badge}
                      isHover={!!submenuRef && menuItemHover === menuItem.name}
                      onClick={handleCloseDrawer}
                    />
                  ))}
                </List>

                <Divider className={styles.divider} />
              </Collapse>
            ) : (
              <DropdownMenu
                open={menuItem.name === menuItemHover}
                anchorEl={anchorRef.current}
                user={user}
                submenuItems={menuItem?.submenuItems}
                handleSubmenuClose={handleSubmenuClose}
                MenuListProps={{
                  onMouseEnter: event => handleSubmenuOpen(event, menuItem.name),
                  onMouseLeave: () => handleSubmenuClose(),
                }}
              />
            ))}
        </>
      );
    },
  ),
);
