import { memo, useCallback } from 'react';

import { Button } from 'components/button/Button';

import { BrowseFileButtonProps } from './BrowseFileButton.types';

export const BrowseFileButton = memo(({ inputFileRef, acceptFormats, onChange }: BrowseFileButtonProps) => {
  const handleBrowseFiles = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [inputFileRef]);

  return (
    <>
      <input style={{ display: 'none' }} ref={inputFileRef} type="file" accept={acceptFormats} onChange={onChange} />

      <div style={{ width: 150 }}>
        <Button buttonType="outlined" fullWidth onClick={handleBrowseFiles}>
          Browse files
        </Button>
      </div>
    </>
  );
});
